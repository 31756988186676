



































import Vue from "vue";
import CloseIcon from "@/assets/icons/close.svg";
import EmailIcon from "@/assets/icons/email.svg";

export default Vue.extend({
  components: {
    CloseIcon,
    EmailIcon,
  },

  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      default: "",
    },
    isStakeholderUser: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    avatarOrPlaceholder(): string {
      if (
        this.userInfo.field_user_avatar &&
        this.userInfo.field_user_avatar.uri &&
        this.userInfo.field_user_avatar.uri.url
      ) {
        return this.baseUrl + this.userInfo.field_user_avatar.uri.url;
      }
      return "/avatar_placeholder.svg";
    },
  },

  methods: {
    removeUser() {
      this.$emit("removeUser", {
        user: this.userInfo,
        isStakeholderUser: this.isStakeholderUser,
      });
    },
  },
});
