



































import Vue from "vue";
import Breadcrumb from "./breadcrumb/Breadcrumb.vue";
import { BASE_URL } from "../constants";
import MenuIcon from "../assets/icons/menu.svg";
import { Viewport } from "@/lib/utils";
import DropdownMenu from "./ui/DropdownMenu.vue";
import UserOptions from "./UserOptions.vue";

export default Vue.extend({
  components: {
    Breadcrumb,
    MenuIcon,
    DropdownMenu,
    UserOptions,
  },
  props: {
    mobileMenuOpened: {
      type: Boolean,
      default: false,
    },
    loggedInUser: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      viewport: Viewport.getInstance(),
    };
  },
  computed: {
    isMobile(): boolean {
      return this.viewport.isMobile;
    },
    avatarOrPlaceholder(): string {
      return this.loggedInUser &&
        this.loggedInUser.field_user_avatar &&
        this.loggedInUser.field_user_avatar.uri &&
        this.loggedInUser.field_user_avatar.uri.url
        ? BASE_URL + this.loggedInUser.field_user_avatar.uri.url
        : "/avatar_placeholder.svg";
    },
  },
});
