


























import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import Btn from "@/components/ui/Btn.vue";
import { RouteNames } from "@/router/router";
import { i18n } from "@/i18n";

export default Vue.extend({
  components: {
    Btn,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    goBackUrl: {
      type: Object as PropType<Location>,
      default: () => ({}),
    },
    formType: {
      type: String,
      default: "add",
    },
    formName: {
      type: String,
      default: "project",
    },
  },
  computed: {
    isCategory(): boolean {
      return [RouteNames.CategoryNew, RouteNames.CategoryEdit].includes(
        this.$route.name || ""
      );
    },
    i18nLabelProperty(): object {
      const form = this.formType === "add" ? "new" : "edit";
      return i18n.actions[this.formName][form];
    },
  },
});
