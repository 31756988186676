





















import Vue, { PropType } from "vue";
import { BASE_URL } from "../constants";
import { User } from "@/interfaces";
import { i18n } from "@/i18n";

export default Vue.extend({
  props: {
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
  },

  computed: {
    limitedUsers(): User[] {
      if (!this.users) return [];

      return this.users.slice(0, 5);
    },

    avatarAlt(): string {
      return i18n.project.attributes.user_image;
    },

    numMembersString(): string {
      if (this.users.length === 0) {
        return i18n.members.null;
      }

      return this.users.length === 1
        ? i18n.members.one
        : i18n.members.other.replace("${count}", this.users.length.toString());
    },
  },

  methods: {
    avatarOrPlaceholder(user: User): string {
      if (user?.avatar) return user.avatar;
      if (user?.field_user_avatar?.uri?.url) {
        return BASE_URL + user.field_user_avatar.uri.url;
      }
      return "/avatar_placeholder.svg";
    },
  },
});
