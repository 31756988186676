



























import Vue, { PropType } from "vue";
import { THEME } from "../../theme";
export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<{ text: string; value: number }[]>,
      default: () => [],
    },
    value: {
      type: [Number, String, Object, Array],
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    hideSideLabel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    optionLabel: {
      type: String,
      default: "label",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      THEME,
      inputId: this.id || crypto.randomUUID(),
    };
  },
});
