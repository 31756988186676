
















import Vue from "vue";
import UiCard from "@/components/ui/Card.vue";

export default Vue.extend({
  components: {
    UiCard,
  },
});
