






import Vue, { PropType } from "vue";
import Arrowhead from "../../assets/icons/arrowhead-down.svg";
import ArrowheadSortable from "../../assets/icons/arrowhead-sortable.svg";

export default Vue.extend({
  components: {
    Arrowhead,
    ArrowheadSortable,
  },
  props: {
    state: {
      type: String as PropType<"asc" | "desc" | "none">,
      default: "none",
    },
  },
});
