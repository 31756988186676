


























import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      value: false,
      menu: null as HTMLElement | null,
      target: null as HTMLElement | null,
      menuItems: [] as HTMLElement[],
      keyCode: Object.freeze({
        TAB: 9,
        RETURN: 13,
        ESC: 27,
        SPACE: 32,
        PAGEUP: 33,
        PAGEDOWN: 34,
        END: 35,
        HOME: 36,
        UP: 38,
        DOWN: 40,
      }),
    };
  },
  computed: {
    firstMenuItem(): HTMLElement | null {
      if (!this.menuItems.length) {
        return null;
      }
      return this.menuItems[0];
    },
    lastMenuItem(): HTMLElement | null {
      if (!this.menuItems.length) {
        return null;
      }
      return this.menuItems[this.menuItems.length - 1];
    },
  },
  methods: {
    toggle() {
      this.value = !this.value;
      if (this.value) {
        this.setFocusToFirstItem();
      }
    },

    open() {
      this.value = true;
      this.setFocusToFirstItem();
    },

    close() {
      this.value = false;
    },

    catchOutsideClick(event, dropdown) {
      if (dropdown == event.target || dropdown == event.target.offsetParent) {
        return false;
      }

      if (this.value && dropdown != event.target) return true;
    },

    setFocusToFirstItem() {
      if (this.firstMenuItem) {
        this.$nextTick(() => {
          this.firstMenuItem?.focus();
        });
      }
    },

    setFocusToLastItem() {
      if (this.lastMenuItem) {
        this.$nextTick(() => {
          this.lastMenuItem?.focus();
        });
      }
    },

    setFocusToNextItem() {
      const currentItem = document.activeElement as HTMLElement;

      if (currentItem == this.lastMenuItem) {
        this.setFocusToFirstItem();
      } else {
        const index = this.menuItems.indexOf(currentItem);
        this.$nextTick(() => {
          this.menuItems[index + 1].focus();
        });
      }
    },

    setFocusToPreviousItem() {
      const currentItem = document.activeElement as HTMLElement;

      if (currentItem == this.firstMenuItem) {
        this.setFocusToLastItem();
      } else {
        const index = this.menuItems.indexOf(currentItem);
        this.$nextTick(() => {
          this.menuItems[index - 1].focus();
        });
      }
    },

    handleKeydown(event) {
      switch (event.keyCode) {
        case this.keyCode.SPACE:
        case this.keyCode.RETURN:
          if (!this.value) {
            this.open();
          }
          event.stopPropagation();
          break;

        case this.keyCode.DOWN:
          if (!this.value) {
            this.open();
          }
          this.setFocusToNextItem();
          break;

        case this.keyCode.UP:
          if (!this.value) {
            this.open();
          }
          this.setFocusToPreviousItem();
          break;

        case this.keyCode.HOME:
        case this.keyCode.PAGEUP:
          this.setFocusToFirstItem();
          break;

        case this.keyCode.END:
        case this.keyCode.PAGEDOWN:
          this.setFocusToLastItem();
          break;

        case this.keyCode.ESC:
        case this.keyCode.TAB:
          this.close();
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    const closeListerner = (e) => {
      if (this.catchOutsideClick(e, this.$refs.menu)) {
        window.removeEventListener("click", closeListerner);
        this.value = false;
      }
    };
    window.addEventListener("click", closeListerner.bind(this));

    this.target = document.getElementById("dropdown-menu-trigger");
    document
      .getElementById("dropdown-menu-wrapper")
      ?.addEventListener("keydown", this.handleKeydown.bind(this));
    this.menu = this.target
      ? document.getElementById(this.target.getAttribute("aria-controls") || "")
      : null;
    this.menuItems =
      Array.from(this.menu?.querySelectorAll('[role="menuitem"]') || []) ||
      ([] as HTMLElement[]);
  },
});
