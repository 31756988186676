
















import Vue from "vue";
import Btn from "@/components/ui/Btn.vue";

export default Vue.extend({
  components: {
    Btn,
  },

  data() {
    return {
      login_url: "/",
    };
  },
});
