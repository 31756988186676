















import Vue from "vue";
import Accordion from "./ui/Accordion.vue";
export default Vue.extend({
  components: {
    Accordion,
  },
  props: {
    editorContent: {
      type: String,
      default: "",
    },
    developerContent: {
      type: String,
      default: "",
    },
  },
});
