

















































import Vue from "vue";
import FieldInfo from "@/components/FieldInfo.vue";
import unlockOverrideStatusMixin from "@/mixins/override-inherited-value";
import OutlinedTextField from "@/components/form/OutlinedTextField.vue";
import ProjectFormTitle from "./ProjectFormTitle.vue";

export default Vue.extend({
  mixins: [unlockOverrideStatusMixin],

  components: {
    FieldInfo,
    OutlinedTextField,
    ProjectFormTitle,
  },

  props: {
    projectAuthUsernameInput: {
      type: String,
      default: "",
    },
    projectAuthPasswordInput: {
      type: String,
      default: "",
    },
    projectAuthUsernameOptions: {
      type: [Object, String],
      default: () => ({}),
    },
    projectAuthPasswordOptions: {
      type: [Object, String],
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      authUsername: "",
      authPassword: "",
    };
  },

  watch: {
    projectAuthUsernameInput: {
      immediate: true,
      handler(newValue) {
        this.authUsername = newValue;
      },
    },

    projectAuthPasswordInput: {
      immediate: true,
      handler() {
        this.authPassword = this.projectAuthPasswordInput;
      },
    },
  },
});
