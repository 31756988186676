














import Vue from "vue";
import { mapState } from "vuex";
import { i18n } from "@/i18n";
import Btn from "./ui/Btn.vue";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";

export default Vue.extend({
  components: {
    Btn,
  },
  computed: {
    ...mapState("projects", ["entries"]),

    category(): { id: string; label: string } {
      const currentId = this.$route.params.id;
      if (this.entries) {
        const currentEntry = this.entries.find(
          (entry) => entry.id === currentId && entry.type === "category"
        );

        if (currentEntry) {
          return currentEntry;
        }
      }
      return { id: "", label: "" };
    },

    emptyCategoryMessage(): string {
      return i18n.project.emptyCategory.replace(
        ":category",
        this.category.label
      );
    },

    newProjectRoute(): Location {
      return {
        name: RouteNames.ProjectNew,
        query: { categoryId: this.category.id },
      };
    },
  },
});
