




















import Vue, { PropType } from "vue";
import { ProjectEntry } from "@/interfaces";
import CustomSelect from "@/components/form/Select.vue";

interface NestedProjectEntry {
  id: string;
  label: string;
  type: "category" | "project";
  level: number;
}

export default Vue.extend({
  name: "CategoryTree",

  components: {
    CustomSelect,
  },

  props: {
    items: {
      type: Array as PropType<ProjectEntry[]>,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedOption(): NestedProjectEntry | string {
      return this.computedItems.find((item) => item.id === this.value) || "";
    },
    computedItems(): NestedProjectEntry[] {
      const getNestedItems = (
        acc: NestedProjectEntry[],
        item: ProjectEntry,
        index: number
      ) => {
        if (item.type === "category") {
          acc.push({
            id: item.id,
            label: item.label,
            type: item.type,
            level: index,
          });

          if (item.children?.length) {
            item.children.forEach((child) =>
              getNestedItems(acc, child, index + 1)
            );
          }
        }

        return acc;
      };

      return this.items.reduce((acc, item) => {
        if (item.type === "category") {
          acc.push({
            id: item.id,
            label: item.label,
            type: item.type,
            level: 0,
          });

          if (item.children?.length) {
            item.children.forEach((child) => getNestedItems(acc, child, 1));
          }
        }

        return acc;
      }, [] as NestedProjectEntry[]);
    },
  },
});
