


















import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import UiCard from "./ui/Card.vue";
import TestTable from "./test-table/TestTable.vue";
import { ProjectDetails, TestTypeOverview } from "@/interfaces";
import ExportMetatagsReport from "./export-metatags-report/ExportMetatagsReport.container";
import ExportMetatagsReportCrawler from "./export-metatags-report/ExportMetatagsReport.container";

export default Vue.extend({
  components: {
    UiCard,
    TestTable,
    ExportMetatagsReport,
    ExportMetatagsReportCrawler
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("tests", ["tests"]),
    ...mapGetters("projects", ["findProjectDetails"]),
    ...mapGetters("tests", ["projectTestsList"]),

    project(): ProjectDetails | undefined {
      return this.findProjectDetails(this.id);
    },

    tests(): TestTypeOverview[] {
      return this.projectTestsList(this.id);
    },

    hasJiraProject(): boolean {
      return Boolean(this.project?.field_jira_project_key);
    },
  },
});
