export default {
  namespaced: true,
  state: {
    validFields: {
      projectTitle: true,
      projectBaseUrl: true,
      projectSitemapUrl: true,
      stakeholderUser: true,
    },
    formSubmitted: false,
  },
  getters: {
    isFieldValid: (state) => (field) => {
      return state.validFields[field];
    },
  },
  actions: {
    setInvalidField({ commit }, field) {
      commit("setInvalidField", field);
    },
    setFormSubmitted({ commit }, submitted) {
      commit("setFormSubmitted", submitted);
    },
    resetValidFields({ commit }) {
      commit("resetValidFields");
    },
  },
  mutations: {
    setInvalidField(state, field) {
      state.validFields[field] = false;
    },
    setFormSubmitted(state, submitted) {
      state.formSubmitted = submitted;
    },
    resetValidFields(state) {
      state.validFields = {
        projectTitle: true,
        projectBaseUrl: true,
        projectSitemapUrl: true,
        stakeholderUser: true,
      };
    },
  },
};
