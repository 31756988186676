









import Vue from "vue";
import { formatTimestamp, formatRelativeTimestamp } from "@/lib/utils";

export default Vue.extend({
  props: {
    nextRunTimestamp: {
      type: Number,
      required: true,
    },
  },
  computed: {
    nextRunDate(): string {
      if (this.nextRunTimestamp) {
        const timestampInMiliseconds = this.nextRunTimestamp * 1000;
        const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

        let dateString = formatTimestamp(timestampInMiliseconds, true) || "";

        const timeDifference = timestampInMiliseconds - Date.now();
        if (timeDifference < 0) {
          return "N/A";
        }

        if (timeDifference > 0 && timeDifference < ONE_DAY_MILLISECONDS) {
          dateString = formatRelativeTimestamp(timestampInMiliseconds) || "";
        }

        if (dateString.endsWith("ago")) {
          return "N/A";
        }

        return dateString;
      }

      return "N/A";
    },
  },
});
