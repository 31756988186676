import {
  EditedCategory,
  EditedProject,
  EntityType,
  ProjectDetails,
  Relationship,
  User,
} from "@/interfaces";
import { getUsername } from "@/lib/auth";
import { workerConfigToAdvancedConfig } from "@/lib/utils";
import Vue from "vue";
import { Location } from "vue-router";
import { mapState, mapActions, mapGetters } from "vuex";
import router, { RouteNames } from "../router/router";

export default Vue.extend({
  props: {
    entityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      defaultIssuePriority: "",
      defaultIssuePriorityOptions: {},
      defaultIssueType: "",
      defaultIssueTypeOptions: {},
      enabledTests: {},
      enabledTestsOptions: {},
      formType: "add",
      isDisabled: false,
      isLoading: false,
      jiraAccount: null as Relationship | null,
      jiraAccountId: "",
      jiraAccountOptions: {},
      jiraProject: {
        id: "",
        key: "",
        name: "",
        issueTypes: [],
      },
      jiraProjectId: "",
      jiraProjectOptions: {},
      overwriteWarningOpen: false,
      projectAuthPassword: "",
      projectAuthPasswordOptions: {},
      projectAuthUsername: "",
      projectAuthUsernameOptions: {},
      projectBaseUrl: "",
      projectCategory: "",
      projectDescription: "",
      projectSitemapUrl: "",
      projectTitle: "",
      stakeholderUser: null as User | null,
      stakeholderUserId: "",
      stakeholderUserOptions: {},
      usersAccess: [],
      usersId: [] as string[],
      usersIdOptions: {},
      workerConfig: {},
      advancedConfig: {
        disableProxy: false,
        sitemapCrawlLimit: 0,
        enableNoindexNofollowPages: false,
        disablePageLoading: false,
      },
      advancedConfigOptions: {},
    };
  },

  watch: {
    jiraProject(): void {
      this.projectTitle =
        this.projectTitle === "" && this.jiraProject
          ? this.jiraProject.name
          : this.projectTitle;
    },

    testTypes(): void {
      if (!this.editedEntity) {
        this.setDefaultEnabledTests();
      }
    },
  },

  computed: {
    ...mapGetters("users", ["getUserByName", "getBusinessAnalystUsers"]),
    ...mapState("categories", ["layeredConfig"]),
    ...mapGetters("categories", ["getOverriddenFields"]),
    ...mapState("projects", ["editedProject", "editedCategory"]),
    ...mapState("tests", ["testTypes", "testTypesFetching"]),

    projectDetails(): ProjectDetails | undefined {
      // should be implemented by component
      return undefined;
    },

    categoryId(): string {
      // should be implemented by component
      return "";
    },

    entityType(): EntityType {
      // should be implemented by component: "project" | "taxonomy_term"
      return EntityType.Project;
    },

    editedEntity(): EditedProject | EditedCategory {
      if (this.entityType === EntityType.Project) {
        return this.editedProject;
      } else {
        return this.editedCategory;
      }
    },

    goBackUrl(): Location | null {
      let routeName = RouteNames.CategoriesHome;
      if (this.entityType === EntityType.Project) {
        routeName = RouteNames.ProjectOverview;
      } else if (this.formType === "add" && !this.categoryId) {
        routeName = RouteNames.ProjectsHome;
      }

      return this.formType === "add"
        ? {
            name: routeName,
            params: { id: this.categoryId },
          }
        : {
            name: routeName,
            params: { id: this.entityId },
          };
    },

    workerConfigData(): object {
      return this.projectDetails?.field_config?.worker_config || {};
    },

    loggedInUser(): User | undefined {
      return this.getUserByName(getUsername());
    },

    currentUserIsStakeholder(): boolean {
      // no stakeholder set yet
      if (!this.stakeholderUser) return true;
      return this.stakeholderUser.id === this.loggedInUser?.id;
    },

    stakeholderUpdateDisabled(): boolean {
      return this.formType === "edit" && !this.currentUserIsStakeholder;
    },
  },

  methods: {
    ...mapActions("categories", ["setOverriddenFields", "getLayeredConfig"]),
    ...mapActions("tests", ["getTestTypes"]),
    ...mapActions("users", ["getUsersForEntity", "getUsersWithPermission"]),
    ...mapActions("projects", ["getEditedCategory", "getEditedProject"]),

    setEditedProject(): void {
      // should be implemented by component
    },

    setEditedCategory(): void {
      // should be implemented by component
    },

    reset(): void {
      (this.$refs?.form as HTMLFormElement)?.reset();
    },

    setEditedEntity(): void {
      if (this.entityType === EntityType.Project) {
        this.setEditedProject();
      } else {
        this.setEditedCategory();
      }
    },

    redirectToDetailsViewIfAccessDenied(): void {
      const canEdit = this.projectDetails?.access.includes("update");

      this.projectDetails?.access.includes("update");

      if (!canEdit) {
        router.push({
          name: RouteNames.ProjectOverview,
          params: { id: this.entityId },
        });
      }
    },

    setCategory(id: string): void {
      if (this.projectCategory) {
        this.overwriteWarningOpen = true;
      } else {
        this.fetchInheritanceData(id);
      }

      this.projectCategory = id;
    },

    getEditedEntity(id: string): Promise<void> {
      if (this.entityType === EntityType.Project) {
        return this.getEditedProject(id);
      } else {
        return this.getEditedCategory(id);
      }
    },

    setDefaultEnabledTests(): void {
      this.testTypes.forEach((testType) => {
        const category = testType.category.label || testType.category;

        this.$set(
          this.enabledTests,
          testType.id,
          category === "recommended" || category === "mandatory"
        );
      });
    },

    async fetchInheritanceData(id: string): Promise<void> {
      // fetch inherited test types
      if (!this.testTypesFetching) {
        await this.getTestTypes({
          id: id,
          entity: this.entityType,
        });
      }
      // fetch inheritance data
      await this.getLayeredConfig({
        type_base: EntityType.Category,
        id_base: id,
      }).then(() => {
        this.updateForm();
      });
    },

    handleConfigInput({ value, test }): void {
      this.$set(this.workerConfig, test, {
        type: "worker",
        worker_config: value,
      });
    },

    setUsername(project_config) {
      const id_username = "auth-username";
      if (
        !(id_username in this.getOverriddenFields) ||
        this.getOverriddenFields[id_username].lock !== false ||
        this.formType === "add"
      ) {
        this.projectAuthUsername =
          project_config.field_auth_username?.value || "";
        this.projectAuthUsernameOptions =
          project_config.field_auth_username?.option || {};
      } else {
        this.projectAuthUsernameOptions =
          project_config.field_auth_username?.option || {};
        this.setOverriddenFields({
          id: id_username,
          data: { lock: false },
        });
      }
    },

    setPassword(project_config) {
      const id_auth = "auth-password";
      if (
        !(id_auth in this.getOverriddenFields) ||
        this.getOverriddenFields[id_auth].lock !== false ||
        this.formType === "add"
      ) {
        this.projectAuthPassword =
          project_config.field_auth_password?.value || "";
        this.projectAuthPasswordOptions =
          project_config.field_auth_password?.option || {};
      } else {
        this.projectAuthPasswordOptions =
          project_config.field_auth_password?.option || {};
        this.setOverriddenFields({
          id: id_auth,
          data: { lock: false },
        });
      }
    },

    setJiraData(project_config) {
      this.jiraProjectId =
        project_config.field_jira_project_key?.value?.key || "";
      this.jiraProjectOptions = project_config.field_jira_project_key?.option;

      this.jiraAccount = {
        data: {
          id: project_config.jira_account?.id,
          type: project_config.jira_account?.content_type,
        },
      };
      this.jiraAccountId = project_config.jira_account?.id || "";
      this.jiraAccountOptions = project_config.jira_account?.option;

      this.defaultIssuePriority =
        project_config.field_jira_tickets_priority?.value || "";
      this.defaultIssuePriorityOptions =
        project_config.field_jira_tickets_priority?.option;

      this.defaultIssueType =
        project_config.field_jira_tickets_type?.value || "";
      this.defaultIssueTypeOptions =
        project_config.field_jira_tickets_type?.option;
    },

    setEnabledTests(project_config) {
      this.enabledTests = {};
      this.enabledTestsOptions = {};
      Object.keys(project_config?.enabled_test_types?.tests || {}).forEach(
        (id) => {
          if (id === "option") return;
          this.$set(
            this.enabledTests,
            id,
            project_config.enabled_test_types.tests[id].value
          );
          this.$set(
            this.enabledTestsOptions,
            id,
            project_config.enabled_test_types.tests[id].option
          );
        }
      );
    },

    setStakeholder(project_config) {
      this.stakeholderUserId = project_config.field_project_stakeholder?.id;
      this.stakeholderUserOptions =
        project_config.field_project_stakeholder?.option;
    },

    setUsers(project_config) {
      if ("field_project_users" in project_config) {
        let user = {};

        Object.keys(project_config.field_project_users).forEach(
          (element_id) => {
            if (element_id === "option") return;
            const id = project_config.field_project_users?.[element_id]?.id;
            this.$set(
              this.usersIdOptions,
              id,
              project_config.field_project_users?.[element_id]?.option
            );

            if (
              project_config.field_project_users?.[element_id]?.option?.uuid ==
              this.entityId
            ) {
              user = project_config.field_project_users?.[element_id]?.option;
            }
            if (id) {
              this.usersId.push(id);
            }
          }
        );

        if (Object.keys(user).length === 0) {
          user = Object.keys(project_config.field_project_users)[
            Object.keys(project_config.field_project_users).length - 1
          ];
        }

        this.$set(this.usersIdOptions, "user_select", user);
      }
    },

    setAdvancedConfig(tree_config) {
      const advancedConfig =
        tree_config?.worker_config?.workers?.defaults?.worker_config;
      if (advancedConfig) {
        const translatedConfig = workerConfigToAdvancedConfig(advancedConfig);
        Object.keys(translatedConfig).forEach((key) => {
          if (translatedConfig[key].value) {
            this.$set(this.advancedConfig, key, translatedConfig[key].value);
          }
        });
      }
    },

    updateForm() {
      const tree_config = this.layeredConfig || {};
      const project_config = tree_config.entity_config?.project || {};
      // Pushing all project data to local variables.

      if (!this.entityId) {
        if (this.formType === "add" && this.loggedInUser?.id) {
          if (
            !this.stakeholderUserId &&
            this.getBusinessAnalystUsers.includes(this.loggedInUser)
          ) {
            this.stakeholderUserId = this.loggedInUser?.id;
            this.stakeholderUser = this.loggedInUser;
          }
          if (!this.usersId.length) {
            this.usersId = [this.loggedInUser.id];
          }
        }

        this.formType = "add";
        if (Object.keys(tree_config).length !== 0) {
          this.setOverrideFields(tree_config);
          this.setAdvancedConfig(tree_config);
        }
        return;
      }

      this.usersId = [];
      this.usersIdOptions = {};
      if (Object.keys(project_config).length !== 0) {
        this.setUsername(project_config);

        this.setPassword(project_config);

        this.setJiraData(project_config);

        this.setEnabledTests(project_config);

        this.setStakeholder(project_config);

        this.setUsers(project_config);
      }
    },

    setOverrideFields(tree_config) {
      const projectConfig = tree_config?.entity_config?.project || {};
      const workerConfig =
        tree_config?.worker_config?.workers?.defaults?.worker_config;
      const fields = {
        "auth-username": projectConfig.field_auth_username?.value,
        "auth-password": projectConfig.field_auth_password?.value,
        jira_project: projectConfig.field_jira_project_key?.value,
        jira_account: projectConfig.jira_account?.id,
        field_jira_tickets_priority:
          projectConfig.field_jira_tickets_priority?.value,
        jira_default_issue_type: projectConfig.field_jira_tickets_type?.value,
        stakeholder: projectConfig.field_project_stakeholder?.id,
        user_select: Object.keys(projectConfig.field_project_users).length,
        disable_proxy: workerConfig?.disable_proxy,
        url_getter: workerConfig?.url_getter,
        url_getter_options: workerConfig?.url_getter_options,
        enable_noindex_nofollow_pages:
          workerConfig?.enable_noindex_nofollow_pages,
        disable_page_loading: workerConfig?.disable_page_loading,
        sitemap_crawl_limit: workerConfig?.sitemap_crawl_limit,
      };

      Object.keys(fields).forEach((fieldName) => {
        if (fields[fieldName]) {
          this.setOverriddenFields({
            id: fieldName,
            data: { lock: true },
          });
        }
      });

      Object.keys(projectConfig?.enabled_test_types?.tests || {}).forEach(
        (id) => {
          this.setOverriddenFields({
            id: id,
            data: { lock: true },
          });
        }
      );
    },
  },

  async created() {
    if (this.entityId) {
      this.formType = "edit";
      this.isLoading = true;

      if (!this.testTypesFetching) {
        await this.getTestTypes({ id: this.entityId, entity: this.entityType });
      }

      await this.getEditedEntity(this.entityId);
      if (this.categoryId) {
        await this.getLayeredConfig({
          type_base: EntityType.Category,
          id_base: this.categoryId,
        }).then(() => {
          this.updateForm();
        });
      }
      this.isLoading = false;

      if (this.projectDetails && this.projectDetails.access) {
        this.redirectToDetailsViewIfAccessDenied();
      }

      await Promise.all([
        this.getUsersForEntity({
          entityType: this.entityType,
          entityId: this.entityId,
        }),
        this.getUsersWithPermission("be business analyst"),
      ]);
    } else {
      await Promise.all([
        this.getUsersForEntity({
          entityType: EntityType.Category,
          entityId: this.categoryId,
        }),
        this.getUsersWithPermission("be business analyst"),
      ]);
    }

    if (this.editedEntity && this.entityId) {
      this.setEditedEntity();
    } else {
      this.setDefaultEnabledTests();
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  },
});
