

















































import { BasicUser, FieldImage, TooltipConfiguration } from "@/interfaces";
import { colorForProjectScore, colorNameForScore } from "@/lib/utils";
import Vue, { PropType } from "vue";
import { Fragment } from "vue-fragment";
import ScoreProgressBar from "../scores/ScoreProgressBar.vue";
import Trend from "../ui/Trend.vue";
import MembersInline from "./MembersInline.vue";
import ProjectInfo from "./ProjectInfo.vue";
import AlertTriangle from "../../assets/icons/alert-triangle.svg";
import { formatTimestamp } from "@/lib/utils";

export default Vue.extend({
  components: {
    Fragment,
    ProjectInfo,
    ScoreProgressBar,
    MembersInline,
    Trend,
    AlertTriangle,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    logo: {
      type: [Object, String] as PropType<FieldImage | string>,
      required: true,
    },
    projectUrl: {
      type: String,
      required: true,
    },
    users: {
      type: Object as PropType<{
        stakeholder: BasicUser[];
        team: BasicUser[];
      }>,
      required: true,
    },
    alerts: {
      type: Object as PropType<{ messages: string[] }>,
      required: true,
    },
    score: {
      type: Number,
      default: 0,
    },
    trend: {
      type: Number,
      default: 0,
    },
    lastTestRunDate: {
      type: [String, Number],
      default: null,
    },
    isCritical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url(): string {
      // @todo: would the new endpoint return the url with auth already?
      // return urlForProject(this.projectUrl);
      return this.projectUrl;
    },

    lastTestRunDateString(): string {
      if (this.lastTestRunDate) {
        return formatTimestamp(this.lastTestRunDate);
      }
      return "N/A";
    },

    classColorRules(): string {
      return colorNameForScore(this.score);
    },

    tooltipContent(): TooltipConfiguration {
      const errorMessages = new Set(this.alerts.messages);
      return {
        content: [...errorMessages].join("<br><br>"),
      };
    },
  },
  methods: {
    colorRules(progress): string {
      return colorForProjectScore(progress);
    },
  },
});
