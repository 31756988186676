<template>
  <div class="chart-container" v-if="testData.date.length > 0">
    <v-c-line-chart
      :chart-data="graphData"
      :options="graphOptions"
      :height="height"
    />
  </div>
  <p v-else class="caption greyMedium--text text-xs-center">
    {{ noResultCopy }}
  </p>
</template>

<script>
import { fillArrayWith } from "../../lib/utils";
import VCLineChart from "./VueCharts/VCLineChart.vue";
import { THEME } from "../../theme";

export default {
  components: {
    VCLineChart,
  },

  props: {
    testData: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 240,
    },
    noResultCopy: {
      type: String,
      default: "",
    },
    numberOfDataPoints: {
      type: Number,
      default: 14,
    },
    indexCurrentTest: {
      type: Number,
      default: 0,
    },
    threshold: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      graphOptions: {
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 8,
            bottom: 0,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
            ticks: {
              display: true,
              stepSize: 25,
            },
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              font: {
                size: 14,
                weight: "bold",
              },
              generateLabels: function (chart) {
                const colors = {
                  Total: "critical",
                  Performance: "warning",
                  Compliance: "perfect",
                  SEO: "primary",
                  "Critical threshold": "grey80",
                };

                return chart.data.datasets.map((item) => {
                  const img = new Image();
                  img.src = `/icons/activity-${colors[item.label]}.svg`;
                  return {
                    text: item.label,
                    pointStyle: img,
                  };
                });
              },
            },
          },
        },
        chartArea: {
          backgroundColor: THEME.grey15,
        },
      },
    };
  },

  computed: {
    /**
     * If the tests don't have enough data points to meet the cap number
     * just use the amount of data points the test has
     */
    adjustedNumberOfDataPoints() {
      return this.testData.date.length > this.numberOfDataPoints
        ? this.numberOfDataPoints
        : this.testData.date.length;
    },
    /**
     * Shorten the data to the number of datapoints that should be displayed.
     */
    lengthAdjustedTestData() {
      return {
        date: this.testData.date.slice(0, this.adjustedNumberOfDataPoints),
        total: this.testData.total.slice(0, this.adjustedNumberOfDataPoints),
        performance: this.testData.performance.slice(
          0,
          this.adjustedNumberOfDataPoints
        ),
        seo: this.testData.seo.slice(0, this.adjustedNumberOfDataPoints),
        compliance: this.testData.compliance.slice(
          0,
          this.adjustedNumberOfDataPoints
        ),
      };
    },

    /**
     * Sort testData oldest to newest for the history graph.
     */
    testDataReversed() {
      return {
        date: this.lengthAdjustedTestData.date.slice().reverse(),
        total: this.lengthAdjustedTestData.total.slice().reverse(),
        performance: this.lengthAdjustedTestData.performance.slice().reverse(),
        seo: this.lengthAdjustedTestData.seo.slice().reverse(),
        compliance: this.lengthAdjustedTestData.compliance.slice().reverse(),
      };
    },

    /**
     * Creates an array of the length numberOfDataPoints with the formatted date
     * of each test.
     */
    calculateLabels() {
      const labelsArray = this.testDataReversed.date.map((date) => {
        if (date) {
          return new Intl.DateTimeFormat(
            document.querySelector("html")?.lang || "en",
            {
              month: "2-digit",
              day: "2-digit",
            }
          ).format(new Date(date));
        }

        return "";
      });

      fillArrayWith(labelsArray, "", this.adjustedNumberOfDataPoints);
      return labelsArray;
    },

    thresholdArray() {
      return new Array(this.adjustedNumberOfDataPoints).fill(this.threshold);
    },

    /**
     * The data object for the chart.js element.
     * Docs: http://www.chartjs.org/docs/latest/charts/line.html
     */
    graphData() {
      const pointRadius = new Array(this.adjustedNumberOfDataPoints).fill(3);

      const datasets = [];

      if (this.testDataReversed.total.length) {
        datasets.push({
          label: "Total",
          data: this.testDataReversed.total,
          fill: false,
          borderColor: THEME.critical,
          pointBackgroundColor: THEME.critical,
          pointRadius,
        });
      }

      if (this.testDataReversed.seo.length) {
        datasets.push({
          label: "SEO",
          data: this.testDataReversed.seo,
          fill: false,
          borderColor: THEME.accent,
          pointBackgroundColor: THEME.accent,
          pointRadius,
        });
      }

      if (this.testDataReversed.performance.length) {
        datasets.push({
          label: "Performance",
          data: this.testDataReversed.performance,
          fill: false,
          borderColor: THEME.warning,
          pointBackgroundColor: THEME.warning,
          pointRadius,
        });
      }

      if (this.testDataReversed.compliance.length) {
        datasets.push({
          label: "Compliance",
          data: this.testDataReversed.compliance,
          fill: false,
          borderColor: THEME.perfect,
          pointBackgroundColor: THEME.perfect,
          pointRadius,
        });
      }

      if (this.threshold) {
        datasets.push({
          label: "Critical threshold",
          data: this.thresholdArray,
          fill: false,
          borderColor: THEME.grey80,
          pointBackgroundColor: THEME.grey80,
          pointRadius: pointRadius.map(() => 1),
        });
      }

      return {
        labels: this.calculateLabels,
        datasets,
      };
    },
  },
};
</script>
