import JsonApiParse from "jsonapi-parse";
import Vue from "vue";
import { uniqBy, sortBy } from "lodash";
import { i18n } from "../../../i18n";
import {
  findObjectByProp,
  numberOfPendingTests,
  showToastedMessage,
} from "../../../lib/utils";
import { BASE_FE_URL } from "@/constants";
import {
  fetchProjectsMessages,
  fetchProjects,
  fetchProjectsDetails,
  fetchProjectDetails,
  fetchProjectMeta,
  fetchCategoryDetails,
  destroyProject,
  exportRecommendationReport,
  fetchProjectsList,
  editProject, exportMetatagCrawlerReport,
} from "../../../lib/api";

export default {
  namespaced: true,
  state: {
    projects: [],
    nextProjectsPageUrl: "",
    projectsList: [],
    entries: null,
    projectsDetails: [],
    editedProject: null,
    editedCategory: null,
    loaders: [],
    error: false,
    projectsMessages: {},
    search: "",
    pollingSubscribers: [],
    pollingTimeout: null,
    reportPollingSubscribers: [],
    reportPollingTimeout: null,
    restoring: [],
  },

  actions: {
    getProjectsMessages({ commit }, ids) {
      return new Promise((resolve, reject) =>
        fetchProjectsMessages(ids)
          .then((response) => {
            const parsedResponse = JsonApiParse.parse(response);
            commit("addProjectsMessages", {
              ids,
              messages: parsedResponse.data,
            });
            resolve();
          })
          .catch(() => {
            reject();
          })
      );
    },

    getProjects({ commit }) {
      commit("addLoader", "projects");
      return fetchProjects()
        .then((response) => {
          const sortCallback = (a, b) =>
            a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;

          const sortProjects = (array) => {
            return [...array].sort(sortCallback).map((element) => {
              if (element.children) {
                element.children = sortProjects(element.children);
              }
              return element;
            });
          };

          const projects = sortProjects(response);

          commit("setProjects", projects);
          commit("setEntries", projects);
          commit("removeLoader", "projects");
          return projects;
        })
        .catch((error) => {
          commit("setError", error);
          commit("removeLoader", "projects");
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    getProjectsDetails({ commit }, { ids, append, workflow = 1 }) {
      if (!append) {
        commit("addLoader", "projectsDetails");
      }
      return new Promise((resolve, reject) =>
        fetchProjectsDetails({ ids, workflow })
          .then((response) => {
            const parsedResponse = JsonApiParse.parse(response);
            response.data.forEach((entry, i) => {
              parsedResponse.data[i].access = entry.meta.access;
            });
            commit("addProjectsDetails", parsedResponse.data);
            commit("addProjectsMeta", parsedResponse.data);
            commit("addProjectsAlerts", parsedResponse.data);
            commit("removeLoader", "projectsDetails");
            resolve({ next: parsedResponse.links.next });
          })
          .catch((error) => {
            console.log("getProjectsDetails", error);
            commit("removeLoader", "projectsDetails");
            reject();
            // showToastedMessage('Something went wrong, please try again later.', 'error');
          })
      );
    },

    getProjectDetails({ commit }, id) {
      commit("addLoader", id);
      return new Promise((resolve, reject) =>
        fetchProjectDetails(id)
          .then((response) => {
            const parsedResponse = JsonApiParse.parse(response);
            parsedResponse.data.access = response?.data?.meta?.access;
            commit("addProjectDetails", parsedResponse);
            commit("addProjectMeta", parsedResponse);
            commit("addProjectAlerts", parsedResponse);
            resolve();
          })
          .catch((error) => {
            console.log("getProjectDetails", error);
            reject();
            // showToastedMessage('Something went wrong, please try again later.', 'error');
          })
          .finally(commit("removeLoader", id))
      );
    },

    getProjectMeta({ commit, getters, dispatch }, id) {
      commit("addLoader", id);
      return new Promise((resolve, reject) =>
        fetchProjectMeta(id)
          .then((response) => {
            const parsedResponse = JsonApiParse.parse(response);
            const projectMeta = getters.getProjectMetaByProjectId(id);
            const responseHasChanged = parsedResponse.data.meta != projectMeta;

            const currentParsedMeta = JSON.parse(projectMeta);
            const currentAmountOfPendingTests =
              numberOfPendingTests(currentParsedMeta);

            const newParsedMeta = JSON.parse(parsedResponse.data.meta);
            const newAmountOfPendingTests = numberOfPendingTests(newParsedMeta);

            if (newAmountOfPendingTests < currentAmountOfPendingTests) {
              dispatch("triggerFinishedTestsNotification", id);
            }

            if (newAmountOfPendingTests === 0) {
              commit("unsubscribeFromPolling", { id });
            }

            Object.values(newParsedMeta).forEach(({ latest_status }) => {
              if (latest_status === "pending") {
                commit("subscribeToPolling", {
                  id,
                  meta: parsedResponse.data.meta,
                });
              }
            });

            commit("addProjectMeta", parsedResponse);
            resolve(responseHasChanged);
          })
          .catch((error) => {
            console.log("getProjectMeta", error);
            reject();
            // showToastedMessage('Something went wrong, please try again later.', 'error');
          })
          .finally(commit("removeLoader", id))
      );
    },

    subscribeProjectsWithPendingTestsToPolling({ state, commit, dispatch }) {
      if (state.projectsDetails.length === 0) return;

      state.projectsDetails.forEach(({ id, meta, meta_parsed }) => {
        if (
          Object.values(meta_parsed || {}).some(
            ({ latest_status }) => latest_status === "pending"
          )
        ) {
          commit("subscribeToPolling", {
            id,
            meta,
          });
        }
      });
      dispatch("startPollingMetadata");
    },

    startPollingMetadata({ state, dispatch }) {
      clearTimeout(state.pollingTimeout);
      const promises = [];
      state.pollingTimeout = setTimeout(() => {
        state.pollingSubscribers.forEach(({ id }) =>
          promises.push(dispatch("getProjectMeta", id))
        );
        Promise.all(promises).then(() => dispatch("startPollingMetadata"));
      }, 20000);
    },

    startReportPolling({ state, dispatch }) {
      clearTimeout(state.reportPollingTimeout);
      const promises = [];
      state.reportPollingTimeout = setTimeout(() => {
        state.reportPollingSubscribers.forEach((projectId) =>
          promises.push(dispatch("triggerSendReportByEmail", projectId))
        );
        Promise.all(promises).then(() => dispatch("startReportPolling"));
      }, 20000);
    },

    async triggerSendReportCrawlerByEmail({ commit }, { projectId, tests }) {
      try {
        const response = await exportMetatagCrawlerReport(projectId, tests);

        if (response.status === "completed") {
          if (Notification.permission === "granted") {
            const notification = new Notification(
                i18n.feedback.metatag_report.notification_title,
                {
                  body: i18n.feedback.metatag_report.browser_message,
                }
            );
            notification.onclick = (event) => {
              event.preventDefault();
              window.open(response.link);
            };
          } else {
            showToastedMessage(
                i18n.feedback.metatag_report.toast_message.replace(
                    ":downloadLink",
                    response.link
                ),
                "success"
            );
          }
        }
        return response;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },

    async triggerSendReportByEmail({ commit }, { projectId, tests }) {
      try {
        const response = await exportRecommendationReport(projectId, tests);

        if (response.status === "completed") {
          commit("unsubscribeFromReportPolling", projectId);

          if (Notification.permission === "granted") {
            const notification = new Notification(
              i18n.feedback.metatag_report.notification_title,
              {
                body: i18n.feedback.metatag_report.browser_message,
              }
            );
            notification.onclick = (event) => {
              event.preventDefault();
              window.open(response.link);
            };
          } else {
            showToastedMessage(
              i18n.feedback.metatag_report.toast_message.replace(
                ":downloadLink",
                response.link
              ),
              "success"
            );
          }
        }
        return response;
      } catch (e) {
        console.error(e);
        commit("unsubscribeFromReportPolling", projectId);
        return Promise.reject(e);
      }
    },

    triggerFinishedTestsNotification({ getters }, id) {
      const projectDetails = getters.findProjectDetails(id);
      if (!projectDetails) return;

      const message = i18n.feedback.tests.finished_for_project.replace(
        ":projectName",
        projectDetails.name
      );

      if (Notification.permission === "granted") {
        const notification = new Notification(
          i18n.feedback.tests.notification_title,
          {
            body: message,
          }
        );
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(`${BASE_FE_URL}/project/${id}`, "_blank");
        };
      } else {
        showToastedMessage(message, "success");
        return;
      }
    },

    async getEditedProject({ commit }, id) {
      await fetchProjectDetails(id)
        .then((response) => {
          const parsedResponse = JsonApiParse.parse(response);
          parsedResponse.data.access = response.data.meta.access;
          commit("setEditedProject", JsonApiParse.parse(response));
          commit("addProjectDetails", parsedResponse);
          commit("addProjectMeta", parsedResponse);
          commit("addProjectAlerts", parsedResponse);
        })
        .catch((error) => {
          console.log("getEditedProject", error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        })
        .finally(commit("removeLoader", id));
    },

    async getEditedCategory({ commit }, id) {
      await fetchCategoryDetails(id)
        .then((response) => {
          const parsedResponse = JsonApiParse.parse(response);
          parsedResponse.data.access = response.data.meta.access;
          commit("setEditedCategory", JsonApiParse.parse(response));
        })
        .catch((error) => {
          console.log("getEditedCategory", error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        })
        .finally(commit("removeLoader", id));
    },

    deleteProject({ commit }, id) {
      commit("addLoader", id);
      return new Promise((resolve, reject) => {
        destroyProject(id)
          .then(() => {
            commit("removeProject", id);
            resolve();
          })
          .catch(reject)
          .finally(commit("removeLoader", id));
      });
    },

    setSearch({ commit }, search) {
      commit("setSearch", search);
    },

    getProjectsList({ commit, state }, { categoryId, workflow }) {
      commit("addLoader", "projectsList");

      return fetchProjectsList(state.projectsApiUrl, categoryId, workflow)
        .then((response) => {
          commit("setProjectsList", [...state.projectsList, ...response.items]);
          commit("setProjectsApiUrl", response.next_page);
          return response;
        })
        .catch((error) => {
          console.log("getProjectsList", error);
        })
        .finally(() => {
          commit("removeLoader", "projectsList");
        });
    },

    async restoreProject({ dispatch, commit }, id) {
      commit("addRestoring", id);
      await editProject(
        {
          data: {
            id,
            type: "project--default_project",
            attributes: {
              workflow: "1",
            },
          },
        },
        id
      );
      commit("removeRestoring", id);
      return dispatch("getProjectDetails", id);
    },
  },

  mutations: {
    addLoader(state, id) {
      state.loaders = [...state.loaders, id];
    },

    removeLoader(state, id) {
      state.loaders = state.loaders.filter((item) => item !== id);
    },

    setProjects(state, projects) {
      state.projects = projects;
    },

    setProjectsList(state, projects) {
      const uniqProjects = uniqBy(projects, "uuid");
      state.projectsList = sortBy(uniqProjects, [(p) => p.name.toLowerCase()]);
    },

    /**
     * URL used for API pagination
     *
     * @param {object} state
     * @param {string} url
     */
    setProjectsApiUrl(state, url) {
      state.projectsApiUrl = url;
    },

    setEntries(state, projects) {
      const entries = [];

      (function iterate(ps, parent) {
        let parentId;
        let parentType;
        let parentIds;

        if (parent) {
          parentId = parent.id;
          parentType = parent.type;
          parentIds = parent.parentIds
            ? [...parent.parentIds, parent.id]
            : [parent.id];
        }

        for (const p of ps) {
          const obj = {
            id: p.id,
            type: p.type,
            label: p.label,
            parentId,
            parentType,
            parentIds,
            workflow: p.workflow,
          };

          entries.push(obj);

          if (p.children) {
            iterate(p.children, obj);
          }
        }
      })(projects);

      state.entries = sortBy(entries, (entry) => entry.label.toLowerCase());
    },

    addProjectDetails(state, project) {
      const uniqProjects = uniqBy(
        [project.data, ...state.projectsDetails],
        "id"
      );
      state.projectsDetails = sortBy(uniqProjects, [
        (p) => p.name.toLowerCase(),
      ]);
    },

    addProjectMeta(state, newProject) {
      const project = state.projectsDetails.find(
        (p) => p.id === newProject.data.id
      );

      Vue.set(project, "meta", newProject.data.meta);
      Vue.set(project, "meta_parsed", JSON.parse(newProject.data.meta));
    },

    addProjectAlerts(state, newProject) {
      const project = state.projectsDetails.find(
        (p) => p.id === newProject.data.id
      );

      const meta = JSON.parse(newProject.data.meta);
      let list = [];

      for (const variable in meta) {
        if (meta[variable]?.critical_error) {
          list = [...meta[variable]?.critical_error, ...list];
        }
      }

      Vue.set(project, "alerts", list);
    },

    addProjectsAlerts(state, newProjects) {
      // eslint-disable-next-line no-unused-vars
      newProjects.forEach((entry) => {
        const project = state.projectsDetails.find((p) => p.id === entry.id);
        const meta = JSON.parse(entry.meta);
        let list = [];

        for (const variable in meta) {
          if (meta[variable].critical_error) {
            list = [...meta[variable].critical_error, ...list];
          }
        }

        Vue.set(project, "alerts", list);
      });
    },

    addProjectsMeta(state, newProjects) {
      // eslint-disable-next-line no-unused-vars
      newProjects.forEach((entry) => {
        const project = state.projectsDetails.find((p) => p.id === entry.id);
        Vue.set(project, "meta", entry.meta);
        Vue.set(project, "meta_parsed", JSON.parse(entry.meta));
      });
    },

    addProjectsMessages(state, { ids, messages }) {
      ids.forEach((id) => {
        Vue.set(
          state.projectsMessages,
          id,
          messages.filter((message) => message.context === id)
        );
      });
    },

    addProjectsDetails(state, newProjects) {
      const uniqProjects = uniqBy(
        [...newProjects, ...state.projectsDetails],
        "id"
      );
      state.projectsDetails = sortBy(uniqProjects, [
        (p) => p.name.toLowerCase(),
      ]);
    },

    setEditedProject(state, project) {
      state.editedProject = project.data;
    },

    setEditedCategory(state, category) {
      state.editedCategory = category.data;
    },

    removeProject(state, id) {
      state.projects = state.projects.filter((p) => p.id !== id);
    },

    setError(state, error) {
      state.error = error;
    },

    setSearch(state, search) {
      state.search = search;
    },

    subscribeToPolling(state, subscriber) {
      const subscriberIds = state.pollingSubscribers.map(({ id }) => id);
      if (!subscriberIds.includes(subscriber.id)) {
        state.pollingSubscribers.push(subscriber);
      }
    },

    unsubscribeFromPolling(state, subscriber) {
      state.pollingSubscribers = state.pollingSubscribers.filter(
        (sub) => sub.id !== subscriber.id
      );
    },

    subscribeToReportPolling(state, subscriberId) {
      if (!state.reportPollingSubscribers.includes(subscriberId)) {
        state.reportPollingSubscribers.push(subscriberId);
      }
    },

    unsubscribeFromReportPolling(state, subscriberId) {
      state.pollingSubscribers = state.reportPollingSubscribers.filter(
        (subId) => subId !== subscriberId
      );
    },

    addRestoring(state, id) {
      state.restoring = [...state.restoring, id];
    },

    removeRestoring(state, id) {
      state.restoring = state.restoring.filter((i) => i !== id);
    },
  },

  getters: {
    getProjectMetaByProjectId:
      ({ projectsDetails }) =>
      (projectId) => {
        return projectsDetails.find((p) => p.id === projectId).meta;
      },
    getIsLoadingById:
      ({ loaders }) =>
      (id) =>
        loaders.some((item) => item === id),
    findProjectDetails:
      ({ projectsDetails }) =>
      (id) =>
        projectsDetails.find((p) => p.id === id),
    findProjectName:
      ({ projectsDetails }) =>
      (id) => {
        const selectedProject = projectsDetails.find((p) => p.id === id);
        return selectedProject ? selectedProject.name : "";
      },
    findCategoryName:
      ({ projects }) =>
      (uuid) => {
        const category = findObjectByProp(projects, "id", uuid);

        if (category) {
          return category.label;
        }

        return "";
      },
    findAllProjectsInCategory:
      ({ projects }) =>
      (uuid) => {
        const arr = [];
        const category = findObjectByProp(projects, "id", uuid);
        if (category && category.children && category.children.length > 0) {
          (function getProjects(children) {
            children.forEach((child) => {
              if (child.type === "project") {
                arr.push(child.id);
              } else if (child.children && child.children.length > 0) {
                getProjects(child.children);
              }
            });
          })(category.children);
        }

        return arr;
      },
    projectsIsLoading: ({ loaders }) => loaders.includes("projects"),
  },
};
