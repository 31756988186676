





















































import Vue, { PropType } from "vue";
import ScoreProgressBar from "@/components/scores/ScoreProgressBar.vue";
import Trend from "@/components/ui/Trend.vue";
import { mapGetters } from "vuex";
import { RawTest, RawTestType, JiraTicket as IJiraTicket } from "@/interfaces";
import {
  sortByCreatedDate,
  testScoreInPercent,
  testScoreTendency,
} from "@/lib/utils";
import Btn from "@/components/ui/Btn.vue";
import JiraTicket from "../ui/JiraTicket.vue";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";

export default Vue.extend({
  components: {
    ScoreProgressBar,
    Trend,
    Btn,
    JiraTicket,
  },

  props: {
    test: {
      type: Object as PropType<RawTest>,
      required: true,
    },
  },

  computed: {
    ...mapGetters("tests", ["getTestTypeByType", "getTestsByProjectAndType"]),

    testTypeId(): string {
      return this.test.type.replace("test--", "");
    },

    testType(): RawTestType | undefined {
      return this.getTestTypeByType(this.testTypeId);
    },

    progress(): number {
      return testScoreInPercent(this.test);
    },

    testData(): RawTest[] {
      return this.getTestsByProjectAndType(
        this.test.project.id,
        this.testTypeId
      );
    },

    previousTest(): RawTest {
      const indexTest = this.testData.indexOf(this.test);
      return indexTest >= 0 ? this.testData[indexTest + 1] : ({} as RawTest);
    },

    trend(): number {
      return testScoreTendency(this.test, this.previousTest);
    },

    jiraTickets(): IJiraTicket[] {
      return this.$store.state.jira.issues[this.testTypeId]?.slice(0, 3) || [];
    },

    sortedTickets(): IJiraTicket[] {
      return sortByCreatedDate(this.jiraTickets);
    },

    testDetailRoute(): Location {
      return {
        name: RouteNames.TestDetails,
        params: {
          projectId: this.test.project.id,
          type: this.testTypeId,
          testId: this.test.id,
        },
      };
    },
  },
});
