<template>
  <FormSchema
    :schema="schema"
    :components="components"
    v-model="model"
    class="JsonSchemaForm"
    @input="$emit('input', $event)"
  ></FormSchema>
</template>

<script>
import FormSchema, { NativeComponents } from "@formschema/native";
import OutlinedTextField from "./OutlinedTextField.vue";
import OutlinedTextArea from "./OutlinedTextArea.vue";
import ArrayField from "./ArrayField.vue";
import SingleRadio from "./SingleRadio.vue";
import Checkbox from "./Checkbox.vue";

class CustomComponents extends NativeComponents {
  constructor() {
    super();
    this.set("textarea", OutlinedTextArea);
    this.set("radio", SingleRadio);
    this.set("boolean", Checkbox);
    this.set("checkbox", Checkbox);
    this.set("string", OutlinedTextField);
    this.set("integer", OutlinedTextField);
    this.set("number", OutlinedTextField);
    this.set("password", OutlinedTextField);
    this.set("array", ArrayField);

    // this.set("file", InputElement);
    // this.set("image", InputElement);
    // this.set("enum", FieldsetElement);
    // this.set("object", FieldsetElement);
    // this.set("list", ListElement);
    // this.set("message", MessageElement);
  }
}

export default {
  components: {
    FormSchema,
  },
  props: {
    schema: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      components: new CustomComponents(),
      model: this.value,
    };
  },
};
</script>

<style scoped>
.JsonSchemaForm {
  margin-bottom: var(--spacing-32);
}

.JsonSchemaForm::v-deep {
  font: var(--typo-copy-14);
}

.JsonSchemaForm::v-deep fieldset {
  padding: var(--spacing-16) 0 0;
  margin: 0;
  border: none;
}

.JsonSchemaForm::v-deep fieldset legend {
  color: var(--color-decoration-Grey80);
}
</style>
