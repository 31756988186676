export default {
  all: "All",
  search: "Search",
  jira_issue: "Jira issue",
  actions: {
    back: "Back",
    back_to_overview: "Back to overview",
    cancel: "Cancel",
    delete: "Delete",
    archive: "Archive",
    restore: "Restore",
    edit: "Edit",
    edit_preset: "Edit preset",
    edit_category: "Edit category",
    edit_project: "Edit project",
    log_selected: "Log selected tests on Jira",
    log_test: "Log test on Jira",
    login: "Log in",
    logout: "Log out",
    submit: "Submit",
    learn_more: "Learn more",
    more_about: "More about :title",
    new_project: "Add project",
    new_category: "Add category",
    archived: "Archived",
    open_website: "Open website",
    rerun_selected: "Rerun selected tests",
    rerun_test: "Rerun test",
    rerun: "Rerun",
    running: "Running",
    create_ticket: "Create jira ticket",
    reset: "Restore project default settings",
    create_tickets: "Jira ticket",
    rerun_tests: "Test rerun",
    select_to_rerun_or_generate: "Select at least one test to launch an action",
    generate_report: "PDF report",
    forgot_password: "Forgot your password?",
    toggle_advanced_options: "Advanced options",
    click_here: "Click here",
    generate: "Generate",
    project: {
      new: {
        reset: "Back",
        submit: "Create project",
      },
      edit: {
        reset: "Back",
        submit: "Save changes",
      },
    },
    category: {
      new: {
        reset: "Back",
        submit: "Create category",
      },
      edit: {
        reset: "Back",
        submit: "Save changes",
      },
    },
    shield: {
      click_to_copy: "Click to copy the shield URL",
      copied_successfully: "URL copied to clipboard!",
      not_copied: "Could not copy the url to clipboard",
      about_shield:
        "Embed this status badge into third party services such as Slack, Mattermost and others by clicking the button on the left to copy the link).",
    },
    select_recommended_tests: "Select only recommended tests",
    toggle_dropdown_menu: "Toggle dropdown menu",
    start_tutorial: "Tutorial",
  },
  pages: {
    home: {
      name: "Home",
      title: "Project Overview",
    },
    archive: {
      title: "Archived Projects",
    },
    category: {
      new: {
        name: "New category",
        title: "Create a new category",
      },
      edit: {
        name: "Edit",
        title: "Edit category",
      },
    },
    project: {
      new: {
        name: "New project",
        title: "Create a new project",
      },
      edit: {
        name: "Edit",
        title: "Edit project",
      },
    },
  },
  test: {
    title: {
      one: "Test",
      other: "Tests",
    },
    summary: "Summary",
    responsibility: "Responsibility",
    testsuite: "Topic",
    date_pending: "${date}, pending…",
    pending:
      "This test is currently running. Please come back in a little bit.",
    no_data_logged: "No data was logged for this test.",
    importance: "Why is it important?",
    how_to_fix: "How can this be fixed?",
    test_score: "Test score",
    latest_test_run: "Latest test run",
    next_run: "Next run",
    latest_jira_ticket: "Latest Jira ticket",
    score_chart_title: "Latest test scores",
    results_title: "Test Results",
    results_errors_empty:
      "The test couldn't identify any errors that affect the quality of your website. Note: These results only apply to the metrics this test analyzes.",
    results_warning_empty:
      "The test couldn't identify any warnings that affect the quality of your website. Note: These results only apply to the metrics this test analyzes.",
    results_success_empty:
      "The test did not succeed in any of the checked pages. Note: These results only apply to the metrics this test analyzes.",
    pages_checked: "pages checked",
    page_checked: "page checked",
    no_tickets: "No tickets have been created for this test.",
    congratulations:
      "Congratulations! The test couldn't identify any issues that affect the quality of your website. Note: These results only apply to the metrics this test analyzes.",
    has_no_jira_project: "This project is not associated with a Jira project",
    no_tests_selected_report: "Select at least one test to generate a report",
    no_tests_selected_rerun: "Select at least one test to rerun",
    pending_info:
      "This test is currently running. A new snapshot is created as soon as it is done.",
    test_access_disabled_warning:
      "Mandatory Tests can only be deactivated by the project lead",
    overview: {
      title: "Tests overview",
      score: {
        title: "Test Score",
      },
      details: "Test Details",
      summary: {
        categories: "Categories",
      },
    },
  },
  test_table: {
    filters: {
      seo: "SEO",
      compliance: "Compliance",
      performance: "Performance",
    },
    columns: {
      select: "Select test",
      name: "Test name",
      score: "Test score",
      latest_run: "Latest test run",
      actions: "Actions",
    },
  },
  inherited_value_from: "Inherited from <b>:projectName</b> project settings",
  overriden_value: "Overriden value",
  no_results_found: "No results found.",
  no_data: "No data available.",
  not_available: "n.a.",
  scores: {
    current: "Current result",
    projectTitle: "Project Score",
    categoryTitle: "Category Scores",
    sectionTitle: "Project and category scores",
  },
  result: "Result",
  date: "Date",
  loading_data: "Loading data…",
  percent: "Percent",
  no_project_for_category: 'There are no projects in "${categoryName}"…',
  no_projects: "There are no projects available yet…",
  members: {
    null: "No members",
    one: "1 member",
    other: "${count} members",
    sectionTitle: "Project team",
    projectLead: "Project lead",
    projectMembers: "Project members",
  },
  project_summary: {
    title: "Summary",
    quality_chart: {
      threshold_toggle: "Show threshold for critical scores",
    },
  },
  project_tests: {
    title: "Tests & Results",
  },
  analyzing_project: {
    title: "Analysing project…",
    copy: "The project is being analysed, please check back in a little while.",
  },
  statistic: {
    overall: "Overall",
    scores_today: "Scores today",
    scores_details: "Scores details",
  },
  confirmation: {
    default: {
      confirm: "Yes",
    },
    archive_project: {
      title: "Archive this project?",
      copy: "Do you really want to move the <b>${name}</b> project into your archive?",
    },
    delete_project: {
      title: "Delete this project?",
      copy: "Do you really want to delete the <b>${name}</b> project?",
    },
    jira_ticket: {
      title: {
        one: "Create Jira ticket?",
        other: "Create Jira tickets?",
      },
      copy: {
        one: "This will create a ticket for <b>${name}</b>.",
        other: "This will create <b>${count}</b> tickets.",
      },
    },
    rerun: {
      title: {
        one: "Rerun this test?",
        other: "Rerun these tests?",
      },
      copy: {
        one: "This will rerun the test <b>${name}</b>.",
        other: "This will rerun <b>${count}</b> tests.",
      },
    },
  },
  feedback: {
    tests_started: {
      fail: "One or more tests could not be started. Please try again.",
      all: "All tests have been started.",
      other: "${count} of ${total} tests have been started.",
    },
    tests: {
      finished: "One or more tests have finished running",
      finished_for_project: "One or more tests finished in :projectName",
      notification_title: "Tests finished!",
    },
    project: {
      new: {
        success_full:
          "Thank you for creating your project. You need to wait for a couple of minutes until all of our tests have run.",
        success: "The project has been created.",
        error: "Something went wrong, please try again later.",
      },
      edit: {
        success: "Your changes have been saved.",
        error: "Something went wrong, please try again later.",
      },
      archive: {
        success: "Your project has been moved to your archive.",
        error: "Something went wrong, please try again later.",
      },
      delete: {
        success: "The project has been removed.",
        error: "Something went wrong, please try again later.",
      },
    },
    category: {
      new: {
        success: "The category has been created.",
        error: "Something went wrong, please try again later.",
      },
      edit: {
        success: "Your changes have been saved.",
        error: "Something went wrong, please try again later.",
      },
    },
    loginError: "An error has occurred. Please, try again in a moment.",
    reset_link_sent:
      "A link to reset your password has been sent to your email.",
    metatag_report: {
      generating:
        "Your report is being generated and will be sent to your e-mail in a few minutes",
      notification_title: "Report generated!",
      toast_message:
        "Your metatags report has been generated. <a href=':downloadLink' target='_blank'>Click here to download it</a>",
      browser_message:
        "Your metatags report has been generated. Click here to download it",
      error:
        "There was an error generating your metatags report.<br/> Please try again in a moment.",
    },
    metatag_report_crawler: {
      generating:
          "Your report is being generated and will be sent to your e-mail in a few minutes",
      notification_title: "Report generated!",
      toast_message:
          "Your metatags report has been generated. <a href=':downloadLink' target='_blank'>Click here to download it</a>",
      browser_message:
          "Your metatags report has been generated. Click here to download it",
      error:
          "There was an error generating your metatags report.<br/> Please try again in a moment.",
    },
    user: {
      new: {
        success: "The profile has been updated.",
        error: "Something went wrong, please try again later.",
      },
    },
  },
  status: {
    title: "Status",
    success: "Success",
    failed: "Failed",
    pending: "Pending",
  },
  user: {
    login: "Username",
    password: "Password",
    newPassword: "New password",
    tempPassword: "Temporary password",
    confirmPassword: "Confirm password",
    existingPassword: "Current password",
    email: "E-mail",
    profile: "Profile",
    form: {
      title: "Your profile",
      general: {
        details: "User information",
        password_change: "Change password",
        mail: "E-mail",
        name: "Username",
        firstname: "Firstname",
        surname: "Surname",
        job_title: "Job title",
        password_mismatch: "The passwords don't match",
        password_min_length: "The password must contain at least 6 characters.",
        required_name: "Please enter your name",
        existing_password_required: "Please enter your current password",
        required_mail: "Please enter your e-mail",
      },
    },
  },
  subscription: {
    trial_expired: {
      title: "Your free trial has expired.",
      description:
        "Register for one of our plans in order to log into your account.",
      subscribe_link: "Subscribe",
      contact_link: "Contact us",
    },
    success: {
      title: "Congratulations!",
      description: "Your subscription to Website Standards was successful.",
      login_link: "Login",
    },
  },
  project: {
    emptyCategory: "There is no project yet in :category",
    emptyArchive: "There are no archived projects.",
    export_metatag_report: "Metatags report",
    export_metatag_report_crawler: "Metatags report",
    export_metatag_report_alt: "Export metatags report",
    pending_metatag_report: "Generating metatags report...",
    attributes: {
      title: "Title",
      description: "Description",
      url: "Main URL",
      sitemap: "Sitemap URL",
      category: "Category",
      jira: {
        account: "Jira account",
        project_id: "Jira project ID",
        default_issue_type: "Default issue type",
        default_issue_priority: "Default issue priority",
        no_jira: "There are no Jira accounts yet.",
      },
      auth: {
        username: "Username",
        password: "Password",
      },
      logo: "Project logo",
      user_image: "Avatar",
      lead: "Project lead",
      members: "Project members",
      users: "Members",
    },
    tests_pending_info:
      "One or more tests are currently pending. A new snapshot is created as soon as all tests are done.",
    form: {
      general: {
        details: "Details",
        urls: "Tested URLs",
        basic_auth: "Basic auth",
        category: "Category",
        explanation:
          "Add the basic project details here. If you can supply a sitemap URL the tests can automatically test all project pages.",
      },
      advanced_configuration: "Advanced configuration",
      auth: {
        explanation:
          "If this site has HTTP authentication, add these details here.",
      },
      jira: {
        title: "Jira settings",
        explanation:
          "These options will be used when creating Jira tickets for failed tests.",
        no_account: "None",
      },
      advanced_settings: {
        title: "Advanced settings",
        proxy: {
          label: "Disable Proxy",
          description:
            "We use <a href='https://brightdata.com/' target='_blank'>Bright Data</a> for all of our requests from WSD. By selecting this configuration the proxy will be disabled.",
        },
        noindex_nofollow: {
          label: "Enable noindex nofollow Pages",
          description:
            "By selecting this configuration, all pages that are marked as noindex and/or nofollow will be included into the analysis. Otherwise these pages will be ignored by any test.",
        },
        page_loading: {
          label: "Disable Page Loading",
          description:
            "Using this configuration will disable all requests from WSD to your project. Select this configuration when you think your website is overloaded.",
        },
        crawler_selection: {
          label: "Select the Crawler Option",
        },
        sitemap_crawl_limit: {
          label: "Sitemap Crawl Limit",
          description:
            "Indicate a page crawl limit here to stop WSD from analysing all pages from your domain. Use this configuration to analyse only parts of your web application instead.",
        },
      },
      tests: {
        title: "Tests to perform",
        explanation: `<p>Here you can choose the tests for this project/category. Tests are marked with one of the following tags:</p>
          <ul class="u-list-unstyled">
            <li class="u-mt-4"><b>mandatory:</b> Tests that are crucial for a good quality</li>
            <li class="u-mt-4"><b>recommended:</b> Tests related to the state of the art</li>
            <li class="u-mt-4"><b>optional:</b> Tests that only apply to certain setups</li>
            <li class="u-mt-4"><b>experimental:</b> Tests that require certain settings</li>
          </ul>
          <p class="u-mt-4">Read the test descriptions for more info.</p>`,
        see_more: "See more",
        see_less: "See less",
      },
      users: {
        title: "Responsible Accounts",
        disabled_stakeholder: "Only the project lead can change this field",
        project_lead_label: "Project lead *",
        members_label: "Members",
      },
      category: {
        search: "Search for a category",
      },
    },
  },
  validation: {
    required_explanation: "Fields with * are required.",
    invalid_url: "Invalid URL.",
    required: "This field is required.",
  },
  category_warning: {
    title: "Warning",
    copy: "Changing categories could overwrite your test configuration with inherited values. Please review your settings carefully before saving.",
  },
  quality_charts: {
    title: "Latest quality scores",
  },
  sidebar: {
    title: "Departments & Projects",
  },
  project_teaser: {
    no_members: "no further members",
  },
  test_selection_modal: {
    title: "Generate Metatags Report?",
    description:
      "Select at least one of the following tests to download the Metatags Report as an Excel file.",
  },
  test_selection_modal_crawler: {
    title: "Generate Metatags Report?",
    description:
        "Select at least one of the following tests to download the Metatags Report as an Excel file.",
  },
  tutorial: {
    home: {
      welcome:
        "Welcome to Website Standards Dashboard! Let us help you get an overview of the tool.",
      create_new_projects:
        "Create new projects by clicking the green button below. All created projects will be displayed in alphabetical order on the left hand side.",
      overview:
        "In the overview, the overall score of all created projects is presented as well as the current trend, latest test runs and the assigned project team. Click on the individual project to find out more.",
      sort: "Use the sort option above to rearrange the order of the displayed projects.",
      dashboard_toggle:
        "The toggle view enables you to switch between the list view and the dashboard view. The dashboard view visualises all data with our sunburst.",
      profile:
        "Edit your personal account details in the user section. Here you can also find the FAQ as well as contact information to request assistance.",
      congratulations:
        "Congratulations, now you gained a general overview of the tool. Let's take a look into the projects in the next step, let's go!",
    },
    project: {
      ready:
        "Are you ready to take a look on how your web-application is doing?",
      summary:
        "In the project summary you'll find the overall score of your website as well as the three category scores. Also you can see their changes in the scores displayed in the graph.",
      navigation:
        "Use the buttons in the upper right corner to either go back to main navigation or change the chosen set of tests.",
      results_section:
        "In the tests & results section, all test results are provided with a general score, the date of the latest test run and the information when the next test run will be proceeded.",
      jira: "See in the right section if Jira tickets already have been created in order to work on the issues. If not, use the purple button to create a new Jira ticket and assign it automatically to the respective development team.",
      rerun:
        "If you don't want to wait until the next automated test run, click this button to rerun the test manually. You can also select multiple tests and start the manualrerun process by ticking the boxes on the left hand side.",
      pending_tests:
        "Tests that are still in process will be shown in the top of the page with a blue bar. Once finished, a push notification shows the completed test runs.",
      critical_warning:
        "A critical warning is raised when an error is found in certain selected tests. In this case, you should consider taking a look at the results. This is indicated with a read colour as well as an exlamation mark.",
      critical_warning_alert:
        "The critical warning is also indicated with a red bar above the general overview box. In case of a critical warning a Jira ticket is created automatically.",
      pdf_report:
        "Use the report function to export data from the tool. Currently, two report functions are available.",
      metatags_report:
        "The Metatags report provides the test results from various SEO tests, including Meta description, title tag, Open graph and others. Select tests and download the report as an xsxl excel file.",
      pdf_report_select:
        "The PDF report transforms the test results as well as the project overview page into a interactive PDF file for you to pass on internally or to your clients. Select tests and click on the button to initiate the download.",
      you_made_it:
        "You made it! With that being said, why don't we take a deep dive into the individual test detail pages? Follow me!",
    },
    test: {
      overview:
        "In this section it is possible to evaluate the actual test results. Do you want us to show you around?",
      summary:
        "In the test detail page a summary highlights the basic functionality of the test and why a high score is important. In the drop down menu on the right you find recommendations foor content creators and developers.",
      test_score:
        "The overview is comparable to the one you saw in the project page. Here you can find the test score, the number of pages checked as well as the test interval applied, created Jira tickets and the latest test scores  displyed in a graph.",
      test_results:
        "The test results are presented in Errors, Warnings and Successes. Here, you find all individual URLs the tool has been testing. Each level of results provides detailed information about the URLS tested.",
      test_results_details:
        "The results not only show which URLs can be optimized but also indicate what kind of component is missing or poorly implemented.",
      report_function:
        "You already know the report function from the project overview. By clicking the button you can directly download the individual test result as a PDF or as an excel file with the metatags report. Note: The metatags report feature is only available for certain tests.",
      jira: "Create a Jira ticket and transfer the test results directly to the respective development team. All necessary data will be automatically copied into the ticket.",
      automated_frequency:
        "Decide for yourself whether you want to keep the automated test frequency or test at your preferred times. Click the button to rerun the test manually.",
      congrats:
        "Congratulations, you completed the tour! If you have any further questions or need assistance, go to your user menu and drop us a message. If you want another tour at a later time, you can find this tutorial in the user menu!",
    },
    actions: {
      skip: "Skip",
      previous: "Previous",
      next: "Next",
      finish: "Finish",
      continue_tour: "Continue tour",
    },
  },
};
