<template>
  <project-form-expansion-panel :id="id" :title="label">
    <template #header>
      <span class="Test-checkboxWrapper">
        <checkbox
          :id="id"
          :disabled="disabled || userDoesNotHaveAccess"
          :label="label"
          :value="value"
          data-cy="project-form-test"
          class="Test-checkbox"
          @value-changed="onChange"
        />

        <span v-if="categoryLabel" class="Test-category">
          {{ categoryLabel }}
        </span>
      </span>
    </template>
    <template #content>
      <div v-if="short_description || category" class="Test-details">
        <p v-if="short_description" class="Test-detailsDescription">
          {{
            shortDescriptionExpanded
              ? short_description
              : computedShortDescription
          }}
        </p>
        <div v-if="shortDescriptionTooLong" class="Test-afterDescription">
          <btn
            type="button"
            @click="shortDescriptionExpanded = !shortDescriptionExpanded"
            class="Test-seeMore"
            small
          >
            {{ seeMoreLabel }}
          </btn>
        </div>

        <div class="Test-linkWrapper">
          <a
            v-if="documentation_url"
            class="Test-link"
            target="_blank"
            rel="noopener"
            :href="documentation_url"
            >Learn more</a
          >
        </div>
      </div>

      <div v-if="hasAdvancedConfig" class="Test-advancedOptions">
        <div
          class="Test-advancedOptionsContent"
          :id="id"
          :aria-labelledby="`toggle-${id}`"
        >
          <json-schema-form
            :schema="config_options"
            :value="workerConfig"
            @input="handleConfigInput"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </project-form-expansion-panel>
</template>

<script>
import Checkbox from "../Checkbox.vue";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import unlockOverrideStatusMixin from "../../mixins/override-inherited-value";
import JsonSchemaForm from "../form/JsonSchemaForm.vue";
import Btn from "@/components/ui/Btn.vue";
import { i18n } from "@/i18n";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import ProjectFormExpansionPanel from "./ProjectFormExpansionPanel.vue";

export default {
  mixins: [unlockOverrideStatusMixin],

  components: {
    Checkbox,
    ArrowDownIcon,
    JsonSchemaForm,
    Btn,
    ArrowDown,
    ProjectFormExpansionPanel,
  },

  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    short_description: {
      type: String,
      default: "",
    },
    category: {
      type: [String, Object],
      default: "",
    },
    documentation_url: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    config_options: {
      type: Object,
      default: () => ({}),
    },
    workerConfig: {
      type: Object,
      default: () => ({}),
    },
    currentUserIsStakeholder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      shortDescriptionExpanded: false,
    };
  },

  computed: {
    categoryLabel() {
      return this.category.label || this.category;
    },
    hasAdvancedConfig() {
      return Object.keys(this.config_options).length > 0;
    },
    shortDescriptionCharacterCount() {
      return this.short_description.length;
    },
    shortDescriptionMaxLength() {
      return 250;
    },
    shortDescriptionTooLong() {
      return (
        this.shortDescriptionCharacterCount > this.shortDescriptionMaxLength
      );
    },
    computedShortDescription() {
      return this.shortDescriptionTooLong
        ? this.short_description.slice(0, this.shortDescriptionMaxLength) + "…"
        : this.short_description;
    },
    userDoesNotHaveAccess() {
      return !this.currentUserIsStakeholder && this.category === "mandatory";
    },
    tooltipConfig() {
      return this.userDoesNotHaveAccess
        ? { content: i18n.test.test_access_disabled_warning }
        : null;
    },
    seeMoreLabel() {
      return this.shortDescriptionExpanded
        ? i18n.project.form.tests.see_less
        : i18n.project.form.tests.see_more;
    },
  },

  methods: {
    onChange(value) {
      this.$emit("test-selection-changed", { value, test: this.id });
      this.unlockOverrideStatus(this.id);
    },
    handleConfigInput(value) {
      this.$emit("test-config-input", { value, test: this.id });
    },
  },
};
</script>

<style scoped>
.Test-link,
.Test-link:visited {
  color: var(--color-decoration-Grey80);
}

.Test-link:hover,
.Test-link:focus {
  text-decoration: underline;
}

.Test-linkWrapper {
  text-align: right;
}

.Test-detailsDescription {
  margin-bottom: var(--spacing-8);
  color: var(--color-white);
}

.Test-detailsDescription,
.Test-link {
  font-size: var(--typo-copy-14-font-size);
}

.Test-afterDescription {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: var(--spacing-12) 0;
}

.Test-checkboxWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
}

.Test-category {
  display: inline-block;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font: var(--typo-copy-12);
  background: var(--color-decoration-Grey40);
  color: var(--color-white);
}

.Test-title {
  flex: 1;
  font: var(--typo-headline-18);
  color: var(--color-decoration-White);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Test-checkbox::v-deep .Checkbox-label::before {
  top: 0;
}
</style>
