

















import Vue from "vue";
import Capsule from "./Capsule.vue";
import ArrowIcon from "../../assets/icons/arrow.svg";

export default Vue.extend({
  components: {
    Capsule,
    ArrowIcon,
  },
  props: {
    trend: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    rotationDegrees(): number {
      let degrees = 90;

      if (this.trend > 0) {
        degrees = 0;
      } else if (this.trend === 0) {
        degrees = 45;
      }

      return degrees;
    },

    tendencyString(): string {
      let algSign = "";

      if (this.trend > 0) {
        algSign = "+";
      } else if (this.trend === 0) {
        algSign = "±";
      }

      return `${algSign}${this.trend}`;
    },
  },
});
