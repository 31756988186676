










import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import AuthForm from "./AuthForm.vue";
import { i18n } from "@/i18n";

import {
  postPasswordResetEndpoint,
  setClientId,
  setClientSecret,
} from "@/lib/auth";
import { CLIENT_ID, CLIENT_SECRET } from "@/constants";

export default Vue.extend({
  components: {
    AuthForm,
  },

  data() {
    return {
      postPasswordResetEndpoint,
      sent: false,
      fields: [
        {
          id: "name",
          label: i18n.user.login,
          value: "",
        },
        {
          id: "password",
          label: i18n.user.password,
          value: "",
          type: "password",
        },
        {
          id: "confirm_password",
          label: i18n.user.confirmPassword,
          value: "",
          type: "password",
        },
      ],
    };
  },

  methods: {
    ...mapActions("users", ["postResetPassword"]),
    ...mapActions("users", ["authenticateUser"]),
    ...mapMutations("users", ["setLoginError", "resetLoginError"]),

    submit(fields: Record<string, string>): void {
      this.resetLoginError();

      if (fields.password !== fields.confirm_password) {
        this.setLoginError({
          message: i18n.user.form.general.password_mismatch,
        });
        return;
      }

      this.postResetPassword({
        name: fields.name,
        temp_pass: this.$route.query.h,
        new_pass: fields.password,
      }).then(() => {
        setClientId(CLIENT_ID);
        setClientSecret(CLIENT_SECRET);
        this.authenticateUser({
          name: fields.name,
          pass: fields.password,
          redirect: "/",
        });
      });
    },
  },
});
