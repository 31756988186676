










import Vue from "vue";
export default Vue.extend({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    accent: {
      type: String,
      default: "perfect",
    },
  },
});
