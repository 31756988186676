










import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { CLIENT_ID, CLIENT_SECRET } from "@/constants";
import { setClientId, setClientSecret } from "@/lib/auth";
import AuthForm from "./AuthForm.vue";
import { i18n } from "@/i18n";

export default Vue.extend({
  components: {
    AuthForm,
  },

  data() {
    return {
      fields: [
        {
          id: "username",
          label: i18n.user.login,
          value: localStorage.getItem("username") || "",
        },
        {
          id: "password",
          label: i18n.user.password,
          value: "",
          type: "password",
        },
      ],
    };
  },

  methods: {
    ...mapActions("users", ["authenticateUser"]),
    ...mapMutations("users", ["resetLoginError"]),

    submit(fields: Record<string, string>): void {
      setClientId(CLIENT_ID);
      setClientSecret(CLIENT_SECRET);
      this.resetLoginError();
      this.authenticateUser({
        name: fields.username,
        pass: fields.password,
        redirect: this.$route.query?.redirect || "/",
      });
    },
  },
});
