























import Vue, { PropType } from "vue";
import {
  CategorizedTests,
  ResultLevels,
  TestSnapshot,
  TestSuiteResult,
} from "@/interfaces";
import QualityScoresChart from "./charts/QualityScoresChart.vue";
import Toggle from "@/components/form/Toggle.vue";

export default Vue.extend({
  components: {
    QualityScoresChart,
    Toggle,
  },
  data() {
    return {
      showThreshold: false,
    };
  },
  props: {
    testData: {
      type: Array as PropType<TestSnapshot[]>,
      required: true,
    },
    showThresholdToggle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    categorizedTests(): CategorizedTests {
      return this.testData.reduce(
        (datasets, test): CategorizedTests => {
          datasets.date.push(test.created);
          datasets.total.push(Math.round((test.score / test.max_score) * 100));

          let categories = ["seo", "performance", "compliance"];
          test.field_testsuite_results.forEach((suite: TestSuiteResult) => {
            datasets[suite.value].push(
              Math.round((suite.score / suite.max_score) * 100)
            );
            categories = categories.filter((c) => c !== suite.value);
          });
          // Ensures something fills up the spot if there's no result for this date
          categories.forEach((category) => datasets[category].push(null));

          return datasets;
        },
        {
          seo: [] as number[],
          performance: [] as number[],
          compliance: [] as number[],
          total: [] as number[],
          date: [] as string[],
        }
      );
    },
    threshold(): number {
      return this.showThreshold ? ResultLevels.Critical : 0;
    },
  },
});
