





















import Vue, { PropType } from "vue";
import AccordionItem from "./AccordionItem.vue";
import Results from "../Results.vue";
import { RawTest } from "@/interfaces";

export default Vue.extend({
  components: {
    AccordionItem,
    Results,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "Info",
    },
    test: {
      type: Object as PropType<RawTest>,
      required: true,
    },
    testType: {
      type: String,
      default: "",
    },
    resultsLoading: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accent() {
      switch (this.type) {
        case "Errors":
          return "critical";
        case "Warnings":
          return "warning";
        default:
          return "perfect";
      }
    },
  },
  methods: {
    typeLabel(singular = false) {
      switch (this.type) {
        case "Errors":
          return `error${!singular ? "s" : ""} found`;
        case "Warnings":
          return `warning${!singular ? "s" : ""} found`;
        default:
          return "";
      }
    },
    getSubtitle(item): string {
      return `${item.items.length} ${this.typeLabel(item.items.length === 1)}`;
    },
  },
});
