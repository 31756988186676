








































import Vue, { PropType } from "vue";
import { BASE_URL } from "@/constants";
import { FieldImage } from "@/interfaces";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";
import ExternalLinkIcon from "@/assets/icons/external-link.svg";

export default Vue.extend({
  components: {
    ExternalLinkIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    logo: {
      type: [Object, String] as PropType<FieldImage | string>,
      default: () => ({} as FieldImage),
    },
    tag: {
      type: String,
      default: "h1",
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
    maxTitleCharacters: {
      type: String,
      default: "50ch",
    },
  },
  computed: {
    logoUrl(): string {
      if (typeof this.logo === "string") return this.logo;
      if (this.logo?.uri?.url) {
        return BASE_URL + this.logo.uri.url;
      }
      return "";
    },

    projectRoute(): Location {
      return {
        name: RouteNames.ProjectOverview,
        params: {
          id: this.id,
        },
      };
    },

    displayUrl(): string {
      if (!this.url) return this.url;
      const url = new URL(this.url);
      const { username, password } = url;
      return this.url.replace(
        `${decodeURIComponent(username)}:${decodeURIComponent(password)}@`,
        ""
      );
    },
  },
});
