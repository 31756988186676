import Vue from "vue";
import Vuex from "vuex";
import Toasted from "vue-toasted";
import { VTooltip } from "v-tooltip";
import vSelect from "vue-select";
import PortalVue from "portal-vue";
import VueTour from "vue-tour";

import "vue-select/dist/vue-select.css";
import "vue-tour/dist/vue-tour.css";
import "./assets/css/index.css";

import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";

import {
  setClientId,
  setClientSecret,
  isLocalAuthPresent,
  accessTokenHasExpired,
  getRefreshTokenGrant,
  setAuthInfo,
} from "./lib/auth";

import { fetchUserInformation } from "./lib/api";

import { CLIENT_ID, CLIENT_SECRET } from "./constants";

import { i18n } from "./i18n";

setClientId(CLIENT_ID);
setClientSecret(CLIENT_SECRET);

Vue.use(Vuex);
Vue.use(Toasted);
Vue.component("vue-select", vSelect);
Vue.use(PortalVue);
Vue.use(VueTour);

Vue.directive("tooltip", VTooltip);

function initVue() {
  Vue.prototype.$i18n = i18n;

  /* eslint-disable no-new */
  new Vue({
    el: "#app",
    store,
    router,
    render: (h) => h(App),
  });
}

if (isLocalAuthPresent()) {
  if (accessTokenHasExpired()) {
    getRefreshTokenGrant()
      .then((response) => {
        setAuthInfo(response);
        store.commit("users/setAuthStatus", true);
      })
      .catch(() => {
        store.commit("users/setAuthStatus", false);
      })
      .finally(initVue);
  } else {
    fetchUserInformation()
      .then((response) => {
        store.commit("users/setAuthStatus", response.logged_in);
      })
      .catch(() => {
        store.commit("users/setAuthStatus", false);
      })
      .finally(initVue);
  }
} else {
  store.commit("users/setAuthStatus", false);
  initVue();
}
