import { i18n } from "@/i18n";
import { RouteNames } from "@/router/router";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      tutorialSteps: [
        {
          target: `[data-v-step="project-ready"]`,
          content: i18n.tutorial.project.ready,
          offset: -200,
          params: {
            placement: "bottom",
          },
        },
        {
          target: `[data-v-step="project-summary"]`,
          content: i18n.tutorial.project.summary,
          offset: -200,
        },
        {
          target: `[data-v-step="project-navigation"]`,
          content: i18n.tutorial.project.navigation,
          offset: -200,
        },
        {
          target: `[data-v-step="project-results_section"]`,
          content: i18n.tutorial.project.results_section,
          offset: -200,
        },
        {
          target: `[data-v-step="project-jira"]`,
          content: i18n.tutorial.project.jira,
          offset: -200,
        },
        {
          target: `[data-v-step="project-rerun"]`,
          content: i18n.tutorial.project.rerun,
          offset: -200,
        },
        {
          target: `[data-v-step="project-pending_tests"]`,
          content: i18n.tutorial.project.pending_tests,
          offset: -200,
        },
        {
          target: `[data-v-step="project-critical_warning"]`,
          content: i18n.tutorial.project.critical_warning,
          offset: -200,
        },
        {
          target: `[data-v-step="project-critical_warning_alert"]`,
          content: i18n.tutorial.project.critical_warning_alert,
          offset: -200,
        },
        {
          target: `[data-v-step="project-pdf_report"]`,
          content: i18n.tutorial.project.pdf_report,
          offset: -200,
        },
        {
          target: `[data-v-step="project-metatags_report"]`,
          content: i18n.tutorial.project.metatags_report,
          offset: -200,
        },
        {
          target: `[data-v-step="project-pdf_report_select"]`,
          content: i18n.tutorial.project.pdf_report_select,
          offset: -200,
        },
        {
          target: `[data-v-step="project-you_made_it"]`,
          content: i18n.tutorial.project.you_made_it,
          offset: -200,
          params: {
            placement: "bottom",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("users", ["doingTutorial"]),

    tutorialName: () => "tour",

    tutorialCallbacks() {
      return {
        onStart: () => this.$store.commit("users/setDoingTutorial", true),
        onStop: () => {
          if (!this.$tours.tour.isLast) {
            this.resetTutorialState();
          }
        },
        onSkip: () => this.resetTutorialState(),
        onFinish: () => {
          this.$router.push({
            name: RouteNames.TestDetails,
            params: {
              projectId: "tutorial",
              type: "accessibility_check",
              testId: "tutorial",
            },
          });
        },
      };
    },
  },

  methods: {
    ...mapActions("projects", [
      "getProjects",
      "getProjectsList",
      "getProjectDetails",
    ]),

    startTutorial() {
      if (this.doingTutorial) {
        this.$tours[this.tutorialName].start();
      }
    },

    resetTutorialState() {
      this.$store.commit("users/setDoingTutorial", false);
      this.getProjects();
      this.getProjectsList({
        categoryId: "",
        workflow: 1,
      });

      if (this.$route.name !== RouteNames.ProjectsHome) {
        this.$router.push({
          name: RouteNames.ProjectsHome,
        });
      }
    },
  },

  watch: {
    doingTutorial() {
      this.startTutorial();
    },
  },

  created() {
    if (this.$route.params.id === "tutorial") {
      this.$store.commit("users/setDoingTutorial", true);
    }
  },

  mounted() {
    this.startTutorial();
  },
});
