import JsonApiParse from "jsonapi-parse";
import { DrupalJsonApiParams } from "drupal-jsonapi-params/lib";

import { BASE_URL } from "../constants";
import { WSDAxios, WSDAxiosV2 } from "./auth";

import {
  convertTestSuites,
  convertTestTypes,
  buildTestsSubRequest,
  buildSnapshotsSubRequest,
} from "./utils";
import { RawTest, TestTypeOverview } from "@/interfaces";

export const ApiEndpoints = {
  ProjectMessages: "/wsd_message/wsd_message",
  UserInfo: "/user_info",
  Menu: "/menu",
  DefaultProjects: "/project/default_project",
  DefaultProject: "/project/default_project/:id",
  Categories: "/taxonomy_term/menu",
  CategoryDetails: "/taxonomy_term/menu/:id",
  LayeredConfig: "/project/config/dynamic/:type/:id",
  Subrequests: `${BASE_URL}/subrequests`,
  StorageConfig: "/field_storage_config/field_storage_config",
  TestType: "/test_types/:entity/:id",
  TestSnapshots: "/test/:testType",
  TestSnapshot: "/test/:testType/:id",
  JiraTickets: "/jira/tickets",
  JiraAccounts: "/jira_account/jira_account",
  ProjectJiraTickets: "/wsd_jira_ticket/wsd_jira_ticket",
  JiraProjects: "/jira/projects/:accountId",
  JiraPriorities: "/jira/priorities/:accountId",
  GetterOptions: "/getter_options",
  Users: "/user/user/",
  UserDetails: "/user/user/:id",
  EntityUsers: "/users/:entity/:entityId",
  UserPermissions: "/users-permissions",
  TestTypeDocumentation: "/test-type-documentation",
  RecommendationReport: "/project/:projectId/report",
  MetatagCrawlerReport: "/project/:projectId/report_crawler",
  ProjectList: "/category",
  ProjectTestList: "/project/tests/:projectId",
};

export const fetchProjectsMessages = (ids: string[]) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams.addFilter("context", ids, "IN").addFilter("archived", "0");

  return WSDAxios.get(
    `${ApiEndpoints.ProjectMessages}?${apiParams.getQueryString()}`
  ).then((response) => response.data);
};

/**
 * Fetches the logged in state of the current user
 *
 * @returns {promise}
 */
export const fetchUserInformation = () => {
  return WSDAxios.get(ApiEndpoints.UserInfo).then((response) => response.data);
};

/**
 * Fetches the project hierarchy.
 *
 * @returns {promise}
 */
export const fetchProjects = () =>
  WSDAxios.get(ApiEndpoints.Menu).then((response) => response.data);

/**
 * Fetches the details of paginated projects.
 * Order by name field, ASC (A->Z).
 * Includes users, stakeholder and users avatars.
 *
 * "workflow" determines if a project is published or not:
 * 1 = published
 * 2 = archived
 *
 * @returns {promise}
 */
export const fetchProjectsDetails = ({
  ids,
  workflow = 1,
}: {
  ids: string[];
  workflow: number;
}) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams
    .addInclude([
      "field_image",
      "field_project_users",
      "field_project_users.field_user_avatar",
      "field_project_stakeholder",
      "field_project_stakeholder.field_user_avatar",
    ])
    .addSort("name", "ASC");

  apiParams.addFilter("workflow", workflow.toString());

  if (ids?.length) {
    apiParams.addFilter("id", ids, "IN");
  }

  const url = `${
    ApiEndpoints.DefaultProjects
  }?${apiParams.getQueryString()}&jsonapi_access=update,delete`;

  return WSDAxios.get(url).then((response) => response.data);
};

/**
 * Fetches a single category identified by its ID.
 * Includes users, stakeholder and users avatars.
 *
 * @param {String} id
 * @returns {promise}
 */
export const fetchCategoryDetails = (id: string) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams.addFilter("id", "", "<>");

  const endpoint = ApiEndpoints.CategoryDetails.replace(":id", id);

  return WSDAxios.get(
    `${endpoint}?${apiParams.getQueryString()}&jsonapi_access=update,delete`
  ).then((response) => response.data);
};

/**
 * Fetches the layered configuration data for a category/project
 *
 * @param {String} type_base
 * @param {String} id_base
 * @returns {promise}
 */
export const fetchLayeredConfig = (type_base: string, id_base: string) => {
  const endpoint = ApiEndpoints.LayeredConfig.replace(
    ":type",
    type_base
  ).replace(":id", id_base);
  return WSDAxios.get(endpoint).then((response) => response.data);
};

/**
 * Fetches a single project identified by its ID.
 * Includes users, stakeholder and users avatars.
 *
 * @param {String} id
 * @returns {promise}
 */
export const fetchProjectDetails = (id: string) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams
    .addInclude([
      "field_image",
      "field_project_users",
      "field_project_users.field_user_avatar",
      "field_project_users.field_client",
      "field_project_stakeholder",
      "field_project_stakeholder.field_user_avatar",
      "field_project_stakeholder.field_client",
    ])
    .addFilter("id", "", "<>");

  const endpoint = ApiEndpoints.DefaultProject.replace(":id", id);
  return WSDAxios.get(
    `${endpoint}?${apiParams.getQueryString()}&jsonapi_access=update,delete`
  ).then((response) => response.data);
};

/**
 * Fetches the meta data of a single project identified by its ID.
 *
 * @param {String} id
 * @returns {promise}
 */
export const fetchProjectMeta = (id: string) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams.addFields("project--default_project", ["meta"]);

  const endpoint = ApiEndpoints.DefaultProject.replace(":id", id);
  return WSDAxios.get(`${endpoint}?${apiParams.getQueryString()}`).then(
    (response) => response.data
  );
};

/**
 * Fetches a set of snapshot results for a list of projects.
 * Sorted by 'created' field, DESC order.
 *
 * @param {Array} ids
 * @returns {promise}
 */
export const fetchSnapshotsForProjects = (ids: string[]) => {
  const subRequestArray = buildSnapshotsSubRequest({
    ids,
  });

  return WSDAxios.post(
    `${ApiEndpoints.Subrequests}?_format=json`,
    subRequestArray
  ).then((response) => response.data);
};

/**
 * Delete a project.
 *
 * @param {String} id
 * @returns {promise}
 */
export const destroyProject = (id: string) => {
  const endpoint = ApiEndpoints.DefaultProject.replace(":id", id);
  return WSDAxios.delete(endpoint).then((response) => response.data);
};

/**
 * Retrieve the list of testsuites.
 *
 * @returns {promise}
 */
export const fetchTestSuites = () => {
  return WSDAxios.get(
    `${ApiEndpoints.StorageConfig}?filter[drupal_internal__id][value]=test.field_testsuites`
  ).then((response) => convertTestSuites(response.data));
};

/**
 * Retrieve the list of test types.
 *
 * @param {string} id
 * @param {string} entity
 * @returns {promise}
 */
export const fetchTestTypes = (id: string, entity = "project") => {
  const endpoint = ApiEndpoints.TestType.replace(":entity", entity).replace(
    ":id",
    id
  );
  return WSDAxios.get(endpoint).then((response) =>
    convertTestTypes(response.data)
  );
};

/**
 * Retrieve all tests for a given testsuite (e.g. "SEO") for a project.
 *
 * @param {string} id - Project id
 * @param {string} testsuite
 * - The testsuite machinename that the test should belongs to (e.g. "SEO")
 * @param {array} testTypes
 * - An array of objects containing all available testTypes.
 * @returns {promise}
 */
export const fetchTestsForProject = ({
  id,
  testTypes,
}: {
  id: string;
  testTypes: string[];
}) => {
  const subRequestArray = buildTestsSubRequest({
    id,
    testTypes,
  });

  return WSDAxios.post(
    `${ApiEndpoints.Subrequests}?_format=json`,
    subRequestArray
  ).then((response) => response.data);
};

/**
 * Retrieve all tests of a given type for a give project.
 *
 * @param {string} projectId - Project id
 * @param {array} testType - The test-type we want to retrieve.
 * @returns {promise}
 */
export const fetchTestsOfTypeForProject = ({
  projectId,
  testType,
}: {
  projectId: string;
  testType: string;
}) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams
    .addFilter("project.id", projectId)
    .addSort("created", "DESC")
    .addFields(`test-${testType}`, [
      "score",
      "max_score",
      "field_testsuites",
      "project",
      "html_report_url",
      "created",
    ])
    .addPageLimit(10);

  const endpoint = ApiEndpoints.TestSnapshots.replace(":testType", testType);
  return WSDAxios.get(`${endpoint}?${apiParams.getQueryString()}`).then(
    (response) => response.data
  );
};

/**
 * Retrieve the data for a single test by ID and test-type.
 *
 * @param {string} id - The tests id.
 * @param {string} testType - The tests type.
 * @returns {promise}
 */
export const fetchTestData = ({
  id,
  testType,
}: {
  id: string;
  testType: string;
}) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams.addFields(`test--${testType}`, [
    "log_grouped_by_status_code",
    "status",
    "score",
    "max_score",
    "website_tested_count",
    "result",
    "html_report_url",
    "field_responsibility",
    "documentation_url",
    "id",
    "name",
    "langcode",
    "field_testsuites",
    "project",
    "created",
  ]);
  apiParams.addInclude(["test_type"]);

  const endpoint = ApiEndpoints.TestSnapshot.replace(
    ":testType",
    testType
  ).replace(":id", id);
  return WSDAxios.get(`${endpoint}?${apiParams.getQueryString()}`).then(
    (response) => response.data
  );
};

/**
 * Saves a jira ticket.
 *
 * @param {object}
 * @return {promise}
 */
export const saveJiraTicket = ({
  testId,
  link,
}: {
  testId: string;
  link: string;
}) => {
  const config = { headers: { "Content-Type": "application/json" } };

  return WSDAxios.post(
    `${ApiEndpoints.JiraTickets}?_format=json`,
    { testId, link },
    config
  ).then((response) => response.data);
};

/**
 * Send a POST request to the server to create a new test entity.
 *
 * @returns {promise}
 */
export const createTestRerun = ({
  type,
  name,
  documentation_url,
  field_responsibility,
  field_testsuites,
  project,
}: RawTest) => {
  const payload = {
    data: {
      type,
      attributes: {
        name,
        status: "pending",
        priority: 7,
        documentation_url,
        field_responsibility,
        field_testsuites,
      },

      relationships: {
        project: {
          data: [
            {
              type: project.type || "project--default_project",
              id: project.id,
            },
          ],
        },
      },
    },
  };

  const endpoint = ApiEndpoints.TestSnapshots.replace(":testType", type);
  return WSDAxios.post(`${endpoint}?use_cache=0&use_manual=1`, payload).then(
    (response) => response.data
  );
};

/**
 * Fetches a specific user.
 * Includes avatar.
 *
 * @param {string} name
 * @returns {promise}
 */
export const fetchUser = (name: string) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams
    .addFilter("name", name)
    .addInclude(["roles", "field_user_avatar", "field_client"]);

  return WSDAxios.get(
    `${ApiEndpoints.Users}?${apiParams.getQueryString()}`
  ).then((response) => response.data);
};

/**
 * Fetches all users.
 * Order by created field, DESC (newest first).
 * Includes avatars.
 *
 * @param {string} entityType
 * @param {string} entityId
 * @returns {promise}
 */
export const fetchUsersForEntity = (entityType: string, entityId: string) => {
  const apiParams = new DrupalJsonApiParams();
  apiParams
    .addInclude(["roles", "field_user_avatar", "field_client"])
    .addFilter("id", "", "<>");

  const endpoint = ApiEndpoints.EntityUsers.replace(
    ":entity",
    entityType
  ).replace(":entityId", entityId);
  return WSDAxios.get(`${endpoint}/?${apiParams.getQueryString()}`).then(
    (response) => response.data
  );
};

/**
 * Fetches user ids that have a given permission.
 *
 * @param {string} permission - The machinenname of the permission.
 * @returns {promise}
 */
export const fetchUsersWithPermission = (permission: string) => {
  return WSDAxios.get(
    `${ApiEndpoints.UserPermissions}?_format=json&permission=${permission}`
  ).then((response) => response.data);
};

/**
 * Fetches all Jira projects.
 *
 * @returns {promise}
 */
export const fetchAccounts = () => {
  return WSDAxios.get(ApiEndpoints.JiraAccounts).then(
    (response) => response.data
  );
};

export const fetchJiraIssuesForProject = (
  projectId: string,
  testType: string | null = null
) => {
  let endpoint = `${ApiEndpoints.ProjectJiraTickets}?filter[project.id]=${projectId}`;
  if (testType) {
    endpoint += `&filter[test_type]=${testType}`;
  }

  return WSDAxios.get(endpoint).then((response) => response.data);
};

/**
 * Fetches all Jira projects.
 *
 * @param {string} accountId
 * @returns {promise}
 */
export const fetchJiraProjects = (accountId: string) => {
  if (!accountId) {
    return Promise.resolve([]);
  }

  const endpoint = ApiEndpoints.JiraProjects.replace(":accountId", accountId);
  return WSDAxios.get(`${endpoint}?_format=json`).then(
    (response) => response.data
  );
};

/**
 * Fetches all Jira priorities.
 *
 * @returns {promise}
 */
export const fetchJiraPriorities = (accountId = "") => {
  if (!accountId) {
    return Promise.resolve([]);
  }

  const endpoint = ApiEndpoints.JiraPriorities.replace(":accountId", accountId);
  return WSDAxios.get(`${endpoint}?_format=json`).then(
    (response) => response.data
  );
};

export const fetchGetterOptions = () => {
  const endpoint = ApiEndpoints.GetterOptions;
  return WSDAxiosV2.get(endpoint).then((response) => response.data);
};

/**
 * Create new project.
 *
 * @param {object} payload - The new project data.
 * @returns {promise}
 */
export const addProject = (payload) => {
  return WSDAxios.post(ApiEndpoints.DefaultProjects, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(JsonApiParse.parse(error.response.data)));
};

/**
 * Edit an existing project identified by a given project id.
 *
 * @param {object} payload - The updated project data.
 * @param {string} id - ID of the project that is edited.
 * @returns {promise}
 */
export const editProject = (payload, id: string) => {
  const endpoint = ApiEndpoints.DefaultProject.replace(":id", id);
  return WSDAxios.patch(endpoint, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(JsonApiParse.parse(error.response.data)));
};

/**
 * Edit an existing category identified by a given category id.
 *
 * @param {object} payload - The updated project data.
 * @param {string} id - ID of the project that is edited.
 * @returns {promise}
 */
export const editCategory = (payload, id: string) => {
  const endpoint = ApiEndpoints.CategoryDetails.replace(":id", id);
  return WSDAxios.patch(endpoint, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(JsonApiParse.parse(error.response.data)));
};

/**
 * Creates a new category.
 *
 * @param {object} payload - The updated project data.
 * @returns {promise}
 */
export const addCategory = (payload) => {
  return WSDAxios.post(ApiEndpoints.Categories, payload)
    .then((response) => JsonApiParse.parse(response.data))
    .catch((error) => Promise.reject(JsonApiParse.parse(error.response.data)));
};

/**
 * Triggers report generation and gets link for report download
 *
 * @param {string} projectId
 * @returns {promise}
 */
export const exportRecommendationReport = (projectId, tests: string[] = []) => {
  const query = new URLSearchParams({ tests: tests.join(",") });
  const endpoint = ApiEndpoints.RecommendationReport.replace(
    ":projectId",
    projectId
  );
  return WSDAxios.get(`${endpoint}?${query}`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response.data));
};


/**
 * Triggers report generation of the new crawler.
 *
 * @param {string} projectId
 * @returns {promise}
 */
export const exportMetatagCrawlerReport = (projectId, tests: string[] = []) => {
  const endpoint = ApiEndpoints.MetatagCrawlerReport.replace(
      ":projectId",
      projectId
  );
  return WSDAxios.post(`${endpoint}`, {
    "reports": tests
  })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data));
};

export const updateUser = (userData) => {
  if (!userData.id) return Promise.reject(new Error("No user ID provided"));

  const data = {
    id: userData.id,
    type: "user--user",
    attributes: {
      field_user_firstname: userData.field_user_firstname,
      field_user_surname: userData.field_user_surname,
      field_user_title: userData.field_user_title,
    } as Record<string, string | object>,
  };
  if (userData.password) {
    data.attributes.pass = {
      existing: userData.existingPassword,
      value: userData.password,
    };
  }
  const endpoint = ApiEndpoints.UserDetails.replace(":id", userData.id);
  return WSDAxios.patch(`${endpoint}`, { data })
    .then((response) => response.data)
    .catch((error) => Promise.reject(JsonApiParse.parse(error.response.data)));
};

export const fetchUsers = () => {
  return WSDAxios.get(ApiEndpoints.Users)
    .then((response) => response.data)
    .catch((error) => JsonApiParse.parse(error.response.data));
};

/**
 * Fetches a paginated list of projects for the overview table.
 * Order by name field, ASC (A->Z).
 *
 * "workflow" determines if a project is published or not:
 * 1 = published
 * 2 = archived
 *
 * @returns {promise}
 */
export const fetchProjectsList = (
  url = "",
  categoryId: string,
  workflow = 1
) => {
  let endpoint = ApiEndpoints.ProjectList;
  if (workflow === 1) {
    endpoint = url ? url : `${endpoint}/${categoryId}?workflow=1`;
  } else if (workflow === 2) {
    endpoint = url ? url : `${endpoint}/${categoryId}?workflow=2`;
  }
  return WSDAxiosV2.get(endpoint)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Fetches a paginated list of tests for the overview table in the project overview.
 *
 * @returns {promise}
 */
export async function fetchProjectTestsList(
  projectId: string
): Promise<TestTypeOverview[]> {
  const tests: TestTypeOverview[] = [];

  const fetchTests = (offset: number) => {
    const endpoint = ApiEndpoints.ProjectTestList.replace(
      ":projectId",
      projectId
    );
    return WSDAxiosV2.get(`${endpoint}?offset=${offset}`)
      .then((response) => {
        tests.push(...response.data.items);
        return response.data;
      })
      .catch((error) => Promise.reject(error.response.data));
  };

  const responseData = await fetchTests(0);
  const pages = responseData.total / responseData.range;
  for (let page = 1; page < pages; page++) {
    await fetchTests(page * responseData.range);
  }

  return tests;
}
