<template>
  <div style="width: 100%; position: relative" v-if="tests.length">
    <report-cover-page class="TestDetail-projectCover" />

    <project-header
      v-if="project"
      :id="projectId"
      :url="url"
      :title="project.name"
      :logo="logo"
      hide-buttons
      has-link
    />

    <project-summary
      :id="projectId"
      class="TestDetail-projectSummary"
      :show-client="false"
      :show-threshold-toggle="false"
    />

    <tests-overview :tests="tests" class="TestDetail-testsOverview" />

    <div v-for="(test, i) in tests" :key="i" class="TestDetail-test">
      <print-test-summary
        v-if="test && testType(test.type)"
        :test="test"
        :test-type="testType(test.type)"
        :test-data="testData(test.type)"
        :test-meta="testMeta(test.type)"
        class="TestDetail-summary"
      />

      <notification-bar
        v-if="
          project && testType(test.type) && notificationForTestType(test.type)
        "
        :notifications="notificationForTestType(test.type)"
        show="full"
      ></notification-bar>

      <div v-if="!isLoading" class="StackedTestResults">
        <lighthouse-results
          v-if="testType(test.type).id.startsWith('lighthouse')"
          :results="lighthouseResults(test.type)"
          show-all
        />
        <template v-else>
          <stacked-results
            v-if="hasErrors(test)"
            :test="test"
            :items="reportItems(test, 'Errors').items"
            accent="critical"
            result-type="Errors"
            class="StackedTestResults-group"
          >
            <span>Error ({{ eventCounts(test).error }})</span>
          </stacked-results>

          <stacked-results
            v-if="hasWarnings(test)"
            :test="test"
            :items="reportItems(test, 'Warnings').items"
            accent="warning"
            result-type="Warnings"
            class="StackedTestResults-group"
          >
            <span>Warning ({{ eventCounts(test).warning }})</span>
          </stacked-results>

          <div
            v-if="!hasErrors(test) && !hasWarnings(test)"
            class="TestDetail-successMessageWrapper"
          >
            <p class="TestDetail-successMessage">
              {{ $i18n.test.congratulations }}
            </p>
          </div>
        </template>
      </div>
      <div v-else>
        <loading-indicator />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationBar from "@/components/NotificationBar";
import ProjectHeader from "@/components/ProjectHeader";
import PrintTestSummary from "@/components/print/TestSummary";
import StackedResults from "@/components/ui/StackedResults.vue";
import LoadingIndicator from "@/components/LoadingIndicator";
import LighthouseResults from "@/components/results/LighthouseResults.vue";
import ProjectSummary from "@/components/ProjectSummary";
import { urlForProject } from "@/lib/utils";
import ReportCoverPage from "@/components/ReportCoverPage.vue";
import TestsOverview from "@/components/tests-overview/TestsOverview.vue";

export default {
  components: {
    NotificationBar,
    ProjectHeader,
    PrintTestSummary,
    StackedResults,
    LoadingIndicator,
    LighthouseResults,
    ProjectSummary,
    ReportCoverPage,
    TestsOverview,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters("tests", [
      "getTestTypeByType",
      "getTestsByProject",
      "getTestsByProjectAndType",
    ]),
    ...mapGetters("projects", ["findProjectDetails"]),

    tests() {
      const tests = this.getTestsByProject(this.projectId);
      if (this.testTypesQuery) {
        return tests.filter((test) =>
          this.testTypesQuery.includes(test.type.replace("test--", ""))
        );
      }
      return tests;
    },

    project() {
      return this.findProjectDetails(this.projectId);
    },

    logo() {
      return this.project?.field_image;
    },

    url() {
      return urlForProject(this.projectDetails);
    },

    testTypesQuery() {
      const types = this.$route.query?.testType;
      if (!types) return [];

      return Array.isArray(types) ? types : [types];
    },
  },

  methods: {
    ...mapActions("projects", ["getProjectDetails"]),
    ...mapActions("tests", [
      "getTestData",
      "getTestTypes",
      "getTestResults",
      "loadSnapshotsForProjects",
    ]),
    ...mapActions("jira", ["getIssuesForTestType"]),

    parsedReport(test) {
      if (!test.log_grouped_by_status_code) return {};

      try {
        const parsed = JSON.parse(test.log_grouped_by_status_code);
        parsed.items = parsed.items.map((item) => {
          if (item.label === "Info") {
            item.items = item.items.map((subitem) => ({
              items: subitem.items,
            }));
          }
          return item;
        });
        return parsed;
      } catch (e) {
        return {};
      }
    },

    reportItems(test, resultType) {
      return (
        this.parsedReport(test)?.items?.find(
          (item) => item.label === resultType
        ) || {}
      );
    },

    testType(testType) {
      return this.getTestTypeByType(testType.replace("test--", ""));
    },

    testData(testType) {
      return this.getTestsByProjectAndType(
        this.projectId,
        testType.replace("test--", "")
      );
    },

    lighthouseResults(testType) {
      return this.testData(testType)?.find((test) =>
        Boolean(test.html_report_url)
      )?.html_report_url;
    },

    testMeta(testType) {
      if (!this.project) return {};
      return this.project.meta_parsed[testType.replace("test--", "")];
    },

    eventCounts(test) {
      let error = 0;
      let warning = 0;
      let success = 0;
      if (this.parsedReport(test)?.items?.length) {
        const { items } = this.parsedReport(test);
        items.forEach((item) => {
          if (item.label === "Errors") {
            error = item.count || 0;
          }
          if (item.label === "Warnings") {
            warning = item.count || 0;
          }
          if (item.label === "Info") {
            success = item.count || 0;
          }
        });
      }
      return { error, warning, success };
    },

    notificationForTestType(testType) {
      if (!this.project) return null;
      const type = testType.replace("test--", "");
      return this.project.meta_parsed?.[type]?.critical_error || null;
    },

    fetchTestsDetails() {
      const promises = [];
      this.tests.forEach((test) => {
        const type = test.type.replace("test--", "");
        if (
          !this.testTypesQuery ||
          (this.testTypesQuery.length && this.testTypesQuery.includes(type))
        ) {
          promises.push(
            this.getTestData({
              id: test.id,
              testType: type,
            })
          );
        }
      });
      return Promise.all(promises);
    },

    hasErrors(test) {
      return this.reportItems(test, "Errors")?.items?.length > 0;
    },

    hasWarnings(test) {
      return this.reportItems(test, "Warnings")?.items?.length > 0;
    },
  },

  created() {
    this.isLoading = true;
    this.getTestTypes({ id: this.projectId });
    this.loadSnapshotsForProjects([this.projectId]);
    this.getProjectDetails(this.projectId).then(() => {
      let types = Object.keys(JSON.parse(this.project.enabled_test_types));
      if (this.testTypesQuery.length) {
        types = this.testTypesQuery;
      }
      this.getTestResults({
        id: this.projectId,
        testTypes: types,
        keepResultsUntilFetched: true,
      })
        .then(() => this.fetchTestsDetails())
        .finally(() => {
          this.isLoading = false;
          setTimeout(() => {
            window.print();
          }, 500);
        });

      types.forEach((type) => {
        this.getIssuesForTestType({
          projectId: this.projectId,
          testType: type,
        });
      });
    });
  },
};
</script>

<style scoped>
.TestDetail-testsOverview + .TestDetail-test,
.StackedTestResults {
  margin-top: var(--spacing-42);
}

.TestDetail-test + .TestDetail-test {
  margin-top: var(--spacing-96);
}

.StackedTestResults-group {
  margin-top: var(--spacing-24);
}

.TestDetail-successMessageWrapper {
  padding: var(--spacing-32) var(--spacing-32);
  border: var(--spacing-1) solid var(--color-decoration-Grey20);
}

.TestDetail-successMessage {
  font: var(--typo-headline-18);
  max-width: 65ch;
  margin-bottom: 0;
  color: var(--color-decoration-Grey80);
}

.TestDetail-projectSummary {
  margin: var(--spacing-24) 0;
}

.TestDetail-projectSummary::v-deep .ProjectSummary-scores {
  width: 40%;
}

.TestDetail-projectSummary::v-deep .ProjectSummary-chart {
  width: 35%;
}

.TestDetail-projectSummary::v-deep .ProjectSummary-members {
  flex: 20%;
}

.TestDetail-projectSummary::v-deep .ProjectSummary-chart canvas,
.TestDetail-projectSummary::v-deep .ProjectSummary-chart div {
  max-width: 100%;
}

.TestDetail-projectSummary::v-deep .ProjectSummary-chart canvas {
  height: auto !important;
}

@media screen {
  .TestDetail-projectCover {
    margin: var(--spacing-24) 0;
  }
}

@media print {
  .TestDetail-test {
    page-break-before: always;
  }

  .TestDetail-projectCover {
    height: 96vh;
  }
}
</style>
