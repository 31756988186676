


































import Vue from "vue";
import ProjectFormWrapper from "@/components/category-form/ProjectFormWrapper.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Btn from "@/components/ui/Btn.vue";
import UserFormInformation from "@/components/user-form/UserFormInformation.vue";
import { showToastedMessage } from "@/lib/utils";
import { i18n } from "@/i18n";
import { updateUser } from "../lib/api";
import { mapActions, mapGetters } from "vuex";
import { getUsername } from "@/lib/auth";
import { User } from "@/interfaces";

export default Vue.extend({
  components: {
    ProjectFormWrapper,
    Btn,
    UserFormInformation,
    LoadingIndicator,
  },
  data() {
    return {
      isLoading: false,
      user: {
        mail: "",
        name: "",
        field_user_firstname: "",
        field_user_surname: "",
        field_user_title: "",
        password: "",
        confirmPassword: "",
        existingPassword: "",
      },
      errors: {},
    };
  },
  watch: {
    loggedInUser: {
      handler(user: User | undefined) {
        this.$set(this.user, "name", user?.name || "");
        this.$set(this.user, "mail", user?.mail || "");
        this.$set(
          this.user,
          "field_user_firstname",
          user?.field_user_firstname || ""
        );
        this.$set(
          this.user,
          "field_user_surname",
          user?.field_user_surname || ""
        );
        this.$set(this.user, "field_user_title", user?.field_user_title || "");
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("users", ["getUserByName"]),

    loggedInUser(): User | undefined {
      return this.getUserByName(getUsername());
    },
  },
  methods: {
    ...mapActions("users", ["authenticateUser", "getUsers"]),

    validate(): boolean {
      this.errors = {};

      if (this.user.password) {
        if (this.user.password.length < 6) {
          this.$set(
            this.errors,
            "password",
            i18n.user.form.general.password_min_length
          );
          showToastedMessage(
            i18n.user.form.general.password_min_length,
            "error"
          );
          return false;
        }

        if (this.user.password !== this.user.confirmPassword) {
          this.$set(
            this.errors,
            "confirm_password",
            i18n.user.form.general.password_mismatch
          );
          showToastedMessage(i18n.user.form.general.password_mismatch, "error");
          return false;
        }
      }

      if (!this.user.existingPassword) {
        this.$set(
          this.errors,
          "existing_password",
          i18n.user.form.general.existing_password_required
        );
        showToastedMessage(
          i18n.user.form.general.existing_password_required,
          "error"
        );
        return false;
      }

      return true;
    },
    handleSubmit: function (): void {
      if (!this.validate()) return;
      this.isLoading = true;

      const data = {
        id: this.loggedInUser?.id,
        field_user_firstname: this.user.field_user_firstname,
        field_user_surname: this.user.field_user_surname,
        field_user_title: this.user.field_user_title,
        password: this.user.password,
        existingPassword: this.user.existingPassword,
      };

      updateUser(data)
        .then(() => {
          showToastedMessage(i18n.feedback.user.new.success, "success");

          // Re authenticates the user, otherwise on next "user_info" request it will log the user out.
          this.authenticateUser({
            name: this.user.name,
            pass: this.user.password || this.user.existingPassword,
            redirect: null,
          });

          this.$set(this.user, "password", "");
          this.$set(this.user, "confirmPassword", "");
          this.$set(this.user, "existingPassword", "");
        })
        .catch((response) => {
          let message = i18n.feedback.user.new.error;
          if (response?.errors) {
            message = response.errors[0].detail;
          }
          showToastedMessage(message, "error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getUsers();
  },
});
