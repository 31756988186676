import { render, staticRenderFns } from "./ProjectInfo.vue?vue&type=template&id=3baf0df1&scoped=true&"
import script from "./ProjectInfo.vue?vue&type=script&lang=ts&"
export * from "./ProjectInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectInfo.vue?vue&type=style&index=0&id=3baf0df1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3baf0df1",
  null
  
)

export default component.exports