






































import Vue, { PropType } from "vue";
import OrderButton from "../ui/OrderButton.vue";

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
  None = "none",
}

export interface CustomTableConfig {
  columns: {
    label: string;
    id: string;
    orderable: boolean;
    order?: SortOrder;
    hideLabel?: boolean;
  }[];
}

export default Vue.extend({
  components: {
    OrderButton,
  },
  props: {
    options: {
      type: Object as PropType<CustomTableConfig>,
      default: () => ({} as CustomTableConfig),
    },
    items: {
      type: Array as PropType<object[]>,
      default: () => [],
    },
    sortFunction: {
      type: Function as PropType<(a: object, b: object) => number>,
      default: () => () => 0,
    },
  },
  computed: {
    orderedItems(): object[] {
      const orderCol = Object.values(this.options.columns).find(
        ({ order }) => order !== SortOrder.None
      );
      if (!orderCol) {
        return this.items;
      }

      return [...this.items].sort(this.sortFunction);
    },
  },
});
