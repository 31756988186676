


















import Vue, { PropType } from "vue";
import { Route } from "vue-router";
import Capsule from "../ui/Capsule.vue";

export default Vue.extend({
  components: {
    Capsule,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    categories: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    route: {
      type: Object as PropType<Partial<Route> | null>,
      default: null,
    },
  },
});
