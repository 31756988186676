






























































import Vue, { PropType } from "vue";
import { FieldImage } from "@/interfaces";
import Btn from "./ui/Btn.vue";
import ProjectInfo from "./project-teaser/ProjectInfo.vue";
import { i18n } from "@/i18n";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";

export default Vue.extend({
  components: {
    Btn,
    ProjectInfo,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    logo: {
      type: Object as PropType<FieldImage>,
      default: () => ({} as FieldImage),
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
    workflow: {
      type: String,
      default: "1",
    },
    backRoute: {
      type: Object as PropType<Location>,
      default: undefined,
    },
  },
  computed: {
    deleteButtonLabel(): string {
      return this.workflow === "1" ? i18n.actions.archive : i18n.actions.delete;
    },

    editProjectRoute(): Location {
      return {
        name: RouteNames.ProjectEdit,
        params: {
          id: this.id,
        },
      };
    },

    homeRoute(): Location {
      const firstCategory = this.$store.state.projects.projects.find(
        (project) => project.type === "category"
      );
      if (!firstCategory) {
        return {
          name: RouteNames.ProjectsHome,
        };
      }
      return {
        name: RouteNames.CategoriesHome,
        params: {
          id: firstCategory.id,
        },
      };
    },

    showRestoreButton(): boolean {
      return this.showDeleteButton && this.workflow === "2";
    },

    restoring(): boolean {
      return this.$store.state.projects.restoring.includes(this.id);
    },
  },
});
