






































import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  Chart,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { THEME, LEGACY_THEME } from "./theme";
import { getUsername, removeLocalAuthInfo } from "./lib/auth";
import Sidebar from "./Sidebar.vue";
import TopNavBar from "./components/TopNavBar.vue";
import { User } from "./interfaces";
import { RouteNames } from "./router/router";

const pollingInterval = 20000;

export default Vue.extend({
  components: {
    Sidebar,
    TopNavBar,
  },

  data() {
    return {
      sidebarOpened: document.documentElement.clientWidth > 768,
      testSuitesLoaded: false,
      projectsLoaded: false,
      mini: true,
    };
  },

  watch: {
    $route() {
      this.update();
    },

    currentCategory(newCategory: string | null) {
      this.$store.dispatch("categories/setCurrentCategory", newCategory);
    },

    sidebarPadding: {
      immediate: true,
      handler(padding) {
        document.documentElement.style.setProperty(
          "--sidebar-padding",
          `${padding}px`
        );
      },
    },
  },

  computed: {
    ...mapState("tests", ["testSuites"]),
    ...mapState("users", ["userIsAuthenticated", "users", "doingTutorial"]),
    ...mapState("projects", ["projects"]),
    ...mapGetters("users", ["getUserByName"]),

    loggedInUser(): User | undefined {
      return this.getUserByName(getUsername());
    },

    currentCategory(): string | null {
      // Returns the ID of the current category the user is visiting
      // or parent category if user views a project
      const currentId = this.$route.params.id || this.$route.params.projectId;
      const entries = this.$store.state.projects.entries;

      if (entries && entries.length > 0) {
        const currentEntry = entries.find(
          (entry) => entry.id === currentId && entry.type === "category"
        );

        if (currentEntry) {
          return currentEntry.id;
        }

        const entry = entries.find((entr) => entr.id === currentId);
        if (entry && entry.parentType === "category") {
          return entry.parentId;
        }
      }

      return null;
    },

    isPrintRoute(): boolean {
      return Boolean(this.$route?.name?.startsWith("print__"));
    },

    isErrorRoute(): boolean {
      return this.$route?.name === RouteNames.Error;
    },

    sidebarPadding(): number {
      if (this.isPrintRoute || this.isErrorRoute) return 0;
      return this.sidebarOpened ? 270 : 48;
    },

    showSidebar(): boolean {
      return (
        !this.isPrintRoute && this.userIsAuthenticated && !this.isErrorRoute
      );
    },
  },

  methods: {
    ...mapActions("projects", ["getProjects", "getArchivedProjects"]),
    ...mapActions("tests", ["getTestSuites"]),
    ...mapActions("users", ["getUser", "getUserInformation"]),

    logout() {
      removeLocalAuthInfo();
      document.location.href = "/login";
    },

    attemptRedirectToFirstCategory(entries) {
      const firstCategory = entries.find((entry) => entry.type === "category");
      if (this.$route.name === RouteNames.ProjectsHome && firstCategory) {
        this.$router.push({
          name: RouteNames.CategoriesHome,
          params: {
            id: firstCategory.id,
          },
        });
      }
    },

    update() {
      if (this.userIsAuthenticated) {
        if (!this.projectsLoaded || Object.keys(this.projects).length === 0) {
          this.projectsLoaded = true;
          this.getProjects().then((entries) => {
            this.attemptRedirectToFirstCategory(entries);
          });
        } else {
          this.attemptRedirectToFirstCategory(this.projects);
        }

        if (!this.testSuitesLoaded) {
          this.testSuitesLoaded = true;
          this.getTestSuites();
        }

        this.getUser(getUsername());
      }

      this.disableTutorial();
    },

    disableTutorial() {
      const allowedRoutesForTutorial = [
        RouteNames.ProjectsHome,
        RouteNames.CategoriesHome,
        RouteNames.ProjectOverview,
        RouteNames.TestDetails,
      ];

      if (
        !allowedRoutesForTutorial.includes(this.$route.name) &&
        this.doingTutorial
      ) {
        this.$store.commit("users/setDoingTutorial", false);
      }
    },

    pollAuthStatus() {
      if (this.userIsAuthenticated) {
        this.getUserInformation()
          .then(() => {
            setTimeout(() => {
              this.pollAuthStatus();
            }, pollingInterval);
          })
          .catch((response) => {
            if (response.status === 502 || response.status === 503) {
              this.$router.push({
                name: RouteNames.Error,
              });
            }
          });
      } else {
        setTimeout(() => {
          this.pollAuthStatus();
        }, pollingInterval);
      }
    },

    requestPermission() {
      if (this.userIsAuthenticated) {
        Notification.requestPermission().then(() => {
          document.removeEventListener("click", this.requestPermission);
        });
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.requestPermission);
  },

  created() {
    const styleElement = document.createElement("style");
    let styles = "";
    Object.entries(LEGACY_THEME).forEach(([name, value]) => {
      styles += `--color-${name}: ${value};`;
    });
    Object.entries(THEME).forEach(([name, value]) => {
      styles += `--color-${name}: ${value};`;
    });
    styleElement.innerText = `:root { ${styles} }`;
    document.querySelector("head")?.appendChild(styleElement);

    this.update();
    // Set some chart.js defaults.
    Chart.register(
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement
    );

    Chart.defaults.elements.line = {
      ...Chart.defaults.elements.line,
      tension: 0,
    };
    Chart.defaults.plugins.tooltip = {
      ...Chart.defaults.plugins.tooltip,
      borderColor: "#039be5",
      backgroundColor: "#039be5",
      cornerRadius: 0,
      displayColors: false,
    };
    Chart.defaults.color = THEME.grey80;
    Chart.defaults.font = {
      size: 12,
      family: "proxima-nova, sans-serif",
    };
    Chart.defaults.backgroundColor = THEME.grey15;

    this.pollAuthStatus();
  },
});
