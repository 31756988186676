











import Vue from "vue";
import ScoreProgressBar from "../scores/ScoreProgressBar.vue";

export default Vue.extend({
  components: {
    ScoreProgressBar,
  },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    colorRules: {
      type: Function,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: "Grey15",
    },
  },
});
