export const LEGACY_THEME = {
  primary_: "#039be5",
  primaryDark: "#0288d1",
  primaryLight: "#e5f6fd",
  accent: "#e76959",
  positive: "#00b194",
  positive2: "#8ba825",
  warning: "#ff9800",
  negative: "#ff4f4f",
  black: "#000",
  mainBg: "#f8fafc",
  greyDark: "#424242",
  grey: "#666",
  greyMedium: "#aaa",
  greyLight: "#eee",
};

// for now it's the same, but gives flexibility to change later
export const LEGACY_DARK_THEME = {
  primary: "#039be5",
  primaryDark: "#0288d1",
  primaryLight: "#e5f6fd",
  accent: "#e76959",
  positive: "#85FFC8",
  positive2: "#8ba825",
  warning: "#E1FF8A",
  negative: "#FFB285",
  black: "#000",
  mainBg: "#21242C",
  greyDark: "#424242",
  grey: "#666",
  greyMedium: "#aaa",
  greyLight: "#586174",
};

export const THEME = {
  white: "#FFF",
  grey80: "#C5CAD3",
  grey70: "#AEB4C2",
  grey40: "#586174",
  grey20: "#2E333D",
  grey15: "#21242C",
  black7: "#111216",
  accent: "#99D6FF",
  primary: "#695CFF",
  secondary: "#5200FF",
  perfect: "#85FFC8",
  success: "#E1FF8A",
  warning: "#FFE37F",
  fail: "#FFB285",
  critical: "#FF8F85",
  error: "#CC4040",
  errorDarker: "#992929",
};
