













import Vue from "vue";
export default Vue.extend({
  props: {
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
});
