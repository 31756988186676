
















import Vue, { PropType } from "vue";
import AccordionItem from "./results-accordion/AccordionItem.vue";

export default Vue.extend({
  components: {
    AccordionItem,
  },
  props: {
    results: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
});
