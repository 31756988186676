
























import Vue from "vue";
import { RouteNames } from "@/router/router";
import { mapGetters, mapState } from "vuex";
import ChevronRightIcon from "@/assets/icons/chevron-right.svg";
import { i18n } from "@/i18n";
import { Location } from "vue-router";

interface Breadcrumb {
  text: string;
  id?: string;
  to: string | Location;
  exact: boolean;
  disabled?: boolean;
}

export default Vue.extend({
  components: {
    ChevronRightIcon,
  },
  computed: {
    ...mapGetters("tests", ["getTestSuite", "getTestById"]),
    ...mapGetters("projects", ["findProjectName", "findCategoryName"]),
    ...mapState("projects", ["entries", "projects"]),

    projectName(): string {
      const projectId = this.$route.params.id || this.$route.params.projectId;
      return this.findProjectName(projectId);
    },

    testSuiteName(): string {
      const testSuite = this.getTestSuite(this.$route.params.testsuite);
      return testSuite ? testSuite.name : "";
    },

    testName(): string {
      const test = this.getTestById(this.$route.params.testId);
      return test ? test.name : "";
    },

    categories(): string[] {
      const currentId = this.$route.params.id || this.$route.params.projectId;
      const entries = this.entries;
      const categories: string[] = [];

      if (this.entries) {
        if (entries.length > 0) {
          const currentEntry = entries.find(
            (entry) => entry.id === currentId && entry.type === "category"
          );

          if (currentEntry) {
            categories.push(currentEntry.id);
          }

          (function getParentCategory(id) {
            const entry = entries.find((entr) => entr.id === id);
            if (entry && entry.parentType === "category") {
              categories.push(entry.parentId);
              getParentCategory(entry.parentId);
            }
          })(currentId);
        }

        return categories.reverse();
      }

      return [];
    },

    breadcrumbList() {
      const list: Breadcrumb[] = [];

      if (this.$route.meta?.breadcrumb) {
        this.$route.meta.breadcrumb.forEach((entry) => {
          if (entry.name === "category") {
            this.categories.forEach((category) => {
              list.push({
                text: this.getBreadcrumbName({
                  name: RouteNames.CategoriesHome,
                  id: category,
                }),
                id: category,
                to: this.routeTo({ name: "category", id: category }),
                exact: true,
              });
            });
          } else if (entry.name === "Home") {
            // Route to first category
            const firstCategory = this.projects.find(
              (entry) => entry.type === "category"
            );
            if (firstCategory) {
              list.push({
                text: "Home",
                id: firstCategory.id,
                to: this.routeTo({ name: "category", id: firstCategory.id }),
                exact: true,
              });
            } else {
              list.push({
                text: this.getBreadcrumbName(entry),
                exact: true,
                to: this.routeTo(entry),
              });
            }
          } else {
            list.push({
              text: this.getBreadcrumbName(entry),
              exact: true,
              to: this.routeTo(entry),
            });
          }
        });

        list[list.length - 1].disabled = true;
      }

      return list;
    },
  },

  methods: {
    getBreadcrumbName(item) {
      switch (item.name) {
        case "Home":
          return i18n.pages.home.name;
        case "category":
          return this.findCategoryName(item.id);
        case "projectName":
          return this.projectName;
        case "editProject":
          return i18n.pages.project.edit.name;
        case "testsuiteName":
          return this.testSuiteName;
        case "testName":
          return this.testName;
        case "newProject":
          return i18n.pages.project.new.name;
        case "newCategory":
          return i18n.pages.category.new.name;
      }
      return "";
    },

    routeTo(item) {
      if (item.name === i18n.pages.home.name) {
        return {
          name: RouteNames.ProjectsHome,
        };
      }
      if (item.name === "category") {
        return {
          name: RouteNames.CategoriesHome,
          params: {
            id: item.id,
          },
        };
      }
      if (item.name === "testsuiteName") {
        const projectId = this.$route.params.id || this.$route.params.projectId;
        return `/project/${projectId}/${this.$route.params.testsuite}`;
      }
      if (item.name === "projectName") {
        const projectId = this.$route.params.id || this.$route.params.projectId;
        return {
          name: RouteNames.ProjectOverview,
          params: {
            id: projectId,
          },
        };
      }
      return "";
    },
  },
});
