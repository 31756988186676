
























import Vue, { PropType } from "vue";
import { BASE_URL } from "../constants";
import { User, TooltipConfiguration } from "../interfaces";
import Capsule from "./ui/Capsule.vue";

export default Vue.extend({
  components: {
    Capsule,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarOrPlaceholder(): string {
      if (this.user?.avatar) return this.user.avatar;
      if (
        this.user.field_user_avatar &&
        this.user.field_user_avatar.uri &&
        this.user.field_user_avatar.uri.url
      ) {
        return BASE_URL + this.user.field_user_avatar.uri.url;
      }
      return "/avatar_placeholder.svg";
    },

    userName(): string {
      if (this.user.display_name) {
        return this.user.display_name;
      } else if (this.user.name) {
        return this.user.name;
      } else if (this.user.mail) {
        return this.user.mail;
      }

      return "";
    },

    tooltipConfig(): TooltipConfiguration | null {
      if (this.user.mail) {
        return {
          content: `Email: <a href="mailto:${this.user.mail}">${this.user.mail}</a>`,
          delay: { show: 500, hide: 1000 },
        };
      }
      return null;
    },

    client(): string {
      if (this.user.field_client?.length) {
        return this.user.field_client[0].name;
      }
      return "";
    },
  },
});
