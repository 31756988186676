<template>
  <div
    class="LineChart"
    :style="`height: ${
      height / 10
    }rem; background-color: var(--color-decoration-${backgroundColor});`"
  >
    <div
      class="LineChart-progress"
      :class="{ 'LineChart-progress--animated': animated }"
      :style="{
        '--LineChart-scale': `${scale}`,
        ...graphStyle,
      }"
    ></div>
  </div>
</template>

<script>
import { colorForScore } from "../../lib/utils";

export default {
  props: {
    progress: {
      type: Number,
      default: 87,
    },
    height: {
      type: Number,
      default: 4,
    },
    colorRules: {
      type: Function,
      default: colorForScore,
    },
    backgroundColor: {
      type: String,
      default: "Grey15",
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scale: 0,
    };
  },

  watch: {
    progress() {
      this.updateProgress();
    },
  },

  computed: {
    graphStyle() {
      const rule = this.colorRules(this.progress);
      if (rule.startsWith("#")) {
        return { backgroundColor: rule };
      }

      if (rule.includes("gradient")) {
        return { backgroundImage: rule };
      }

      return { background: rule };
    },
  },

  methods: {
    updateProgress() {
      this.scale = this.progress / 100;
    },
  },

  mounted() {
    this.updateProgress();
  },
};
</script>

<style>
.LineChart {
  border-radius: 0.5rem;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.LineChart-progress {
  --LineChart-scale: 0;
  border-radius: 0.5rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left;
  transform: scaleX(var(--LineChart-scale));
  transition: transform 300ms ease-in-out 200ms;
  width: 100%;
}

.LineChart-progress--animated {
  animation: gradient 500ms ease 8 alternate;
  background-size: 400% 100%;
  background-position: 50%;
}

@media (prefers-reduced-motion: reduce), (update: slow) {
  .LineChart-progress--animated {
    animation: none;
  }

  .LineChart-progress {
    transition: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
</style>
