import { i18n } from "@/i18n";
import { showToastedMessage } from "@/lib/utils";
import Vue, { CreateElement, VNode } from "vue";
import ExportMetatagsReport from "./ExportMetatagsReport.vue";
import TestSelectionModal from "./TestSelectionModal.vue";

export default Vue.extend({
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      testSelectionModalOpen: false,
    };
  },
  methods: {
    async handleSubmit(selectedTests: string[]) {
      this.testSelectionModalOpen = false;
      this.pending = true;
      const response = await this.$store
        .dispatch("projects/triggerSendReportByEmail", {
          projectId: this.projectId,
          tests: selectedTests,
        })
        .catch(() => {
          showToastedMessage(i18n.feedback.metatag_report.error, "error");
          this.pending = false;
          return;
        });

      if (response?.status === "pending") {
        this.$store.dispatch(
          "projects/subscribeToReportPolling",
          this.projectId
        );

        return showToastedMessage(
          i18n.feedback.metatag_report.generating,
          "success"
        );
      }

      if (response?.status === "error") {
        this.pending = false;
        return showToastedMessage(
          response.message || i18n.feedback.metatag_report.error,
          "error"
        );
      }
    },
    handleTestSelectionModal(value: boolean) {
      this.testSelectionModalOpen = value;
    },
    handleClick() {
      this.testSelectionModalOpen = true;
    },
  },
  render(createElement: CreateElement): VNode {
    return createElement("div", [
      createElement(ExportMetatagsReport, {
        props: {
          pending: this.pending,
        },
        on: {
          click: this.handleClick,
        },
      }),
      createElement(TestSelectionModal, {
        props: {
          value: this.testSelectionModalOpen,
        },
        on: {
          input: this.handleTestSelectionModal,
          submit: this.handleSubmit,
        },
      }),
    ]);
  },
});
