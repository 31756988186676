















































import { ProjectEntry } from "@/interfaces";
import { RouteNames } from "@/router/router";
import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import { mapState } from "vuex";
import { findObjectByProp, stringIncludesSearch } from "../lib/utils";
import ChevronRightIcon from "@/assets/icons/chevron-right.svg";

export default Vue.extend({
  name: "SidebarList",

  components: {
    ChevronRightIcon,
  },

  props: {
    items: {
      type: Array as PropType<ProjectEntry[]>,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      expandedItems: {},
    };
  },

  computed: {
    ...mapState("projects", ["entries"]),

    indexStyle(): string {
      return `--index: ${this.index}`;
    },

    filteredItems(): ProjectEntry[] {
      if (!this.search) return this.items;
      return this.items.filter((item) => {
        if (!item.children?.length) {
          return stringIncludesSearch(item.label, this.search);
        }

        return (
          stringIncludesSearch(item.label, this.search) ||
          item.children.some(
            (child) =>
              item.children?.length ||
              stringIncludesSearch(child.label, this.search)
          )
        );
      });
    },
  },

  methods: {
    searchQueryFound(item: ProjectEntry): boolean {
      const found: ProjectEntry[] = [];

      if (this.search) {
        item.children.forEach((child) => {
          if (findObjectByProp(child, "label", this.search, true)) {
            found.push(child);
          }
        });
      }

      return found.length > 0;
    },

    isOpen(item: ProjectEntry): boolean {
      let isExpanded = false;
      if (item.id in this.expandedItems) {
        isExpanded = this.isExpanded(item.id);
      }

      if (this.search) {
        return this.searchQueryFound(item) || isExpanded;
      }

      return isExpanded;
    },

    subProjectOpened(item: ProjectEntry): boolean {
      const activeProjectId =
        this.$route.params.projectId || this.$route.params.id;

      return JSON.stringify(item).includes(`"id":"${activeProjectId}"`);
    },

    toggleList(item: ProjectEntry) {
      this.$set(this.expandedItems, item.id, !this.isOpen(item));
    },

    isExpanded(id: string): boolean {
      if (!(id in this.expandedItems)) {
        return false;
      }
      return this.expandedItems[id];
    },

    projectRouteObject(id: string): Location {
      return {
        name: RouteNames.ProjectOverview,
        params: {
          id,
        },
      };
    },

    categoryRouteObject(id: string): Location {
      return {
        name: RouteNames.CategoriesHome,
        params: {
          id,
        },
      };
    },
  },
  mounted() {
    const activeProjectId =
      this.$route.params.projectId || this.$route.params.id;

    const getParentCategory = (id) => {
      const entry = this.entries?.find((entr) => entr.id === id);
      if (entry) {
        this.$set(this.expandedItems, entry.id, true);

        if (entry.parentType === "category") {
          getParentCategory(entry.parentId);
        }
      }
    };
    getParentCategory(activeProjectId);
  },
});
