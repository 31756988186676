<template>
  <div class="ProjectFormTestInformation">
    <project-form-title :description="$i18n.project.form.tests.explanation">
      {{ $i18n.project.form.tests.title }}
    </project-form-title>

    <outlined-text-field
      v-model="testSearch"
      label="Search tests"
      id="search-tests"
    />

    <div class="ProjectFormTestInformation-selectButtonWrapper">
      <btn
        type="button"
        color="transparent"
        outlined
        @click="handleSelectAllRecommended"
      >
        {{ $i18n.actions.select_recommended_tests }}
      </btn>
    </div>

    <ul class="ProjectFormTestInformation-list">
      <li
        class="ProjectFormTestInformation-suite"
        v-for="suite in validTestTypesGroupedBySuite"
        :key="suite.name"
      >
        <h3>{{ suite.name }}</h3>
        <ol
          class="ProjectFormTestInformation-list ProjectFormTestInformation-list--tests"
        >
          <li
            class="ProjectFormTestInformation-test"
            v-for="testType in suite.testTypes"
            :key="testType.id"
          >
            <test
              v-bind="testType"
              v-model="selectedTests[testType.id]"
              :disabled="testType.is_disabled"
              :worker-config="workerConfigForTestType(testType.id)"
              :current-user-is-stakeholder="currentUserIsStakeholder"
              @input="unlockOverrideStatus(testType.id)"
              @test-selection-changed="onTestSelectionChanged"
              @test-config-input="handleConfigInput"
            >
              <template #footer>
                <FieldInfo
                  :options="enabledTestsOptions[testType.id]"
                  :id="testType.id"
                  :value="selectedTests[testType.id]"
                  @inherit-value="selectedTests[testType.id] = $event"
                ></FieldInfo>
              </template>
            </test>
          </li>
        </ol>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Test from "./Test.vue";
import FieldInfo from "@/components/FieldInfo";
import unlockOverrideStatusMixin from "../../mixins/override-inherited-value";
import OutlinedTextField from "../form/OutlinedTextField.vue";
import Btn from "../ui/Btn.vue";
import ProjectFormTitle from "./ProjectFormTitle.vue";

export default {
  mixins: [unlockOverrideStatusMixin],
  components: {
    FieldInfo,
    Test,
    OutlinedTextField,
    Btn,
    ProjectFormTitle,
  },

  props: {
    enabledTests: {
      type: Object,
    },
    enabledTestsOptions: {
      type: Object,
    },
    workerConfig: {
      type: Object,
      default: () => ({}),
    },
    currentUserIsStakeholder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      testSearch: "",
    };
  },

  computed: {
    ...mapGetters("categories", ["getOverriddenFieldLock"]),
    ...mapState("tests", ["testTypes", "testSuites"]),

    testTypesGroupedBySuite() {
      return this.testSuites.map(({ name, machinename }) => {
        return {
          name,
          testTypes: this.testTypes.filter(({ testsuite_values, label }) => {
            if (!this.testSearch) return testsuite_values[0] === machinename;
            return (
              testsuite_values[0] === machinename &&
              label.toLowerCase().includes(this.testSearch.toLowerCase())
            );
          }),
        };
      });
    },

    validTestTypesGroupedBySuite() {
      return this.testTypesGroupedBySuite.filter(
        (item) => item.testTypes.length
      );
    },

    selectedTests() {
      return this.enabledTests;
    },

    recommendedTests() {
      return this.testTypes.reduce((acc, { id, category }) => {
        if (["recommended", "mandatory"].includes(category)) {
          acc.push(id);
        }
        return acc;
      }, []);
    },
  },

  methods: {
    onTestSelectionChanged({ value, test }) {
      this.$set(this.selectedTests, test, value);
      this.$emit("test-selection-changed", this.selectedTests);
    },
    handleConfigInput(config) {
      this.$emit("test-config-input", config);
    },
    workerConfigForTestType(testType) {
      if (!this.workerConfig?.test_types) return {};
      return this.workerConfig?.test_types?.[testType]?.worker_config || {};
    },
    handleSelectAllRecommended() {
      Object.keys(this.selectedTests).forEach((test_type_id) => {
        this.$set(this.selectedTests, test_type_id, false);
      });
      this.recommendedTests.forEach((test_type_id) => {
        this.$set(this.selectedTests, test_type_id, true);
      });
      this.$emit("test-selection-changed", this.selectedTests);
    },
  },
};
</script>

<style scoped>
.ProjectFormTestInformation-list {
  list-style: none;
  padding: 0;
}

.ProjectFormTestInformation-suite:not(:first-child) {
  margin-top: var(--spacing-48);
}

.ProjectFormTestInformation h3 {
  font-size: var(--typo-copy-16-font-size);
}

.ProjectFormTestInformation-test > *:only-child {
  margin-bottom: var(--spacing-24);
}

.ProjectFormTestInformation-test:first-child {
  margin-top: var(--spacing-24);
}

.ProjectFormTestInformation-selectButtonWrapper {
  margin-top: var(--spacing-48);
  text-align: right;
}
</style>
