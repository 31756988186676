import axios, { AxiosRequestConfig } from "axios";
import store from "../store/store";

import {
  API_OAUTH_URL,
  API_URL,
  BASE_URL,
  API_URL_V2,
  BASE_FE_URL,
} from "../constants";

const WSDAxios = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/vnd.api+json" },
});

const WSDAxiosV2 = axios.create({
  baseURL: API_URL_V2,
  headers: { "Content-Type": "application/vnd.api+json" },
});

let isRefreshing = false;

/**
 * Various getters for auth settings in localstorage.
 */
const getUsername = () => localStorage.getItem("username");
const getAccessToken = () => localStorage.getItem("access_token");
const getRefreshToken = () => localStorage.getItem("refresh_token");
const getClientId = () => localStorage.getItem("client_id");
const getClientSecret = () => localStorage.getItem("client_secret");

const setUsername = (username: string) =>
  localStorage.setItem("username", username);

/**
 * Removes all auth related info from localstorage.
 */
const removeLocalAuthInfo = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("expires_at");
};

/**
 * Checks if all neccessary auth info is stored in localstorage.
 *
 * @returns {Boolean}
 */
const isLocalAuthPresent = () =>
  localStorage.getItem("access_token") !== null &&
  localStorage.getItem("refresh_token") !== null &&
  localStorage.getItem("expires_in") !== null &&
  localStorage.getItem("expires_at") !== null;

/**
 * Sets the client id for oauth.
 *
 * @param {string} id
 */
const setClientId = (id: string) => localStorage.setItem("client_id", id);

/**
 * Sets the client secret for oauth.
 *
 * @param {string} secret
 */
const setClientSecret = (secret: string) =>
  localStorage.setItem("client_secret", secret);

/**
 * Handles updating the auth info for a user.
 *  - Saves auth info to localstorage.
 *  - Saves a variable to localstorage indicating that we have an authenticated
 *    user.
 *  - Sets the axios auth headers to new access token.
 *
 * @param {Object} authInfo
 */
const setAuthInfo = (authInfo: {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}) => {
  localStorage.setItem("access_token", authInfo.access_token);
  localStorage.setItem("refresh_token", authInfo.refresh_token);
  localStorage.setItem("expires_in", String(authInfo.expires_in));
  localStorage.setItem(
    "expires_at",
    (Date.now() + authInfo.expires_in * 1000).toString()
  );
};

/**
 * Checks if the current access token has expired.
 */
const accessTokenHasExpired = () =>
  Date.now() > parseInt(localStorage.getItem("expires_at") || "0", 10);

/**
 * Tries to get a new access token & refresh token from the API.
 *
 * @returns {promise}
 */
const getRefreshTokenGrant = () => {
  const formData = new FormData();
  formData.set("grant_type", "refresh_token");
  formData.set("refresh_token", getRefreshToken() || "");
  formData.set("client_id", getClientId() || "");
  formData.set("client_secret", getClientSecret() || "");

  isRefreshing = true;

  // Uses plain axios to NOT use auth request interceptors
  return axios
    .post(API_OAUTH_URL, formData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response.data))
    .finally(() => {
      isRefreshing = false;
    });
};

export const postForgotPasswordEndpoint = `${BASE_URL}/user/lost-password?_format=json`;

const postForgotPassword = (email) => {
  return axios
    .post(
      postForgotPasswordEndpoint,
      {
        mail: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

export const postPasswordResetEndpoint = `${BASE_URL}/user/lost-password-reset?_format=json`;

const postPasswordReset = ({ name, temp_pass, new_pass }) => {
  return axios
    .post(
      postPasswordResetEndpoint,
      {
        name,
        temp_pass,
        new_pass,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response.data));
};

/**
 * Tries to get a new access token & refresh token from the API with provided
 * username and password.
 *
 * @param {string} name
 * @param {string} pass
 * @returns {promise}
 */
const getPasswordGrant = (name: string, pass: string) => {
  // Set the username to retrieve logged-in users data later.
  setUsername(name);

  const formData = new FormData();
  formData.set("grant_type", "password");
  formData.set("client_id", getClientId() || "");
  formData.set("client_secret", getClientSecret() || "");
  formData.set("username", name);
  formData.set("password", pass);
  formData.set("scope", "business_analyst content_agency dph_agency");

  // Uses plain axios to NOT use auth request interceptors
  return axios
    .post(API_OAUTH_URL, formData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.response?.data || error));
};

/**
 * Decodes the JWT token
 *
 * @param {string} token
 * @returns {object}
 */
const parseJwt = (token: string): { scope: string[] } => {
  const base64Url = token.split(".")[1];
  if (!base64Url) return { scope: [] };
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 * Checks if token contains correct scope
 *
 * @returns boolean
 */
const isValidToken = () => {
  const token = getAccessToken();
  if (!token) return false;
  const parsedToken = parseJwt(token);
  if (
    "scope" in parsedToken &&
    parsedToken.scope.includes("wsd_frontend_app")
  ) {
    return true;
  }

  return false;
};

function injectAuthHeader(headers, token) {
  if (!token) {
    return headers;
  }

  if (!headers) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

const urlsToBeMocked = {
  "/menu": "tutorial/menu.json",
  "/category/": "tutorial/project-list.json",
  "/project/default_project/": "tutorial/yellowstone.json",
  "/test_types/project/tutorial": "tutorial/test-types.json",
  "/subrequests": "tutorial/subrequests.json",
  "/project/tests/tutorial": "tutorial/test-list.json",
  "/test/accessibility_check/tutorial": "tutorial/test-snapshot.json",
  "/test/accessibility_check": "tutorial/test-snapshots.json",
};

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authConfig = config;

  const shouldRefreshToken =
    (!isRefreshing && isLocalAuthPresent() && accessTokenHasExpired()) ||
    !isValidToken();

  if (shouldRefreshToken) {
    return new Promise((resolve, reject) => {
      getRefreshTokenGrant()
        .then((response) => {
          setAuthInfo(response);
          const bearer = getAccessToken();
          authConfig.headers = injectAuthHeader(authConfig.headers, bearer);
          resolve(authConfig);
        })
        .catch(() => {
          removeLocalAuthInfo();
          reject();
        });
    });
  } else {
    const bearer = getAccessToken();
    authConfig.headers = injectAuthHeader(authConfig.headers, bearer);
  }

  const isTutorial = store.state.users.doingTutorial;
  const foundKey = Object.keys(urlsToBeMocked).find((key) =>
    config.url?.includes(key)
  );
  if (isTutorial && foundKey) {
    // change url to the mock version
    authConfig.url = `${BASE_FE_URL}/${urlsToBeMocked[foundKey]}`;
    authConfig.method = "GET";
  }

  return authConfig;
};

/**
 * Sets an interceptor for each request.
 * The interceptor checks whether the access token is still valid.
 * If access token is expired, it tries to get a new access token via
 * refresh-token. If that also fails, it redirects to the login page.
 *
 * @param {Object} config
 * @returns {promise}
 */
WSDAxios.interceptors.request.use(requestInterceptor);

/**
 * Sets an interceptor for each request.
 * The interceptor checks whether the access token is still valid.
 * If access token is expired, it tries to get a new access token via
 * refresh-token. If that also fails, it redirects to the login page.
 *
 * @param {Object} config
 * @returns {promise}
 */
WSDAxiosV2.interceptors.request.use(requestInterceptor);

export {
  WSDAxios,
  WSDAxiosV2,
  getAccessToken,
  getRefreshToken,
  getClientId,
  getClientSecret,
  removeLocalAuthInfo,
  isLocalAuthPresent,
  setClientId,
  setClientSecret,
  setAuthInfo,
  accessTokenHasExpired,
  getRefreshTokenGrant,
  getPasswordGrant,
  getUsername,
  postForgotPassword,
  postPasswordReset,
};
