<template>
  <div>
    <p class="Message">{{ displayedMessage }}</p>
    <button class="ShowMore" v-if="!isExpanded" @click="expand">
      Show more
    </button>
  </div>
</template>

<script>
const truncatingLength = 140;

export default {
  name: "ResultMessage",

  props: {
    message: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: this.message.length <= truncatingLength,
    };
  },

  computed: {
    displayedMessage() {
      return this.isExpanded
        ? this.message
        : `${this.message.slice(0, truncatingLength)}…`;
    },
  },

  methods: {
    expand() {
      this.isExpanded = true;
    },
  },
};
</script>

<style>
.Message {
  display: inline-block;
}

.Message:not(:last-child) {
  margin-bottom: 0;
}

.ShowMore {
  color: var(--color-primary_);
  margin-left: 0.5em;
}
</style>
