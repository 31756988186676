import {
  FieldConfig,
  JiraAccount,
  JiraProject,
  ProjectRequest,
  RawTestType,
  RelationshipRequest,
  RelationshipType,
  SubRequest,
  SuiteRequest,
  User,
} from "@/interfaces";
import { DrupalJsonApiParams } from "drupal-jsonapi-params/lib";
import { API_URL } from "../../constants";
import { getAccessToken } from "../auth";

/**
 * Converts a test-suites API result from the backend to shorter format.
 *
 * @param {array} testSuitesData - Data as received from the backend.
 * @return {array} Format [{ name: HUMAN-READABLE, machinename: MACHINENAME }].
 */
const convertTestSuites = (testSuitesData): SuiteRequest[] => {
  if (testSuitesData.data && testSuitesData.data.length > 0) {
    // eslint-disable-next-line
    const dataArray =
      testSuitesData.data[0].attributes.third_party_settings.wsd_test
        .injected_allowed_options;
    const testSuitesArray: SuiteRequest[] = [];

    for (let index = 0; index < dataArray.length; index += 1) {
      const splitString = dataArray[index].split("|");
      const testSuiteObj = {
        machinename: splitString[0],
        name: splitString[1],
      };
      testSuitesArray.push(testSuiteObj);
    }

    return testSuitesArray;
  }

  return [];
};

/**
 * Converts a test-types API result from the backend to shorter format.
 *
 * @param {array} testTypesData - Data as received from the backend.
 * @return {RawTestType}
 */
const convertTestTypes = (testTypesData): RawTestType[] => {
  const testTypesArray: RawTestType[] = [];
  for (let index = 0; index < testTypesData.data.length; index += 1) {
    const attributes = testTypesData.data[index].attributes;
    const testSuiteObj: RawTestType = {
      id: attributes.drupal_internal__id,
      label: attributes.label,
      description: attributes.description,
      documentation_url: attributes.documentation_url,
      testsuite_values: attributes.testsuite_values,
      responsibility_values: attributes.responsibility_values,
      short_description: attributes.short_description,
      category: attributes.category,
      is_disabled: attributes.is_disabled,
      is_disabled_message: attributes.is_disabled_message,
      frequency: attributes.frequency,
      config_options: attributes.config_options,
    };
    testTypesArray.push(testSuiteObj);
  }
  return testTypesArray;
};

const buildSnapshotsSubRequest = ({ ids }: { ids: string[] }): SubRequest[] => {
  const subRequestsArray: SubRequest[] = [];

  ids.forEach((id, index) => {
    const apiParams = new DrupalJsonApiParams();
    apiParams
      .addFilter("project.id", id)
      .addFilter("score", "0", ">") // TODO: remove this when backend is stable (= doesnt accidentally create snapshots with 0)
      .addFilter("max_score", "0", ">")
      .addSort("created", "DESC")
      .addPageLimit(14);

    const requestObject: SubRequest = {
      requestId: `req${index}`,
      uri: `${API_URL}result/default_result?${apiParams.getQueryString()}`,
      action: "view",
      headers: {
        Accept: "application/vnd.api+json",
        ContentType: "application/vnd.api+json",
      },
    };

    const bearer = getAccessToken();

    if (bearer) {
      requestObject.headers["Authorization"] = `Bearer ${bearer}`;
    }

    subRequestsArray.push(requestObject);
  });

  return subRequestsArray;
};

const buildTestsSubRequest = ({
  id,
  testTypes,
}: {
  id: string;
  testTypes: string[];
}): SubRequest[] => {
  const subRequestsArray: SubRequest[] = [];
  const fields = [
    "status",
    "score",
    "max_score",
    "result",
    "field_responsibility",
    "documentation_url",
    "processed",
    "id",
    "name",
    "langcode",
    "field_testsuites",
    "project",
    "html_report_url",
    "created",
  ].join(",");

  testTypes.forEach((testType, index) => {
    const requestObject: SubRequest = {
      requestId: `req${index}`,
      uri: `${API_URL}test/${testType}?filter[project.id][value]=${id}&filter[status][value]=completed&sort[sort-created][path]=created&sort[sort-created][direction]=DESC&page[limit]=1&fields[test--${testType}]=${fields}`,
      action: "view",
      headers: {
        Accept: "application/vnd.api+json",
        ContentType: "application/vnd.api+json",
      },
    };

    const bearer = getAccessToken();

    if (bearer) {
      requestObject.headers["Authorization"] = `Bearer ${bearer}`;
    }

    subRequestsArray.push(requestObject);
  });

  return subRequestsArray;
};

/**
 * Private method to convert user into Api.
 *
 * @param {Array} users - Array of selected users.
 * @return {RelationshipRequest} Converted array users.
 */
const convertUsersToApi = (
  users: User[]
): RelationshipRequest<RelationshipType.User>[] => {
  const apiUsers: RelationshipRequest<RelationshipType.User>[] = [];
  users.forEach((user) => {
    const apiUser: RelationshipRequest<RelationshipType.User> = {
      id: user.id,
      type: RelationshipType.User,
    };
    apiUsers.push(apiUser);
  });
  return apiUsers;
};

/**
 * Private method to convert tests into Api.
 *
 * @param {Object} enabledTests - Object of selected tests.
 * @return {object}
 */
const convertTestsToApi = (enabledTests: {
  [id: string]: boolean;
}): { [id: string]: never[] } => {
  const tests: { [id: string]: never[] } = {};

  Object.entries(enabledTests).forEach(([id, selected]) => {
    if (selected) {
      tests[id] = [];
    }
  });

  return tests;
};

/**
 * Private method to create project object for Api.
 *
 * @param {String} projectTitle - String of project title.
 * @param {String} projectDescription - String of project description.
 * @param {String} projectBaseUrl - String of project base url.
 * @param {String} projectSitemapUrl - String of project sitemap url.
 * @param {String} reRunFrequency - String of Re-run Frequency selected.
 * @param {String} category - String of category id.
 * @param {Object} convertedTests - Object of enabled tests.
 * @param {String} defaultIssueType - String of selected issue type.
 * @param {String} defaultIssuePriority - String of selected default issue priority.
 * @param {Object} jiraProject - String of selected Jira project.
 * @param {String} id - String of the project's id.
 * @param {Object} stakeholderUser - Object of the stakeholder user selected.
 * @param {Array} apiUsers - Array of selected users.
 * @param {Array} authPassword - Password string for basic auth.
 * @param {Array} authPassword - Username string for basic auth.
 * @return {ProjectRequest} Structure Api for a project.
 */
const createProjectStructure = (
  projectTitle: string,
  projectDescription: string,
  projectBaseUrl: string,
  projectSitemapUrl: string,
  reRunFrequency: string,
  category: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertedTests: Record<string, any[]>,
  defaultIssueType: string,
  defaultIssuePriority: string,
  jiraAccount: JiraAccount,
  jiraProject: JiraProject,
  id: string | null,
  stakeholderUser: User,
  apiUsers: RelationshipRequest<RelationshipType.User>[],
  authUsername: string,
  authPassword: string,
  config: FieldConfig
): ProjectRequest => {
  const json: ProjectRequest = {
    data: {
      type: "project--default_project",
      attributes: {
        name: projectTitle,
        status: true,
        enabled_test_types: JSON.stringify(convertedTests),
        field_project_description: projectDescription,
        field_project_tested_url: projectBaseUrl,
        field_jira_tickets_type: defaultIssueType,
        field_jira_tickets_priority: defaultIssuePriority,
        field_jira_project_key: jiraProject ? jiraProject.key : "",
        field_project_sitemap_url: projectSitemapUrl,
        field_project_rerun_frequency: reRunFrequency,
        field_auth_username: authUsername,
        field_auth_password: authPassword,
        field_config: config,
      },
      id,
      relationships: {
        field_project_stakeholder: {
          data: {
            type: "user--user",
            id: stakeholderUser.id,
          },
          links: stakeholderUser.links,
        },
        field_project_users: {
          data: apiUsers,
        },
      },
    },
  };

  if (category) {
    json.data.relationships.field_menu = {
      data: {
        type: "taxonomy_term--menu",
        id: category,
      },
    };
  }

  if (jiraAccount && jiraAccount.id) {
    json.data.relationships.jira_account = {
      data: {
        id: jiraAccount.id,
        type: jiraAccount.type,
        attributes: {
          id: jiraAccount.id,
          langcode: jiraAccount.langcode,
          status: jiraAccount.status,
          label: jiraAccount.label,
          key: jiraAccount.key,
        },
        links: jiraAccount.links,
      },
      links: jiraAccount.links,
    };
  }

  return json;
};

/**
 * Convert new project to project-default API.
 *
 * @param {string} projectTitle - The project's title.
 * @param {string} projectDescription - The project's description.
 * @param {string} projectBaseUrl - The project's base URL.
 * @param {string} projectSitemapUrl - The project's SiteMap URL.
 * @param {string} reRunFrequency - The Re-run frequency selected.
 * @param {string} defaultIssueType - The Jira issue type of the project.
 * @param {string} defaultIssuePriority - The Jira issue priority of the object.
 * @param {object} jiraProject - The Jira project.
 * @param {Array} usersAccess - Array of the selected users.
 * @param {Object} stakeholderUser - The stakeholder user.
 * @return {ProjectRequest}
 */
const convertNewProjectToApi = (
  projectTitle: string,
  projectDescription: string,
  projectBaseUrl: string,
  projectSitemapUrl: string,
  reRunFrequency: string,
  category: string,
  enabledTests: { [id: string]: boolean },
  defaultIssueType: string,
  defaultIssuePriority: string,
  jiraAccount: JiraAccount,
  jiraProject: JiraProject,
  id = null,
  stakeholderUser: User,
  usersAccess: User[],
  authPassword: string,
  authUsername: string,
  config: FieldConfig
) => {
  const apiUsers = convertUsersToApi(usersAccess);
  const convertedTests = convertTestsToApi(enabledTests);
  return createProjectStructure(
    projectTitle,
    projectDescription,
    projectBaseUrl,
    projectSitemapUrl,
    reRunFrequency,
    category,
    convertedTests,
    defaultIssueType,
    defaultIssuePriority,
    jiraAccount,
    jiraProject,
    id,
    stakeholderUser,
    apiUsers,
    authPassword,
    authUsername,
    config
  );
};

const advancedConfigToWorkerConfig = (advancedConfig) => {
  console.log(advancedConfig.urlGetterOptions);
  const values: any = {
    defaults: {
      type: "worker",
      worker_config: {
        disable_proxy: advancedConfig.disableProxy,
        sitemap_crawl_limit: advancedConfig.sitemapCrawlLimit,
        enable_noindex_nofollow_pages:
          advancedConfig.enableNoindexNofollowPages,
        disable_page_loading: advancedConfig.disablePageLoading,
      },
    },
  };

  if (advancedConfig.urlGetter?.value !== undefined) {
    values.defaults.worker_config.url_getter = advancedConfig.urlGetter?.value;
  }
  // Only for the list.
  if (advancedConfig.urlGetter?.value === "2") {
    values.defaults.worker_config.url_getter_options =
      advancedConfig.urlGetterOptions;
  }

  return values;
};

const workerConfigToAdvancedConfig = (workerConfig) => {
  return {
    disableProxy: workerConfig.disable_proxy || false,
    urlGetter: workerConfig?.url_getter || "",
    urlGetterOptions: workerConfig?.url_getter_options || { urls: [] },
    sitemapCrawlLimit: workerConfig.sitemap_crawl_limit || 0,
    enableNoindexNofollowPages:
      workerConfig.enable_noindex_nofollow_pages || false,
    disablePageLoading: workerConfig.disable_page_loading || false,
  };
};

export {
  buildSnapshotsSubRequest,
  buildTestsSubRequest,
  convertNewProjectToApi,
  convertTestSuites,
  convertTestTypes,
  advancedConfigToWorkerConfig,
  workerConfigToAdvancedConfig,
};
