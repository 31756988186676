import { i18n } from "@/i18n";
import { RouteNames } from "@/router/router";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      tutorialSteps: [
        {
          target: `[data-v-step="home-welcome"]`,
          content: i18n.tutorial.home.welcome,
        },
        {
          target: `[data-v-step="home-create_new_projects"]`,
          content: i18n.tutorial.home.create_new_projects,
        },
        {
          target: `[data-v-step="home-overview"]`,
          content: i18n.tutorial.home.overview,
          offset: -200,
          params: {
            placement: "bottom",
          },
        },
        {
          target: `[data-v-step="home-sort"]`,
          content: i18n.tutorial.home.sort,
          offset: -200,
          params: {
            placement: "top",
          },
        },
        // {
        //   target: `[data-v-step="home-dashboard_toggle"]`,
        //   content:
        //     i18n.tutorial.home.dashboard_toggle,
        // },
        {
          target: `[data-v-step="home-profile"]`,
          content: i18n.tutorial.home.profile,
        },
        {
          target: `[data-v-step="home-congratulations"]`,
          content: i18n.tutorial.home.congratulations,
        },
      ],
    };
  },

  computed: {
    ...mapState("users", ["doingTutorial"]),

    tutorialName: () => "tour",

    tutorialCallbacks() {
      return {
        onStart: () => this.$store.commit("users/setDoingTutorial", true),
        onStop: () => {
          if (!this.$tours.tour.isLast) {
            this.resetTutorialState();
          }
        },
        onSkip: () => {
          this.resetTutorialState();
        },
        onFinish: () => {
          this.$router.push({
            name: RouteNames.ProjectOverview,
            params: { id: "tutorial" },
          });
        },
      };
    },
  },

  methods: {
    ...mapActions("projects", ["getProjects", "getProjectsList"]),

    resetTutorialState() {
      this.$store.commit("users/setDoingTutorial", false);
      this.getProjects();
      this.getProjectsList({
        categoryId: "",
        workflow: 1,
      });

      if (this.$route.name !== RouteNames.ProjectsHome) {
        this.$router.push({
          name: RouteNames.ProjectsHome,
        });
      }
    },

    startTutorial() {
      if (this.doingTutorial) {
        this.$tours[this.tutorialName].start();

        this.getProjects();
        this.$store.commit("projects/setProjectsList", []);
        this.getProjectsList({ categoryId: "tutorial_category" });
      }
    },
  },

  watch: {
    doingTutorial() {
      this.startTutorial();
    },
  },

  mounted() {
    this.startTutorial();
  },
});
