






































































import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import SimpleDialog from "../components/SimpleDialog.vue";
import { BASE_URL } from "../constants";
import router from "../router/router";
import { showToastedMessage, urlForProject } from "../lib/utils";
import ProjectSummary from "../components/ProjectSummary.vue";
import ProjectTests from "../components/ProjectTests.vue";
import ProjectHeader from "../components/ProjectHeader.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import Alert from "../components/ui/Alert.vue";
import Modal from "../components/ui/Modal.vue";
import ExportMetatagsReport from "@/components/export-metatags-report/ExportMetatagsReport.container";
import ExportMetatagsReportCrawler from "@/components/export-metatags-report-crawler/ExportMetatagsReportCrawler.container";

import UiCard from "@/components/ui/Card.vue";
import Tutorial from "@/components/Tutorial.vue";
import { i18n } from "@/i18n";
import { FieldImage, ProjectDetails, User } from "@/interfaces";
import ProjectTutorialMixin from "@/mixins/tutorial/project";

export default Vue.extend({
  mixins: [ProjectTutorialMixin],

  components: {
    SimpleDialog,
    ProjectSummary,
    ProjectHeader,
    ProjectTests,
    LoadingIndicator,
    Alert,
    Modal,
    ExportMetatagsReport,
    ExportMetatagsReportCrawler,
    UiCard,
    Tutorial,
  },

  watch: {
    $route() {
      this.update();
    },
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      isLoadingTests: false,
      deleteDialog: false,
      pollingTimeout: undefined as number | undefined,
    };
  },

  computed: {
    ...mapState("tests", ["testTypesFetching"]),
    ...mapGetters("tests", ["getTestSuite", "projectTestsList"]),
    ...mapGetters("projects", ["findProjectDetails"]),

    deleteConfirmationTitle(): string {
      const title =
        this.workflow === "1"
          ? i18n.confirmation.archive_project.title
          : i18n.confirmation.delete_project.title;

      return title;
    },

    deleteConfirmationMessage(): string {
      const message =
        this.workflow === "1"
          ? i18n.confirmation.archive_project.copy
          : i18n.confirmation.delete_project.copy;

      return message.replace("${name}", this.projectName);
    },

    deleteSuccessMessage(): string {
      const message =
        this.workflow === "1"
          ? i18n.feedback.project.archive.success
          : i18n.feedback.project.delete.success;

      return message;
    },

    deleteErrorMessage(): string {
      const message =
        this.workflow === "1"
          ? i18n.feedback.project.archive.error
          : i18n.feedback.project.delete.error;

      return message;
    },

    title(): string {
      return this.testSuite || this.projectName;
    },

    projectName(): string {
      if (!this.projectDetails) {
        return "";
      }

      return this.projectDetails.name;
    },

    logo(): FieldImage | undefined {
      return this.projectDetails?.field_image;
    },

    url(): string {
      if (!this.projectDetails) return "";
      return urlForProject(this.projectDetails);
    },

    workflow(): string {
      return this.projectDetails?.workflow || "";
    },

    projectDetails(): ProjectDetails | undefined {
      return this.findProjectDetails(this.id);
    },

    users(): User[] {
      return this.projectDetails
        ? this.projectDetails?.field_project_users
        : [];
    },

    enabledTestTypes(): string[] {
      if (!this.projectDetails) return [];

      return Object.keys(
        JSON.parse(this.projectDetails?.enabled_test_types || "")
      );
    },

    testSuite(): string {
      if (!this.$route.params.testsuite) return "";

      const suite = this.getTestSuite(this.$route.params.testsuite);

      return suite ? suite.name : "";
    },

    stakeholder(): User | null | undefined {
      return this.projectDetails?.field_project_stakeholder;
    },

    showEditButton(): boolean {
      return this.showButton("update");
    },

    showDeleteButton(): boolean {
      return this.showButton("delete");
    },
  },

  methods: {
    ...mapActions("projects", [
      "getProjects",
      "getProjectMeta",
      "getProjectDetails",
      "deleteProject",
      "subscribeProjectsWithPendingTestsToPolling",
      "restoreProject",
    ]),
    ...mapActions("tests", [
      "getTestResults",
      "getTestTypes",
      "getProjectTestsList",
    ]),
    ...mapActions("jira", ["getIssuesForProject"]),

    showButton(type) {
      if (!this.projectDetails || !this.projectDetails.access) return false;

      return this.projectDetails.access.includes(type);
    },

    deleteDialogCancel() {
      this.deleteDialog = false;
    },

    deleteDialogConfirm() {
      this.deleteProject(this.id)
        .then(() => {
          this.getProjects();
          showToastedMessage(this.deleteSuccessMessage, "success");
          router.push({ name: "projects" });
        })
        .catch(() => {
          showToastedMessage(this.deleteErrorMessage, "error");
        });
      this.deleteDialog = false;
    },

    fetchTestResults() {
      return this.getTestResults({
        id: this.id,
        testTypes: this.enabledTestTypes,
        keepResultsUntilFetched: true,
      });
    },

    pollMetaData(immediately?: boolean) {
      clearTimeout(this.pollingTimeout);
      this.pollingTimeout = setTimeout(
        () => {
          this.getProjectMeta(this.id).then((responseChanged) => {
            if (responseChanged) {
              this.getProjectTestsList(this.id).finally(() => {
                this.isLoadingTests = false;
              });
            }
            this.pollMetaData();
          });
        },
        immediately ? 0 : 20000
      );
    },

    restore() {
      this.restoreProject(this.id);
    },

    async update() {
      if (!this.testTypesFetching) {
        this.getTestTypes({ id: this.id, entity: "project" });
      }

      await this.getProjectDetails(this.id);
      this.subscribeProjectsWithPendingTestsToPolling();
      this.pollMetaData();
      this.isLoading = false;
      this.isLoadingTests = true;
      this.getProjectTestsList(this.id).finally(() => {
        this.isLoadingTests = false;
      });

      this.getIssuesForProject(this.id);
    },

    avatarOrPlaceholder(user) {
      if (user?.field_user_avatar?.uri?.url) {
        return BASE_URL + user.field_user_avatar.uri.url;
      }
      return "/avatar_placeholder.svg";
    },

    userName(user: User): string {
      if (user.display_name) {
        return user.display_name;
      } else if (user.name) {
        return user.name;
      } else if (user.mail) {
        return user.mail;
      }

      return "";
    },
  },

  created() {
    console.log("test");
    this.update();
  },

  beforeDestroy() {
    clearTimeout(this.pollingTimeout);
  },
});
