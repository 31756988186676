



















































import Vue from "vue";
import { mapGetters } from "vuex";
import {
  ProjectDetails,
  RawTest,
  TestSnapshot,
  TestStatus,
  User,
} from "@/interfaces";
import UiCard from "./ui/Card.vue";
// import Capsule from "./ui/Capsule.vue";
import ProjectScoresSection from "./scores/ProjectScoresSection.container";
import ProjectMemberList from "./ProjectMemberList.vue";
import QualityScoresSection from "./QualityScoresSection.vue";
import NotificationBar from "../components/NotificationBar.vue";
import Alert from "./ui/Alert.vue";
import ProjectShield from "./ProjectShield.vue";

export default Vue.extend({
  components: {
    UiCard,
    // Capsule,
    ProjectScoresSection,
    ProjectMemberList,
    QualityScoresSection,
    NotificationBar,
    Alert,
    ProjectShield,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showClient: {
      type: Boolean,
      default: true,
    },
    showThresholdToggle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("projects", ["findProjectDetails"]),
    ...mapGetters("tests", [
      "getTestSnapshotsForProject",
      "getTestsByProject",
      "projectTestsList",
    ]),

    projectDetails(): ProjectDetails | undefined {
      return this.findProjectDetails(this.id);
    },

    testData(): TestSnapshot[] {
      return (this.getTestSnapshotsForProject(this.id) as TestSnapshot[]) || [];
    },

    testsIds(): {
      id: string;
      type: string;
      project_id: string;
    }[] {
      const tests = this.getTestsByProject(
        this.projectDetails?.id
      ) as RawTest[];

      return tests.map(({ id, type }) => ({
        id,
        type: type.replace("test--", ""),
        project_id: this.id,
      }));
    },

    users(): User[] {
      return this.projectDetails
        ? this.projectDetails?.field_project_users
        : [];
    },

    stakeholder(): User | null | undefined {
      return this.projectDetails?.field_project_stakeholder;
    },

    hasPendingTests(): boolean {
      return this.projectTestsList(this.id)
        .map((entry) => entry.status)
        .includes(TestStatus.Pending);
    },
  },
});
