<template>
  <div>
    <project-form-title>
      {{ $i18n.project.form.users.title }}
    </project-form-title>

    <custom-select
      class="ProjectFormUserInformation-field"
      :label="$i18n.project.form.users.project_lead_label"
      option-label="name"
      id="stakeholderUser"
      :invalid="isFieldInvalid('stakeholderUser')"
      v-tooltip="tooltipConfig"
      :value="stakeholderUser"
      :items="filteredStakeholders"
      :placeholder="`${$i18n.project.attributes.lead} *`"
      :disabled="stakeholderFieldDisabled"
      @input="handleStakeholderInput"
    ></custom-select>
    <field-info
      id="stakeholder"
      class="u-my-2"
      :options="stakeholderUserIdInputOptions"
      :value="stakeholderUser"
      :disabled="stakeholderFieldDisabled"
      @inherit-value="stakeholderUser = $event"
    ></field-info>
    <template v-if="stakeholderUser && stakeholderUser.name !== undefined">
      <user-teaser-info
        :key="stakeholderUser.name"
        :user-info="stakeholderUser"
        :base-url="baseUrlPhoto"
        :is-stakeholder-user="true"
        :disabled="
          getOverriddenFieldLock('stakeholder') || stakeholderFieldDisabled
        "
        @removeUser="removeUser"
      ></user-teaser-info>
    </template>

    <custom-select
      class="ProjectFormUserInformation-field"
      :label="$i18n.project.form.users.members_label"
      option-label="name"
      multiple
      id="members"
      :value="usersAccess"
      :items="filteredUsers"
      @input="handleUsersInput"
    ></custom-select>
    <field-info
      id="user_select"
      class="u-my-1"
      :options="usersIdInputOptions[usersIdInputOptions.user_select]"
      :value="usersAccess"
      @inherit-value="handleUsersInput"
    ></field-info>
    <div v-for="user in usersAccess" :key="user.id">
      <user-teaser-info
        :user-info="user"
        :base-url="baseUrlPhoto"
        :is-stakeholder-user="false"
        @removeUser="removeUser"
      ></user-teaser-info>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

import { BASE_URL } from "../../constants";

import UserTeaserInfo from "../user-teaser/UserTeaserInfo";
import FieldInfo from "@/components/FieldInfo";
import unlockOverrideStatusMixin from "../../mixins/override-inherited-value";
import CustomSelect from "@/components/form/Select.vue";
import { i18n } from "@/i18n";
import ProjectFormTitle from "./ProjectFormTitle.vue";

export default {
  mixins: [unlockOverrideStatusMixin],

  props: {
    usersIdInput: {
      type: Array,
      default: () => [],
    },
    usersIdInputOptions: {
      type: Object,
      default: () => ({}),
    },
    stakeholderUserIdInput: {
      type: String,
      default: "",
    },
    stakeholderUserIdInputOptions: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    stakeholderUpdateDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FieldInfo,
    UserTeaserInfo,
    CustomSelect,
    ProjectFormTitle,
  },

  data() {
    return {
      stakeholderUser: {},
      baseUrlPhoto: BASE_URL,
      usersAccess: [],
      stakeholderFieldDisabled: false,
      rules: {
        required: (value) =>
          typeof value === "string" || this.$i18n.validation.required,
        requiredArray: (value) =>
          value.length !== 0 || this.$i18n.validation.required,
      },
    };
  },

  watch: {
    users() {
      this.setStakeHolder();
      this.setUsers();
    },

    stakeholderUserIdInput() {
      this.setStakeHolder();
    },

    stakeholderUser() {
      this.$emit("stakeholder-user", this.stakeholderUser);
    },

    usersAccess() {
      this.$emit("users-access", this.usersAccess);
    },

    usersIdInput() {
      this.setUsers();
    },
  },

  computed: {
    ...mapGetters("categories", ["getOverriddenFieldLock"]),
    ...mapState("users", ["users"]),
    ...mapGetters("users", ["getBusinessAnalystUsers"]),

    filteredUsers() {
      return this.users.filter((user) => {
        return !this.usersAccess.some(
          (userAccess) => userAccess.id === user.id
        );
      });
    },

    filteredStakeholders() {
      return this.getBusinessAnalystUsers.filter((user) => {
        return user.id !== this.stakeholderUser?.id;
      });
    },

    tooltipConfig() {
      return this.stakeholderFieldDisabled
        ? { content: i18n.project.form.users.disabled_stakeholder }
        : null;
    },
  },

  methods: {
    setUsers() {
      this.usersAccess = [];
      if (this.usersIdInput.length !== 0) {
        this.usersIdInput.forEach((userId) => {
          const user = this.users.find(
            (userElement) => userElement.id === userId
          );
          if (user) {
            if (!this.usersAccess.map(({ id }) => id).includes(user.id)) {
              this.usersAccess.push({
                ...user,
                name: user.name || user.display_name,
              });
            }
          }
        });
      }
    },

    setStakeHolder() {
      const user = this.users.find(
        (userElement) => userElement.id === this.stakeholderUserIdInput
      );

      if (user) {
        this.stakeholderUser = user;
      }
    },

    removeUser(userInfo) {
      if (userInfo.isStakeholderUser) {
        this.stakeholderUser = {};
      } else {
        const indexUserAccess = this.usersAccess.findIndex(
          (user) => user.id === userInfo.user.id
        );
        this.usersAccess.splice(indexUserAccess, 1);
      }
    },

    handleStakeholderInput(e) {
      this.stakeholderUser = e || {};
      this.unlockOverrideStatus("stakeholder");
    },

    handleUsersInput(e) {
      this.usersAccess = e || [];
      this.unlockOverrideStatus("user_select");
    },

    isFieldInvalid(field) {
      return (
        this.$store.state.forms.formSubmitted &&
        !this.$store.getters["forms/isFieldValid"](field)
      );
    },
  },
  mounted() {
    this.stakeholderFieldDisabled = this.stakeholderUpdateDisabled;
  },
};
</script>

<style scoped>
.ProjectFormUserInformation-field {
  margin-top: var(--spacing-24);
}
</style>
