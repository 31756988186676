
































import Vue, { PropType } from "vue";
import ButtonTab from "@/components/ui/ButtonTab.vue";
import ResultsAccordion from "@/components/results/results-accordion/Accordion.vue";
import { RawTest } from "@/interfaces";

export default Vue.extend({
  components: {
    ButtonTab,
    ResultsAccordion,
  },
  props: {
    accent: {
      type: String,
      default: "perfect",
    },
    items: {
      type: Array,
      default: () => [],
    },
    resultType: {
      type: String,
      default: "Info",
    },
    test: {
      type: Object as PropType<RawTest>,
      required: true,
    },
  },
  computed: {
    testType(): string {
      return this.test.type.replace("test--", "");
    },
  },
});
