















import Vue from "vue";
import Btn from "@/components/ui/Btn.vue";
import { i18n } from "@/i18n";

export default Vue.extend({
  components: {
    Btn,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon(): string {
      return this.pending ? "spinner-icon" : "table-icon";
    },
    reportButtonLabel(): string {
      return this.pending
        ? i18n.project.pending_metatag_report_crawler
        : i18n.project.export_metatag_report_crawler;
    },
  },
});
