

































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import Btn from "@/components/ui/Btn.vue";
import TextField from "@/components/form/TextField.vue";
import { Location } from "vue-router";
import { i18n } from "@/i18n";
import { RouteNames } from "@/router/router";

interface AuthFormField {
  label: string;
  id: string;
  value: string;
}

export default Vue.extend({
  components: {
    Btn,
    TextField,
  },

  props: {
    endpoint: {
      type: String,
      required: true,
    },
    fields: {
      type: Array as PropType<AuthFormField[]>,
      required: true,
    },
    submitLabel: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      default: "forgot-password",
    },
  },

  data() {
    return {
      fieldsValues: {} as Record<string, string>,
    };
  },

  watch: {
    fields: {
      handler: function (fields) {
        this.fieldsValues = {};
        fields.forEach((field) =>
          this.$set(this.fieldsValues, field.id, field.value)
        );
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("users", ["getIsLoadingById"]),

    loading(): boolean {
      return this.getIsLoadingById("auth");
    },

    actionRoute(): Location {
      return this.action === "forgot-password"
        ? { name: RouteNames.ForgotPassword }
        : { name: RouteNames.Login };
    },

    actionLabel(): string {
      return this.action === "forgot-password"
        ? i18n.actions.forgot_password
        : i18n.actions.login;
    },
  },

  methods: {
    handleInput(i, value) {
      this.$set(this.fieldsValues, this.fields[i].id, value);
    },

    handleSubmit() {
      if ((this.$refs.authForm as HTMLFormElement).reportValidity()) {
        this.$emit("submit", this.fieldsValues);
      }
    },
  },
});
