






































































































































import Vue from "vue";
import SidebarList from "./components/SidebarList.vue";
import Mark from "mark.js";
import Btn from "./components/ui/Btn.vue";
import ProjectSearch from "./components/ProjectSearch.vue";
import { RouteNames } from "./router/router";
import { Viewport } from "./lib/utils";
import { Location } from "vue-router";
import ArrowIcon from "@/assets/icons/arrowhead-right.svg";

export default Vue.extend({
  components: {
    SidebarList,
    Btn,
    ProjectSearch,
    ArrowIcon,
  },

  data() {
    return {
      search: "",
      markInstance: null as Mark | null,
      collapsed: false,
      viewport: Viewport.getInstance(),
    };
  },

  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isMobile: {
      immediate: true,
      handler(isMobile) {
        if (isMobile) {
          this.toggleSidebar(false);
        } else {
          if (!this.value) {
            this.toggleSidebar(true);
          }
        }
      },
    },
  },

  computed: {
    showToggleSidebarButton(): boolean {
      return !this.isMobile && !this.value;
    },

    newProjectRoute(): Location {
      return {
        name: RouteNames.ProjectNew,
        query: { categoryId: this.$store.state.categories.currentCategoryId },
      };
    },

    newCategoryRoute(): Location {
      return {
        name: RouteNames.CategoryNew,
        query: { categoryId: this.$store.state.categories.currentCategoryId },
      };
    },

    archivedRoute(): Location {
      return {
        name: RouteNames.ArchivedProjects,
      };
    },

    homeRoute(): Location {
      const firstCategory = this.$store.state.projects.projects.find(
        (project) => project.type === "category"
      );
      if (!firstCategory) {
        return {
          name: RouteNames.ProjectsHome,
        };
      }
      return {
        name: RouteNames.CategoriesHome,
        params: {
          id: firstCategory.id,
        },
      };
    },

    isMobile(): boolean {
      return this.viewport.isMobile;
    },
  },

  methods: {
    toggleSidebar(open) {
      this.$emit("input", open);
    },

    onSearch() {
      this.$store.dispatch("projects/setSearch", this.search);
      this.markInstance?.unmark();
      // gives enough time for the list to rerender
      // and so the markInstance won't have trailing DOM elements
      // $nextTick is not enough here
      setTimeout(() => {
        this.markInstance?.mark(this.search, {
          exclude: [".material-icons"],
        });
      }, 100);
    },
  },

  mounted() {
    // Getting instance for highlight search behaviour.
    this.markInstance = new Mark(this.$refs.menuWrapper);
  },
});
