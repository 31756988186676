import { SuiteScore, TestSnapshot, TestSuiteResult, Suite } from "@/interfaces";
import Vue, { CreateElement, VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { testScoreInPercent, testScoreTendency } from "../../lib/utils";
import ProjectScoresSection from "./ProjectScoresSection.vue";

export default Vue.extend({
  components: {
    ProjectScoresSection,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("tests", [
      "getTestSnapshotsForProject",
      "getEnabledTestsGroupedBySuite",
    ]),
    ...mapGetters("projects", ["findProjectDetails"]),

    testData(): TestSnapshot[] {
      return (this.getTestSnapshotsForProject(this.id) as TestSnapshot[]) || [];
    },

    projectDetails(): object | undefined {
      return this.findProjectDetails(this.id);
    },

    enabledTestTypes(): string[] {
      if (!this.projectDetails) return [];
      /* @ts-ignore (possible to reenable after adding return type to projectDetails getter) */
      return Object.keys(JSON.parse(this.projectDetails.enabled_test_types));
    },

    enabledTestTypesGroupedBySuite(): Suite[] {
      return this.getEnabledTestsGroupedBySuite(this.id, this.enabledTestTypes);
    },

    categoryScores(): SuiteScore[] {
      const scores: SuiteScore[] = [];
      /* @ts-ignore (Add return type to getEnabledTestsGroupedBySuite getter) */
      this.enabledTestTypesGroupedBySuite.forEach((suite: Suite) => {
        const results0 = this.getTestSuiteResults(this.testData[0], suite);
        const results1 = this.getTestSuiteResults(this.testData[1], suite);
        scores.push({
          name: suite.name,
          progress: results0 ? testScoreInPercent(results0) : 0,
          color: this.colorClassNameForSuite(suite.name),
          tendency:
            results0 && results1 ? testScoreTendency(results0, results1) : 0,
        });
      });
      return scores;
    },
  },
  methods: {
    ...mapActions("tests", ["loadSnapshotsForProjects"]),

    colorClassNameForSuite(suiteName: string): string {
      switch (suiteName) {
        case "SEO":
          return "Accent";

        case "Performance":
          return "Warning";

        case "Compliance":
          return "Perfect";

        default:
          return "";
      }
    },

    getTestSuiteResults(
      testData: TestSnapshot,
      suite: Suite
    ): TestSuiteResult | undefined {
      if (testData && testData.field_testsuite_results) {
        return testData.field_testsuite_results.find(
          (s) => s.value === suite.machinename
        );
      }

      return undefined;
    },
  },
  created() {
    if (this.testData.length === 0) {
      this.loadSnapshotsForProjects([this.id]);
    }
  },
  render(createElement: CreateElement): VNode {
    return createElement(ProjectScoresSection, {
      props: {
        projectScore: {
          /* @ts-ignore (add return type to vuex) */
          progress: testScoreInPercent(this.testData[0]),
          /* @ts-ignore (add return type to vuex) */
          tendency: testScoreTendency(this.testData[0], this.testData[1]),
        },
        categoryScores: this.categoryScores,
      },
    });
  },
});
