



















































































import Vue, { PropType } from "vue";
import OutlinedTextField from "@/components/form/OutlinedTextField.vue";
import ProjectFormTitle from "../category-form/ProjectFormTitle.vue";

interface User {
  name: string;
  mail: string;
  field_user_firstname: string;
  field_user_surname: string;
  field_user_title: string;
  password: string;
  confirmPassword: string;
  existingPassword: string;
}

export default Vue.extend({
  components: {
    OutlinedTextField,
    ProjectFormTitle,
  },
  props: {
    value: {
      type: Object as PropType<User>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleInput(key: string, value: string) {
      this.$emit("input", {
        ...this.value,
        [key]: value,
      });
    },
  },
});
