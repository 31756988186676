<template>
  <LineChart
    :chart-data="chartData"
    :chart-options="mergedOptions"
    :height="height"
    :plugins="plugins"
  />
</template>

<script>
import { Line as LineChart } from "vue-chartjs/legacy";

export default {
  name: "VCLineChart",
  components: { LineChart },
  props: ["options", "chartData", "height"],

  data() {
    return {
      plugins: [
        {
          id: "area-chart",
          beforeDraw(chart) {
            if (
              chart.config.options.chartArea &&
              chart.config.options.chartArea.backgroundColor
            ) {
              const ctx = chart.ctx;
              const chartArea = chart.chartArea;
              ctx.save();
              ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
              ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.right - chartArea.left,
                chartArea.bottom - chartArea.top
              );
              ctx.restore();
            }
          },
        },
      ],
    };
  },

  computed: {
    mergedOptions() {
      return Object.assign(
        {},
        {
          maintainAspectRatio: true,
          scales: {
            y: {
              ticks: {
                display: false,
              },
              grid: {
                drawTicks: false,
                drawBorder: false,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
        this.options
      );
    },
  },
};
</script>
