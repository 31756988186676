









import Vue from "vue";
import { i18n } from "@/i18n";

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    callbacks: {
      type: Object,
      required: true,
    },
    buttonLabels: {
      type: Object,
    },
  },

  computed: {
    options() {
      return {
        labels: {
          buttonSkip: i18n.tutorial.actions.skip,
          buttonPrevious: i18n.tutorial.actions.previous,
          buttonNext: i18n.tutorial.actions.next,
          buttonStop: i18n.tutorial.actions.continue_tour,
          ...this.buttonLabels,
        },
      };
    },
  },
});
