
























import Vue from "vue";
export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "input",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
  },
  computed: {
    commonAttributes(): {
      disabled: boolean;
      required: boolean;
      id: string;
      name: string;
      placeholder: string;
      value: string | number;
      min: number | string | null;
      step: number | string | null;
    } {
      return {
        disabled: this.disabled,
        required: this.required,
        id: this.id,
        name: this.id,
        placeholder: this.placeholder,
        value: this.value,
        min: this.type === "number" ? this.min : null,
        step: this.type === "number" ? this.step : null,
      };
    },
    listeners() {
      return {
        input: (evt) => this.$emit("input", evt.target.value),
        keyup: (evt) => this.$emit("keyup", evt.target.value),
        keydown: (evt) => this.$emit("keydown", evt.target.value),
        blur: (evt) => this.$emit("blur", evt.target.value),
      };
    },
  },
});
