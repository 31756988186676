













































import Vue from "vue";
import Checkbox from "../form/Checkbox.vue";
import Btn from "../ui/Btn.vue";
import Modal from "../ui/Modal.vue";

export default Vue.extend({
  components: {
    Modal,
    Btn,
    Checkbox,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedTests: [] as string[],
    };
  },

  computed: {
    learnMoreUrl(): string {
      return "http://docs-develop.yel.clients.factorial.io/guide/reports/";
    },
    tests(): Array<{ label: string; value: string }> {
      return [
        {
          label: "Title tag",
          value: "title_tag",
        },
        {
          label: "Meta description",
          value: "meta_description",
        },
        {
          label: "Canonical",
          value: "canonical_test",
        },
        {
          label: "Open graph",
          value: "open_graph",
        },
        {
          label: "Image Alt Texts",
          value: "image_alt_texts",
        },{
          label: "Link Titles",
          value: "link_titles",
        }
      ];
    },
  },

  methods: {
    handleChange(testId: string, checked: boolean) {
      if (checked) {
        this.selectedTests.push(testId);
      } else {
        this.selectedTests = this.selectedTests.filter(
          (test) => test !== testId
        );
      }
    },
    handleSubmit() {
      this.$emit("submit", this.selectedTests);
    },
  },
});
