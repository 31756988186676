<template>
  <ol class="List" :class="classes">
    <li
      class="Item"
      :style="indexStyle"
      v-for="(result, i) in results"
      :key="i"
    >
      <template v-if="result.label && result.link && result.link.url">
        <component :is="headlineTag(result.items)">
          {{ result.label }}
        </component>
        <a
          class="Link"
          v-if="result.link"
          :href="result.link.url"
          target="_blank"
          rel="noopener noreferrer"
          >{{ result.link.label || result.link.url }}</a
        >
      </template>
      <template v-else>
        <template v-if="result.link && result.link.url">
          <component :is="headlineTag(result.items)">
            <a
              class="Link"
              v-if="result.link"
              :href="result.link.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <external-link-icon class="Link-icon u-hiddenPrint" />
              {{ result.link.label || result.link.url }}
            </a>
          </component>
        </template>
        <template v-else>
          <component :is="headlineTag(result.items)">
            {{ result.label }}
          </component>
        </template>
      </template>

      <div class="Report-desc" v-if="result.message">
        <ResultMessage :message="result.message"></ResultMessage>
      </div>
      <Result
        v-if="result.items && result.items.length > 0"
        :results="result.items"
        :index="index + 1"
      />
    </li>
  </ol>
</template>

<script>
import Vue from "vue";
import ExternalLinkIcon from "../../assets/icons/external-link.svg";
import ResultMessage from "./ResultMessage.vue";

Vue.component("anchored-heading", {
  render: (createElement) => {
    return createElement("h" + this.level, this.$slots.default);
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
});

export default {
  name: "Result",

  components: {
    ResultMessage,
    ExternalLinkIcon,
  },

  props: {
    results: Array,
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    indexStyle() {
      return `--index: ${this.index};`;
    },

    classes() {
      return `level-${this.index}`;
    },

    spacing() {
      return `margin-top: ${this.index}px`;
    },
  },

  methods: {
    headlineTag(children) {
      if (!children || children.length === 0) {
        return "span";
      }

      const level = this.index + 2;
      return level > 6 ? `span` : `h${level}`;
    },
  },
};
</script>

<style scoped>
.List {
  font: var(--typo-copy-14);
}

.List.level-0,
.List.level-1 {
  list-style: none;
}

.List.level-0 {
  padding-left: 0;
}

.Item::marker {
  color: var(--color-decoration-Grey80);
}

.List.level-3 > .Item::marker {
  color: var(--color-decoration-White);
}

.List:not(.level-0) > .Item {
  margin-top: calc(30px - var(--index) * 6px);
}

.List:not(.level-0):not(.level-1) > .Item {
  color: var(--color-decoration-Grey80);
}

.List.level-4 {
  padding-left: 0;
}

.Link {
  word-break: break-word;
  color: var(--color-decoration-White);
  display: inline-flex;
  align-items: center;
  position: relative;
}

.List h5,
.List h6 {
  font-size: 1em;
}

.Link-icon {
  width: 1.5em;
  height: 1.5em;
  top: -0.25em;
  left: -4em;
  position: absolute;
}

.Item {
  font: var(--typo-copy-14);
  color: var(--color-decoration-Grey80);
}

.Item:only-child {
  list-style: none;
}

@media (max-width: 120em) {
  .Link {
    font: var(--typo-headline-12);
  }

  .List:not(:first-child) {
    margin-top: var(--spacing-16);
  }

  .List:not(.level-0) > .Item:last-child {
    margin-bottom: var(--spacing-24);
  }
}

@media (min-width: 120.0625em) {
  .Link {
    font: var(--typo-headline-18);
  }

  .List:not(:first-child) {
    margin-top: var(--spacing-16);
  }

  .List:not(.level-0) > .Item:last-child {
    margin-bottom: var(--spacing-24);
  }
}
</style>
