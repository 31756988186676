import Vue from "vue";
import { ProjectDetails, ProjectMeta, Test } from "@/interfaces";
import { THEME } from "@/theme";
import { currentLocale } from "@/i18n";
import { ToastOptions } from "vue-toasted";

export * from "./api-utils";

/**
 * Calculates the score in percent of a test or snapshot object.
 *
 * @param {Test} test - The test or snapshot object
 * @return {number} Score in percent or 0 when max_score is 0.
 */
export const testScoreInPercent = (test: Test) => {
  if (!test || !test.max_score) {
    return 0;
  }
  return Math.round((test.score / test.max_score) * 100);
};

/**
 * Calculates the score tendency of two tests or snapshots by calculating
 * the difference between the two scores in percent.
 *
 * @param {Test} test
 * @param {Test} previousTest
 * @return {number} Percent difference between 'test' and 'previousTest'.
 */
export const testScoreTendency = (test: Test, previousTest: Test): number => {
  if (
    test &&
    test.score &&
    typeof test.score === "number" &&
    previousTest &&
    previousTest.score &&
    typeof previousTest.score === "number"
  ) {
    const currentScore = testScoreInPercent(test);
    const previousCurrentScore = testScoreInPercent(previousTest);
    return currentScore - previousCurrentScore;
  } else {
    return 0;
  }
};

/**
 * Calculates the color coding for a score value.
 *
 * @param {int} score - The score that determines the color.
 * @returns {string}
 */
export const colorForScore = (score: number) => {
  if (score < 60) return THEME.critical;
  if (score < 80) return THEME.warning;
  return THEME.perfect;
};

/**
 * Calculates the color name for a score value.
 *
 * @param {int} score - The score that determines the color.
 * @returns {string}
 */
export const colorNameForScore = (score: number) => {
  if (score < 60) return "critical";
  if (score < 80) return "warning";
  return "perfect";
};

/**
 * The project progress bar might have different settings and even gradients
 *
 * @param {int} progress - The score that determines the color.
 * @returns {string}
 */
export const colorForProjectScore = (progress: number) => {
  if (progress < 60) {
    return THEME.critical;
  } else if (progress < 80) {
    return THEME.warning;
  } else if (progress < 100) {
    return THEME.perfect;
  }
  return `linear-gradient(to right, ${THEME.critical}, ${THEME.warning}, ${THEME.perfect}, ${THEME.accent}, ${THEME.primary})`;
};

/**
 * Returns a computed url for the project
 *
 * @param {ProjectDetails} project - The actual project object
 * @returns {string}
 */
export const urlForProject = (project: ProjectDetails): string => {
  if (!project) {
    return "";
  }

  const auth =
    project.field_auth_username && project.field_auth_password
      ? `${project.field_auth_username}:${project.field_auth_password}@`
      : "";
  let url = project.field_project_tested_url || "";

  if (auth.length > 0) {
    if (url.startsWith("https://")) {
      url = url.replace("https://", `https://${auth}`);
    } else if (url.startsWith("http://")) {
      url = url.replace("http://", `http://${auth}`);
    }
  }

  return url;
};

/**
 * Dates can come in the following formats:
 * 2021-08-11T08:35:21+00:00
 * 2021-08-11T08:35:21.000000Z
 * 1646737671806
 * 1646737671
 *
 * @param {string|number} timestamp
 * @returns {string|number}
 */
export const getDateFromTimestamp = (timestamp: string | number) => {
  if (
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/.test(
      timestamp.toString()
    ) ||
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/.test(
      timestamp.toString()
    )
  ) {
    return new Date(timestamp);
  }

  if (String(timestamp).length === 10) {
    return new Date(Number(timestamp) * 1000);
  } else {
    return new Date(timestamp);
  }
};

/**
 * Formats an ISO timestamp into a readable string.
 *
 * @param {string | number} timestamp - ISO Timestamp
 * @param {boolean} onlyDate - If true, only the date is returned
 * @return {string} A date string formatted in en-GB format as this is the language used for WSD
 */
export const formatTimestamp = (
  timestamp: string | number,
  onlyDate = false
): string => {
  const date = getDateFromTimestamp(timestamp);
  const format: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  };

  if (!onlyDate) {
    format.hour = "2-digit";
    format.minute = "2-digit";
  }
  return date.toLocaleString("en-GB", format);
};

/**
 * Formats a UNIX timestamp into a relative date
 * @param {number} timestamp - UNIX/ISO Timestamp
 * @return {string}
 */
export const formatRelativeTimestamp = (timestamp: number) => {
  const formatter = new Intl.RelativeTimeFormat(currentLocale, {
    numeric: "auto",
  });

  const DIVISIONS = [
    { amount: 60, name: "seconds" },
    { amount: 60, name: "minutes" },
    { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" },
  ];

  const date = getDateFromTimestamp(Number(timestamp));
  const today = Date.now();
  let duration = (date.getTime() - today) / 1000;
  for (const division of DIVISIONS) {
    if (Math.abs(duration) < division.amount) {
      // eslint-disable-next-line
      // @ts-ignore
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
};

/**
 * Filters out object with key latest_status = 'pending' and returns the number of elements
 *
 * @param {object} meta - The project meta object
 * @return {number} The number of pending tests
 */
export const numberOfPendingTests = (meta: ProjectMeta): number => {
  return Object.values(meta).filter(
    ({ latest_status }) => latest_status === "pending"
  ).length;
};

/**
 * Sort array by created date
 *
 * @param {array} array - The array to sort
 * @return {array}
 */
export function sortByCreatedDate<T extends { created: string }>(
  array: T[]
): T[] {
  return [...array].sort((a, b) => {
    const createdA = new Date(a.created);
    const createdB = new Date(b.created);

    if (createdA < createdB) {
      return -1;
    } else if (createdA > createdB) {
      return 1;
    }
    return 0;
  });
}

/**
 * Error safe retrieval for deep nested object property.
 *
 * Returns undefined if any of the key is not defined.
 * Eg. Usage: getDeepNestedObj(error, `response.data.message`);
 *
 * @param {*} obj - The Object, whose property is to be retrieved.
 * @param {*} dotString - the dot notaion property path.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDeepNestedObj = (obj, dotString: string): any | undefined => {
  const keys = dotString.split(".");
  while (obj && keys.length) obj = obj[keys.shift() || ""];
  return obj;
};

/**
 * Retrieves the value for a given property
 *
 * @param {object} theObject - The object to retrieve the value from
 * @param {string} key - The property to retrieve
 * @param {string} val - a value to compare against
 * @param {boolean} matchPartly - if true, partially matching values is allowed
 * @return {object | null}
 */
export const findObjectByProp = (
  theObject: object,
  key: string,
  val: string,
  matchPartly: boolean
): object | null => {
  let result: object | null = null;

  if (theObject instanceof Array) {
    for (let i = 0; i < theObject.length; i += 1) {
      result = findObjectByProp(theObject[i], key, val, matchPartly);
      if (result) {
        break;
      }
    }
  } else {
    const keys = Object.keys(theObject);

    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === key) {
        if (matchPartly) {
          if (
            theObject[keys[i]].toUpperCase().indexOf(val.toUpperCase()) >= 0
          ) {
            return theObject;
          }
        } else if (theObject[keys[i]] === val) {
          return theObject;
        }
      }
      if (
        theObject[keys[i]] instanceof Object ||
        theObject[keys[i]] instanceof Array
      ) {
        result = findObjectByProp(theObject[keys[i]], key, val, matchPartly);
        if (result) {
          break;
        }
      }
    }
  }

  return result;
};

/**
 * Show a toast message.
 *
 * @param {String} message - operation message.
 * @param {String} type - message type 'success', 'error' or 'info'.
 */
export const showToastedMessage = (
  message: string,
  type: "success" | "error" | "info" | "warning"
) => {
  const duration = 5000;
  let iconType = "";
  switch (type) {
    case "info":
      iconType = "alert-circle";
      break;

    case "error":
      iconType = "alert-circle";
      break;

    default:
      iconType = "checkmark-circle";
      break;
  }

  const htmlMessage = `
    <div class="CustomToast-content">
      <img class="CustomToast-icon" src="/icons/${iconType}.svg" />
      <p class="CustomToast-copy">${message}</p>
    </div>
  `;

  Vue.toasted.show(htmlMessage, {
    theme: "null",
    className: ["CustomToast", `CustomToast--${type}`],
    position: "bottom-right",
    duration,
    action: {
      icon: "close",
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  } as ToastOptions);
};

/**
 * Fills an array with a given value up to a given length.
 * If the given array is too short, the given value will be added to the
 * beginning of the array.
 * NOTE: mutates the array in place!
 *
 * @param {array} array - Array to be filled.
 * @param {any} fillWith - Value to fill into empty slots.
 * @param {number} newLength - Length up tp which the array should be expanded.
 * @return {array}
 */
export function fillArrayWith<T>(
  array: T[],
  fillWith: T,
  newLength: number
): T[] {
  while (array.length < newLength) {
    array.unshift(fillWith);
  }

  return array;
}

/**
 * Debounces the callback
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (callback: Function, wait: number) => {
  let timeout: number | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

/**
 * Singleton class for handling a global state for viewport and window size.
 */
export class Viewport {
  private static _instance: Viewport | null = null;

  private _width = 0;

  private constructor() {
    this._width = window.innerWidth;

    window.addEventListener("resize", this.onResize);
  }

  public static getInstance(): Viewport {
    if (!Viewport._instance) {
      Viewport._instance = new Viewport();
    }

    return Viewport._instance;
  }

  public get isMobile(): boolean {
    return this._width < 768;
  }

  public onResize = debounce(() => {
    this._width = window.innerWidth;
  }, 500);
}

/**
 * Compares string with a search string
 *
 * @param {string} stringToCompare
 * @param {string} search
 * @returns {boolean}
 */
export const stringIncludesSearch = (
  stringToCompare: string,
  search: string
): boolean => {
  return stringToCompare.toLowerCase().includes(search.toLowerCase());
};
