












import Vue from "vue";
import QuestionMarkIcon from "@/assets/icons/question-mark.svg";
import { TooltipConfiguration } from "@/interfaces";

export default Vue.extend({
  components: {
    QuestionMarkIcon,
  },

  props: {
    tag: {
      type: String,
      default: "h2",
    },
    description: {
      type: String,
      default: "",
    },
  },

  computed: {
    tooltip(): TooltipConfiguration {
      return {
        content: this.description,
      };
    },
  },
});
