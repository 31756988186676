




































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Location } from "vue-router";
import JsonApiParse from "jsonapi-parse";
import router from "../router/router";
import { i18n } from "@/i18n";
import { BASE_FE_URL } from "../constants";
import { createTestRerun } from "../lib/api";
import { urlForProject } from "@/lib/utils";
import Btn from "../components/ui/Btn.vue";
import Flip from "../assets/icons/flip.svg";
import LighthouseResults from "@/components/results/LighthouseResults.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import NotificationBar from "../components/NotificationBar.vue";
import ProjectHeader from "../components/ProjectHeader.vue";
import ResultsAccordion from "../components/results/results-accordion/Accordion.vue";
import ExportMetatagsReport from "@/components/export-metatags-report/ExportMetatagsReport.container";
import ExportMetatagsReportCrawler from "@/components/export-metatags-report-crawler/ExportMetatagsReportCrawler.container";
import SimpleDialog from "../components/SimpleDialog.vue";
import TabGroup from "@/components/ui/TabGroup.vue";
import TestSummary from "../components/TestSummary.vue";
import UiCard from "../components/ui/Card.vue";
import Spinner from "../assets/icons/spinner.svg";
import Modal from "../components/ui/Modal.vue";
import Tutorial from "@/components/Tutorial.vue";
import TestDetailTutorialMixin from "@/mixins/tutorial/test";
import {
  FieldImage,
  JiraTicket,
  ParsedReport,
  ProjectDetails,
  RawTest,
  RawTestType,
  TestMeta as ITestMeta,
  TestStatus,
  TooltipConfiguration,
} from "@/interfaces";
import { RouteNames } from "@/router/router";

const METATAGS_TEST_TYPE_IDS = [
  "title_tag",
  "meta_description",
  "open_graph",
  "robots_txt_validator",
  "twitter_cards",
  "canonical_test",
];

export default Vue.extend({
  mixins: [TestDetailTutorialMixin],

  components: {
    LoadingIndicator,
    SimpleDialog,
    NotificationBar,
    ProjectHeader,
    TestSummary,
    UiCard,
    Btn,
    Flip,
    ResultsAccordion,
    TabGroup,
    LighthouseResults,
    Spinner,
    Modal,
    ExportMetatagsReport,
    ExportMetatagsReportCrawler,
    Tutorial,
  },

  props: {
    testId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      rerunDialog: false,
      jiraDialog: false,
      isRunning: false,
      testDataInterval: undefined as number | undefined,
      resultsLoading: true,
      selectedGroup: "Errors",
      groups: [
        { value: "Errors", accent: "critical" },
        { value: "Warnings", accent: "warning" },
        { value: "Info", accent: "perfect" },
      ],
    };
  },

  watch: {
    test(currentTestValue) {
      if (
        currentTestValue &&
        currentTestValue.status !== "pending" &&
        this.testDataInterval
      ) {
        clearInterval(this.testDataInterval);
      }
    },
  },

  computed: {
    ...mapGetters("tests", [
      "getTestById",
      "getTestTypeByType",
      "getTestsByProjectAndType",
    ]),
    ...mapGetters("projects", ["findProjectDetails"]),

    tests(): RawTest[] {
      return this.getTestsByProjectAndType(this.projectId, this.type);
    },

    test(): RawTest {
      return this.getTestById(this.testId);
    },

    testType(): RawTestType | undefined {
      return this.getTestTypeByType(this.type);
    },

    isPending(): boolean {
      return (
        this.test.status === TestStatus.Pending ||
        this.testMeta?.latest_status === TestStatus.Pending
      );
    },

    parsedReport(): ParsedReport {
      if (!this.test.log_grouped_by_status_code) return { items: [] };

      try {
        const parsed = JSON.parse(this.test.log_grouped_by_status_code);
        parsed.items = parsed.items.map((item) => {
          if (item.label === "Info") {
            item.items = item.items.map((subitem) => ({
              items: subitem.items,
              count: subitem.count,
            }));
          }
          return item;
        });
        return parsed;
      } catch (e) {
        return { items: [] };
      }
    },

    reportItems(): object {
      return (
        this.parsedReport?.items?.find(
          (item) => item.label === this.selectedGroup
        ) || {}
      );
    },

    jiraCopy(): string {
      return i18n.confirmation.jira_ticket.copy.one.replace(
        "${name}",
        this.test.name
      );
    },

    project(): ProjectDetails | undefined {
      return this.findProjectDetails(this.projectId);
    },

    logo(): FieldImage | undefined {
      return this.project?.field_image;
    },

    url(): string {
      if (!this.project) return "";
      return urlForProject(this.project);
    },

    showEditButton(): boolean {
      return this.showButton("update");
    },

    eventCounts(): { error: number; warning: number; success: number } {
      let error = 0;
      let warning = 0;
      let success = 0;
      if (this.parsedReport?.items?.length) {
        const { items } = this.parsedReport;
        items.forEach((item) => {
          if (item.label === "Errors") {
            error = item.count || 0;
          }
          if (item.label === "Warnings") {
            warning = item.count || 0;
          }
          if (item.label === "Info") {
            success = item.count || 0;
          }
        });
      }
      return { error, warning, success };
    },

    jiraTickets(): JiraTicket[] {
      return this.$store.state.jira.issues[this.type]?.slice(0, 3) || [];
    },

    generateReportRoute(): Location {
      return {
        name: RouteNames.PrintTestDetails,
        params: {
          projectId: this.test.project.id,
        },
        query: {
          testType: this.testType?.id,
        },
      };
    },

    testMeta(): ITestMeta | undefined {
      if (!this.testType) return undefined;
      return this.project?.meta_parsed[this.testType.id];
    },

    tooltipCopy(): TooltipConfiguration {
      return {
        content: !this.project?.field_jira_project_key
          ? i18n.test.has_no_jira_project
          : "",
      };
    },

    isJiraButtonDisabled(): boolean {
      if (!this.project?.field_jira_project_key) {
        return true;
      }
      return (
        this.isRunning ||
        this.isPending ||
        this.test.result === "SUCCESS" ||
        this.test.result === "PASS"
      );
    },

    lighthouseResults(): Record<string, string> | null | undefined {
      return this.tests?.find((test) => Boolean(test.html_report_url))
        ?.html_report_url;
    },

    showMetatagsReportDownloadButton(): boolean {
      return METATAGS_TEST_TYPE_IDS.includes(
        this.test.type.replace("test--", "")
      );
    },

    backRoute(): Location {
      return {
        name: RouteNames.ProjectOverview,
        params: {
          id: this.projectId,
        },
      };
    },

    hasTruncatedResults(): boolean {
      const total = this.test.website_tested_count;
      const displayed = Object.values(this.eventCounts).reduce(
        (accumulator, value) => accumulator + value
      );

      return displayed < total;
    },

    isPublished(): boolean {
      return this.project?.workflow === "1";
    },
  },

  methods: {
    ...mapMutations("tests", ["clearTests"]),
    ...mapActions("projects", ["getProjectDetails"]),
    ...mapActions("jira", ["createJiraTicket", "getIssuesForTestType"]),
    ...mapActions("tests", [
      "getTestsByTypeAndProject",
      "getTestData",
      "getTestTypes",
      "rerunTest",
      "getTestTypeDocumentation",
    ]),

    rerunDialogConfirm(): void {
      this.rerunDialog = false;
      this.isRunning = true;

      createTestRerun({
        type: this.test.type.replace("test--", ""),
        name: this.test.name,
        documentation_url: this.test.documentation_url,
        field_responsibility: this.test.field_responsibility,
        field_testsuites: this.test.field_testsuites,
        project: this.test.project,
      } as RawTest).then((response) => {
        const newTest = JsonApiParse.parse(response);
        const urlParameters = {
          name: RouteNames.TestDetails,
          params: {
            projectId: newTest.data.project.id,
            testsuite: newTest.data.field_testsuites[0],
            type: newTest.data.type.replace("test--", ""),
            testId: newTest.data.id,
          },
        };
        // Routing to new test
        router.push(urlParameters);
      });
    },

    jiraDialogConfirm(): void {
      this.jiraDialog = false;
      const url = `${BASE_FE_URL}/project/${this.projectId}/test/${this.type}/${this.testId}`;
      this.createJiraTicket({ testId: this.testId, link: url });
    },

    showButton(type): boolean {
      if (!this.project || !this.project.access) return false;

      return this.project.access.includes(type);
    },
  },

  created() {
    this.getProjectDetails(this.projectId);

    this.getTestData({
      id: this.testId,
      testType: this.type,
    }).then(() => {
      this.resultsLoading = false;
    });

    this.getTestTypes({ id: this.projectId });

    this.getTestsByTypeAndProject({
      projectId: this.projectId,
      testType: this.type,
      testId: this.testId,
    });

    if (this.test && this.test.status === "pending") {
      this.testDataInterval = setInterval(() => {
        this.getTestsByTypeAndProject({
          projectId: this.projectId,
          testType: this.type,
          testId: this.testId,
        });
      }, 10000);
    }

    this.getIssuesForTestType({
      projectId: this.projectId,
      testType: this.type,
    });
  },

  destroyed() {
    if (this.testDataInterval) {
      clearInterval(this.testDataInterval);
    }
  },
});
