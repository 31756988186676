




















import { JiraTicket } from "@/interfaces";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    ticket: {
      type: Object as PropType<JiraTicket>,
      required: true,
    },
    showKey: {
      type: Boolean,
      default: true,
    },
  },
});
