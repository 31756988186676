


































import Vue from "vue";
import FlipIcon from "@/assets/icons/flip.svg";
import SpinnerIcon from "@/assets/icons/spinner.svg";
import ShareIcon from "@/assets/icons/share.svg";
import PlusSquareIcon from "@/assets/icons/plus-square.svg";
import PlusIcon from "@/assets/icons/plus.svg";
import FolderIcon from "@/assets/icons/folder.svg";
import ArrowIosLeftIcon from "@/assets/icons/arrow-ios-left.svg";
import FileAddIcon from "@/assets/icons/file-add.svg";
import FileTextIcon from "@/assets/icons/file-text.svg";
import TableIcon from "@/assets/icons/table.svg";

export default Vue.extend({
  components: {
    FlipIcon,
    SpinnerIcon,
    ShareIcon,
    PlusSquareIcon,
    PlusIcon,
    FolderIcon,
    ArrowIosLeftIcon,
    FileAddIcon,
    FileTextIcon,
    TableIcon,
  },
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    color: {
      type: String,
      default: "transparent",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    openNewTab: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    tag(): "a" | "router-link" | "button" {
      if (this.to && this.external) {
        return "a";
      }

      if (this.to) {
        return "router-link";
      }

      return "button";
    },
    iconTransitionName(): string | null {
      if (!this.iconOnly && this.icon === "spinner-icon") {
        return "Btn-icon";
      }

      return null;
    },
  },
});
