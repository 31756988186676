import Vue from "vue";
import Router from "vue-router";

import store from "../store/store";

import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import ProjectForm from "@/views/ProjectForm.vue";
import CategoryForm from "@/views/CategoryForm.vue";
import ProjectDashboard from "@/views/ProjectDashboard.vue";
import TestDetails from "@/views/TestDetails.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import PrintTestDetails from "@/views/print/TestDetails.vue";
import UserForm from "@/views/UserForm.vue";
import ErrorView from "@/views/Error.vue";

Vue.use(Router);

export const RouteNames = {
  Login: "login",
  ProjectsHome: "projects",
  CategoriesHome: "category",
  CategoryNew: "categoryNew",
  CategoryEdit: "categoryEdit",
  ProjectNew: "projectNew",
  ProjectEdit: "projectEdit",
  ProjectOverview: "projectDashboard",
  TestDetails: "test",
  PrintTestDetails: "print__report",
  ArchivedProjects: "archivedProjects",
  ForgotPassword: "forgotPassword",
  ResetPassword: "resetPassword",
  UserProfile: "userProfile",
  TrialExpired: "trialExpired",
  SubscriptionSuccess: "subscriptionSuccess",
  Error: "error",
};

const router = new Router({
  routes: [
    {
      path: "/login",
      name: RouteNames.Login,
      component: Login,
    },

    {
      path: "/",
      name: RouteNames.ProjectsHome,
      component: Home,
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: "Home" }],
      },
    },

    {
      path: "/category/new",
      name: RouteNames.CategoryNew,
      component: CategoryForm,
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Home" },
          { name: "category" },
          { name: "newCategory" },
        ],
      },
    },

    {
      path: "/category/:id",
      name: RouteNames.CategoriesHome,
      component: Home,
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: "Home" }, { name: "category" }],
      },
    },

    {
      path: "/category/edit/:id",
      name: RouteNames.CategoryEdit,
      component: CategoryForm,
      props: (route) => ({ entityId: route.params.id }),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Home" },
          { name: "category" },
          { name: "editProject" },
        ],
      },
    },

    {
      path: "/project/new",
      name: RouteNames.ProjectNew,
      component: ProjectForm,
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: "Home", link: "/" }, { name: "newProject" }],
      },
    },

    {
      path: "/project/edit/:id",
      name: RouteNames.ProjectEdit,
      component: ProjectForm,
      props: (route) => ({ entityId: route.params.id }),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Home" },
          { name: "category" },
          { name: "projectName" },
          { name: "editProject" },
        ],
      },
    },

    {
      path: "/project/:id",
      name: RouteNames.ProjectOverview,
      component: ProjectDashboard,
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Home" },
          { name: "category" },
          { name: "projectName" },
        ],
      },
    },

    {
      path: "/project/:projectId/test/:type/:testId",
      name: RouteNames.TestDetails,
      component: TestDetails,
      props: true,
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Home" },
          { name: "category" },
          { name: "projectName" },
          { name: "testName" },
        ],
      },
    },

    {
      path: "/project/:projectId/report",
      name: RouteNames.PrintTestDetails,
      component: PrintTestDetails,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/archive",
      name: RouteNames.ArchivedProjects,
      component: Home,
      props: () => ({ archived: true }),
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: "archivedProjects" }],
      },
    },

    {
      path: "/user-profile",
      name: RouteNames.UserProfile,
      component: UserForm,
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: "Home" }],
      },
    },

    {
      path: "/forgot-password",
      name: RouteNames.ForgotPassword,
      component: Login,
    },

    {
      path: "/reset-password",
      name: RouteNames.ResetPassword,
      component: Login,
    },

    {
      path: "/trial-expired",
      name: RouteNames.TrialExpired,
      component: Login,
    },

    {
      path: "/subscription-success",
      name: RouteNames.SubscriptionSuccess,
      component: Login,
    },

    {
      path: "/error",
      name: RouteNames.Error,
      component: ErrorView,
    },

    { path: "*", component: PageNotFound },
  ],
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!requiresAuth) {
    if (
      [
        "/login",
        "/reset-password",
        "/forgot-password",
        "/trial-expired",
        "/subscription-success",
      ].includes(to.path) &&
      store.state.users.userIsAuthenticated
    ) {
      return next("/");
    }

    return next();
  }

  if (store.state.users.userIsAuthenticated) {
    next();
  } else {
    next(to.path !== "/" ? `/login?redirect=${to.path}` : "/login");
  }
});

export default router;
