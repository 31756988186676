





















































import Vue from "vue";
import { mapState } from "vuex";
import Login from "@/components/auth/Login.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import ResetPassword from "@/components/auth/ResetPassword.vue";
import TrialExpired from "@/components/auth/TrialExpired.vue";
import SubscriptionSuccess from "@/components/auth/SubscriptionSuccess.vue";

export default Vue.extend({
  components: {
    Login,
    ForgotPassword,
    ResetPassword,
    TrialExpired,
    SubscriptionSuccess,
  },

  computed: {
    ...mapState("users", ["loginErrorMsg"]),

    isLogin(): boolean {
      return this.$route.name === "login";
    },
    isForgotPassword(): boolean {
      return this.$route.name === "forgotPassword";
    },
    isResetPassword(): boolean {
      return this.$route.name === "resetPassword";
    },
    isTrialExpired(): boolean {
      return this.$route.name === "trialExpired";
    },
    isSubscriptionSuccess(): boolean {
      return this.$route.name === "subscriptionSuccess";
    },
  },
});
