



















import Vue from "vue";
import SearchIcon from "../assets/icons/search.svg";

export default Vue.extend({
  components: {
    SearchIcon,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
});
