































import { SuiteScore } from "@/interfaces";
import { EventBus, GlobalEvents } from "@/lib/event-bus";
import { colorForProjectScore, colorForScore } from "@/lib/utils";
import Vue, { PropType } from "vue";
import Trend from "../ui/Trend.vue";
import ScoreProgressBar from "./ScoreProgressBar.vue";

export default Vue.extend({
  components: {
    ScoreProgressBar,
    Trend,
  },
  props: {
    suites: {
      type: Array as PropType<SuiteScore[]>,
      default: () => [],
    },
  },
  methods: {
    colorRules(progress): string {
      const suiteName = this.suites.length ? this.suites[0].name : "";
      return suiteName
        ? colorForScore(progress)
        : colorForProjectScore(progress);
    },
    handleCategoryClick(suite): void {
      if (suite) {
        EventBus.$emit(GlobalEvents.FilterTestTable, {
          label: suite,
          value: suite.toLowerCase(),
        });
      }
    },
  },
});
