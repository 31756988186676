











import Vue, { PropType } from "vue";
import OrderIcon from "../ui/OrderIcon.vue";
export default Vue.extend({
  components: { OrderIcon },
  props: {
    state: {
      type: String as PropType<"asc" | "desc" | "none">,
      default: "none",
    },
  },
  computed: {
    ariaLabel(): string {
      switch (this.state) {
        case "desc":
          return "Descending order";
        case "asc":
          return "Ascending order";
        default:
          return "Unsorted";
      }
    },
  },
  methods: {
    handleClick() {
      let nextState = "asc";
      if (this.state === "asc") {
        nextState = "desc";
      } else if (this.state === "desc") {
        nextState = "none";
      }
      this.$emit("click", nextState);
    },
  },
});
