





















import Vue from "vue";
import CopyIcon from "@/assets/icons/copy.svg";
import Btn from "./ui/Btn.vue";
import { TooltipConfiguration } from "@/interfaces";
import { i18n } from "@/i18n";
import { BASE_URL } from "@/constants";
import QuestionMarkIcon from "@/assets/icons/question-mark.svg";

export default Vue.extend({
  components: {
    CopyIcon,
    Btn,
    QuestionMarkIcon,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tooltipMessage: i18n.actions.shield.click_to_copy,
      hasError: false,
    };
  },
  computed: {
    shieldUrl(): string {
      return `${BASE_URL}/project/badge/${this.projectId}.svg?style=flat-square&format=percent`;
    },
    tooltipConfig(): TooltipConfiguration {
      return {
        content: this.tooltipMessage,
        delay: {
          hide: 1500,
        },
      };
    },
    tooltipAboutShield(): TooltipConfiguration {
      return {
        content: i18n.actions.shield.about_shield,
      };
    },
  },
  methods: {
    resetMessage() {
      setTimeout(() => {
        this.tooltipMessage = i18n.actions.shield.click_to_copy;
      }, 2000);
    },
    copyShieldUrl() {
      navigator.clipboard
        .writeText(this.shieldUrl)
        .then(() => {
          this.tooltipMessage = i18n.actions.shield.copied_successfully;
        })
        .catch(() => {
          this.tooltipMessage = i18n.actions.shield.not_copied;
        })
        .finally(this.resetMessage);
    },
  },
  mounted() {
    const img = new Image();
    img.onerror = () => {
      this.hasError = true;
    };
    img.src = this.shieldUrl;
  },
});
