<template>
  <div class="Checkbox" @click.stop>
    <input
      class="Checkbox-input"
      type="checkbox"
      :disabled="disabled"
      :value="id"
      :id="id"
      :checked="value"
      :data-cy="dataCy"
      @change="$emit('value-changed', $event.target.checked)"
    />
    <label :for="id" class="Checkbox-label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    label: String,
    value: Boolean,
    disabled: Boolean,
    dataCy: String,
  },
};
</script>

<style scoped>
.Checkbox {
  font-size: 16px;
}

.Checkbox-input {
  opacity: 0;
  position: absolute;
}

.Checkbox-label {
  display: flex;
  align-items: center;
  padding: 0 0 0 2em;
  position: relative;
  font-weight: bold;
}

.Checkbox-input[disabled] + .Checkbox-label {
  pointer-events: none;
}

.Checkbox-label::before {
  box-sizing: border-box;
  content: "";
  display: block;
  width: 1.1em;
  height: 1.1em;
  border-radius: 2px;
  margin: 0 0.5em 0 0;
  border: 2px solid var(--color-greyMedium);
  background: #fff;
  transition: all 0.1s ease-in;
  position: absolute;
  top: calc(calc(1.5em - 1.25em) / 2);
  left: 0;
}

.Checkbox-input[disabled] + .Checkbox-label::before,
.Checkbox-input[disabled]:checked + .Checkbox-label::before {
  border-color: var(--color-greyMedium);
  background-color: var(--color-grey);
}

.Checkbox-input:not([disabled]):not(:checked):hover + .Checkbox-label::before {
  border-color: var(--color-grey);
}

.Checkbox-input:not([disabled]):checked:hover + .Checkbox-label::before {
  background-color: var(--color-primaryDark);
}

.Checkbox-input:focus + .Checkbox-label::before {
  border-color: var(--color-primary_);
  box-shadow: 0 0 5px var(--color-primary_);
}

.Checkbox-input:checked + .Checkbox-label::before {
  border-color: transparent;
  background: var(--color-primary_)
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='white' d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  transition: all 0.15s ease-out;
}
</style>
