














import Vue, { PropType } from "vue";
import { Notification } from "@/interfaces";

export default Vue.extend({
  props: {
    notifications: {
      type: Array as PropType<Notification[]>,
      default: () => [],
    },
    show: {
      type: String,
      default: () => "full",
    },
    tests: {
      type: Array as PropType<
        { id: string; name: string; type: string; project_id: string }[]
      >,
      default: () => [],
    },
  },
  methods: {
    getMessage(item: Notification): string {
      switch (this.show) {
        case "full":
          return item.full_message;
        case "small":
          return item.small_message;
        default:
          return item.small_message;
      }
    },
    getTestRoute(item: Notification): string {
      const test = this.tests.find(({ type }) => type === item.test_type);
      if (!test) return "";
      return `/project/${test.project_id}/test/${test.type}/${test.id}`;
    },
    getCompleteMessage(item: Notification): string {
      const testRoute = this.getTestRoute(item);
      const message = this.getMessage(item);
      if (testRoute) {
        return `<a href="${testRoute}">${item.test_name}</a>: ${message}`;
      }
      if (item.test_name) {
        return `${item.test_name}: ${message}`;
      }
      return message;
    },
  },
});
