


























































import Vue from "vue";
import UiCard from "@/components/ui/Card.vue";
import Btn from "./ui/Btn.vue";

export default Vue.extend({
  components: {
    UiCard,
    Btn,
  },
});
