import JsonApiParse from "jsonapi-parse";
import Vue from "vue";

import {
  saveJiraTicket,
  fetchAccounts,
  fetchJiraProjects,
  fetchJiraPriorities,
  fetchJiraIssuesForProject,
} from "../../../lib/api";

import { showToastedMessage, getDeepNestedObj } from "../../../lib/utils";

export default {
  namespaced: true,
  state: {
    accounts: [],
    jiraProjects: {},
    jiraPriorities: {},
    issues: {},
  },

  actions: {
    getIssuesForProject({ commit }, projectId) {
      return fetchJiraIssuesForProject(projectId)
        .then((result) => {
          commit("setJiraIssues", {
            key: projectId,
            issues: JsonApiParse.parse(result),
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getIssuesForTestType({ commit }, { projectId, testType }) {
      return fetchJiraIssuesForProject(projectId, testType)
        .then((result) => {
          commit("setJiraIssues", {
            key: testType,
            issues: JsonApiParse.parse(result),
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * Get the list of accounts in the system.
     *
     * @returns {Promise}
     */
    getAccounts({ commit }) {
      fetchAccounts()
        .then((result) => {
          commit("setAccounts", JsonApiParse.parse(result));
        })
        .catch((error) => {
          console.log(error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    /**
     * Get the list of JIRa projects in the system.
     *
     * @returns {Promise}
     */
    getJiraProjects({ commit }, accountId) {
      fetchJiraProjects(accountId)
        .then((result) => {
          commit("setJiraProjects", { accountId, jiraProjects: result });
        })
        .catch((error) => {
          console.log(error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    /**
     * Get the list of Jira priorities in the system.
     *
     * @returns {Promise}
     */
    getJiraPriorities({ commit }, accountId = "") {
      fetchJiraPriorities(accountId)
        .then((result) => {
          commit("setJiraPriorities", { accountId, jiraPriorities: result });
        })
        .catch((error) => {
          console.log(error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    /**
     * Create a jira ticket.
     */
    createJiraTicket(_, { testId, link }) {
      showToastedMessage("Creating a ticket now, please wait...", "info");
      return saveJiraTicket({ testId, link })
        .then((response) => {
          showToastedMessage(
            `The Jira ticket has been <a href="${response.ticketUrl}" target="_blank">created</a>.`,
            "success"
          );
        })
        .catch((error) => {
          const message = getDeepNestedObj(error, "response.data.message");
          if (message !== undefined) {
            showToastedMessage(message, "error");
          } else {
            console.log(error);
          }
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },
  },

  mutations: {
    setAccounts(state, accounts) {
      state.accounts = accounts.data;
    },

    setJiraProjects(state, { accountId, jiraProjects }) {
      Vue.set(state.jiraProjects, accountId, jiraProjects);
    },

    setJiraPriorities(state, { accountId, jiraPriorities }) {
      Vue.set(state.jiraPriorities, accountId, jiraPriorities);
    },

    setJiraIssues(state, { key, issues }) {
      Vue.set(state.issues, key, issues.data);
    },
  },
};
