


























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  props: {
    options: {
      type: [Object, String],
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("categories", [
      "getOverriddenFieldLock",
      "getInheritedValue",
    ]),
    inheritedValue(): string | number | boolean | object {
      return this.getInheritedValue(this.id);
    },
    valueIsOverwritten(): boolean {
      return this.getOverriddenFieldLock(this.id);
    },
    isUnlocked(): boolean {
      return !this.valueIsOverwritten;
    },
    isOptionEmpty(): boolean {
      if (this.options === undefined) {
        return true;
      }
      if (Object.keys(this.options).length === 0) {
        return true;
      }
      return false;
    },
    isSelf(): boolean {
      if (!this.isOptionEmpty) {
        return this.$route.params.id === this.options.uuid;
      }
      return true;
    },
    nameOfParentCategory(): string {
      if (typeof this.options !== "undefined" && this.options?.title) {
        if (this.isSelf) {
          if (this.options.options?.[0]?.title) {
            return this.options.options[0].title;
          }
        } else {
          return this.options.title;
        }
      }
      return "";
    },
  },
  methods: {
    ...mapActions("categories", ["setOverriddenFields"]),

    resetToInheritedValue() {
      this.setOverriddenFields({
        id: this.id,
        data: { lock: true },
      });
      this.$emit("inherit-value", this.inheritedValue);
    },
  },

  mounted() {
    // Sets the lock state of a field to be open
    // if it's not the project/category under update
    this.setOverriddenFields({
      id: this.id,
      data: { lock: !this.isSelf },
    });
  },
});
