












































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import FieldInfo from "@/components/FieldInfo.vue";
import unlockOverrideStatusMixin from "@/mixins/override-inherited-value";
import CategoryTree from "../CategoryTree.vue";
import { i18n } from "@/i18n";
import OutlinedTextField from "@/components/form/OutlinedTextField.vue";
import { RouteNames } from "@/router/router";
import ProjectFormTitle from "./ProjectFormTitle.vue";

export default Vue.extend({
  mixins: [unlockOverrideStatusMixin],

  components: {
    FieldInfo,
    CategoryTree,
    OutlinedTextField,
    ProjectFormTitle,
  },

  props: {
    projectTitleInput: {
      type: String,
      default: "",
    },
    projectDescriptionInput: {
      type: String,
      default: "",
    },
    projectBaseUrlInput: {
      type: String,
      default: "",
    },
    projectCategoryInput: {
      type: String,
      default: "",
    },
    projectBaseSitemapUrlInput: {
      type: String,
      default: "",
    },
    projectReRunFrequencyInput: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      projectTitle: "",
      projectDescription: "",
      projectUrl: "",
      projectSitemapUrl: "",
      reRunFrequency: "automatic",
      category: "",
      rules: {
        required: (value) => !!value || i18n.validation.required,
        url: (value) => {
          const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
          return pattern.test(value) || i18n.validation.invalid_url;
        },
      },
    };
  },

  watch: {
    projectTitleInput(newValue) {
      this.projectTitle = newValue;
    },

    projectDescriptionInput(newValue) {
      this.projectDescription = newValue;
    },

    projectBaseUrlInput(newValue) {
      this.projectUrl = newValue;
    },

    projectCategoryInput(newValue) {
      this.category = newValue;
    },

    projectBaseSitemapUrlInput(newValue) {
      this.projectSitemapUrl = newValue;
    },

    projectReRunFrequencyInput(newValue) {
      this.reRunFrequency = newValue;
    },

    reRunFrequency(newValue) {
      this.$emit("re-run-frequency", newValue);
    },
  },

  computed: {
    ...mapState("projects", ["projects"]),
    ...mapGetters("categories", ["getOverriddenFieldLock"]),

    isCategory(): boolean {
      return [RouteNames.CategoryNew, RouteNames.CategoryEdit].includes(
        this.$route.name || ""
      );
    },
  },

  methods: {
    setProjectSitemapUrlBasedOnProjectUrl(projectUrl) {
      const sitemapUrlInput = this.$refs.sitemapUrlInput as Vue | null;
      const isValid =
        sitemapUrlInput?.$el.querySelector("input") &&
        sitemapUrlInput?.$el.querySelector("input")?.validity.valid;

      if (this.projectSitemapUrl.length == 0 && isValid) {
        this.projectSitemapUrl = projectUrl.endsWith("/")
          ? `${projectUrl}sitemap.xml`
          : `${projectUrl}/sitemap.xml`;
        this.$emit("project-sitemap-url-event", this.projectSitemapUrl);
      }
    },
    isFieldInvalid(field) {
      return (
        this.$store.state.forms.formSubmitted &&
        !this.$store.getters["forms/isFieldValid"](field)
      );
    },
  },
});
