











































import Vue from "vue";
import ButtonTab from "./ButtonTab.vue";
export default Vue.extend({
  components: {
    ButtonTab,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      required: true,
    },
  },
});
