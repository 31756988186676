














import { User, BasicUser } from "@/interfaces";
import Vue, { PropType } from "vue";
import ProjectMember from "../ProjectMember.vue";
import UsersStacked from "../UsersStacked.vue";
export default Vue.extend({
  components: { UsersStacked, ProjectMember },
  props: {
    users: {
      type: Array as PropType<User[] | BasicUser[]>,
      default: () => [],
    },
    stakeholder: {
      type: Object as PropType<User | BasicUser>,
      required: true,
    },
  },
});
