

































































































































import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import { mapActions } from "vuex";
import {
  RawTest,
  TestMeta,
  TestStatus,
  TestTypeOverview,
} from "../../interfaces";
import { i18n } from "../../i18n";
import { createTestRerun } from "../../lib/api";
import { showToastedMessage, testScoreInPercent } from "../../lib/utils";
import { BASE_FE_URL } from "../../constants";
import TestTableRow from "./TestTableRow.vue";
import SimpleDialog from "../SimpleDialog.vue";
import Checkbox from "../form/Checkbox.vue";
import Btn from "../ui/Btn.vue";
import CustomTable, {
  CustomTableConfig,
  SortOrder,
} from "../ui/CustomTable.vue";
import { RouteNames } from "@/router/router";
import Modal from "../ui/Modal.vue";
import CustomSelect from "../form/Select.vue";
import { EventBus, GlobalEvents } from "@/lib/event-bus";
import ExportMetatagsReportCrawler from "@/components/export-metatags-report-crawler/ExportMetatagsReportCrawler.container";

import FullWidthAlerts from "@/components/full-width-alerts/FullWidthAlerts.container";

export default Vue.extend({
  components: {
    TestTableRow,
    SimpleDialog,
    Checkbox,
    Btn,
    CustomTable,
    Modal,
    CustomSelect,
    FullWidthAlerts,
    ExportMetatagsReportCrawler,
  },
  props: {
    tests: {
      type: Array as PropType<TestTypeOverview[]>,
      required: true,
    },
    testsMeta: {
      type: Object as PropType<Record<string, TestMeta>>,
      required: true,
    },
    hasJiraProject: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: true,
    },
    workflow: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      testsSelected: [] as TestTypeOverview[],
      jiraDialog: false,
      rerunDialog: false,
      group: 0,
      groups: [{ label: "none", value: 0 }],
      filter: null as { label: string; value: string } | null,
      filters: [
        {
          label: i18n.test_table.filters.seo,
          value: "seo",
        },
        {
          label: i18n.test_table.filters.compliance,
          value: "compliance",
        },
        {
          label: i18n.test_table.filters.performance,
          value: "performance",
        },
      ],
      tableOptions: {
        columns: [
          {
            label: i18n.test_table.columns.select,
            id: "select",
            orderable: false,
          },
          {
            label: i18n.test_table.columns.name,
            id: "name",
            orderable: true,
            order: SortOrder.None,
          },
          {
            label: i18n.test_table.columns.score,
            id: "score",
            orderable: true,
            order: SortOrder.None,
          },
          {
            label: i18n.test_table.columns.latest_run,
            id: "latest_run",
            orderable: true,
            order: SortOrder.None,
          },
          {
            label: i18n.test_table.columns.actions,
            id: "actions",
            orderable: false,
            order: SortOrder.None,
          },
        ],
      } as CustomTableConfig,
    };
  },
  computed: {
    selectedFailingTests(): TestTypeOverview[] {
      return this.testsSelected.filter(
        ({ status }) => status === TestStatus.Error
      );
    },
    jiraTitle(): string {
      return i18n.confirmation.jira_ticket.title[
        this.testsSelected.length > 1 ? "other" : "one"
      ];
    },
    jiraCopy(): string {
      if (!this.selectedFailingTests.length) return "";
      if (this.selectedFailingTests.length > 1) {
        return i18n.confirmation.jira_ticket.copy.other.replace(
          "${count}",
          String(this.selectedFailingTests.length)
        );
      }
      return i18n.confirmation.jira_ticket.copy.one.replace(
        "${name}",
        this.selectedFailingTests[0].name
      );
    },
    rerunTitle(): string {
      return i18n.confirmation.rerun.title[
        this.testsSelected.length > 1 ? "other" : "one"
      ];
    },
    rerunCopy(): string {
      if (!this.testsSelected.length) return "";
      if (this.testsSelected.length > 1) {
        return i18n.confirmation.rerun.copy.other.replace(
          "${count}",
          String(this.testsSelected.length)
        );
      }
      return i18n.confirmation.rerun.copy.one.replace(
        "${name}",
        this.testsSelected[0].name
      );
    },
    disabled(): boolean {
      return this.testsSelected.length === 0;
    },
    jiraDisabled(): boolean {
      return this.disabled || !this.hasJiraProject;
    },
    generateReportRoute(): Location {
      if (!this.tests.length || !this.testsSelected.length) return {};

      return {
        name: RouteNames.PrintTestDetails,
        params: {
          projectId: this.projectId,
        },
        query: {
          testType: this.testsSelected.map((test) => test.test_type_id),
        },
      };
    },
    filteredTests(): TestTypeOverview[] {
      if (!this.filter) return this.tests;
      return this.tests.filter(({ category }) => {
        // eslint-disable-next-line
        // @ts-ignore: even with the conditional in previous line, ts complains about filter possibly being null
        return category === this.filter.value;
      });
    },
    isPublished(): boolean {
      return this.workflow === "1";
    },
  },
  methods: {
    ...mapActions("jira", ["createJiraTicket", "getIssuesForProject"]),
    handleTestSelected({
      test,
      enabled,
    }: {
      test: TestTypeOverview;
      enabled: boolean;
    }) {
      if (enabled) {
        this.testsSelected.push(test);
      } else {
        this.testsSelected = this.testsSelected.filter(
          ({ uuid }) => uuid !== test.uuid
        );
      }
    },
    isSelected(test: TestTypeOverview): boolean {
      return this.testsSelected.map(({ uuid }) => uuid).includes(test.uuid);
    },
    handleToggleAll(e) {
      if (e) {
        this.testsSelected.push(...this.tests);
      } else {
        this.testsSelected = [];
      }
    },
    clearSelection() {
      this.testsSelected = [];
    },
    openDialog(type: "jira" | "rerun") {
      if (this.disabled) {
        this.showDisabledWarning();
        return;
      }

      if (type === "jira" && !this.hasJiraProject) {
        this.showJiraWarning();
        return;
      }

      if (type === "jira" && this.selectedFailingTests.length > 0) {
        this.jiraDialog = true;
      } else if (type === "rerun" && this.testsSelected.length > 0) {
        this.rerunDialog = true;
      }
    },
    isInErrorState(test: TestTypeOverview): boolean {
      const type = test.test_type_id;
      return Boolean(this.testsMeta?.[type]?.critical_error);
    },
    testTypeStatus(test: TestTypeOverview): TestStatus {
      const type = test.test_type_id;
      return this.testsMeta?.[type]?.latest_status;
    },
    groupBy(index: number) {
      this.group = index;
      // @todo actual group implementation
    },
    rerunDialogConfirm() {
      let succesfulRuns = 0;
      const promises: Promise<void>[] = [];

      this.rerunDialog = false;
      this.testsSelected.forEach((item) => {
        if (item.status !== "pending") {
          promises.push(
            new Promise((resolve, reject) => {
              // @todo check if it updates the test list correctly
              createTestRerun({
                type: item.test_type_id,
                name: item.name,
                field_testsuites: [item.category],
                project: { id: item.project_uuid },
              } as RawTest)
                .then(({ data }) => {
                  this.$store.dispatch("tests/updateTestStatus", {
                    ...data.attributes,
                    id: data.id,
                    type: item.test_type_id,
                  });
                  succesfulRuns += 1;
                  resolve();
                })
                .catch(reject)
                .finally(() => {
                  this.testsSelected.splice(
                    this.testsSelected.indexOf(item),
                    1
                  );
                });
            })
          );
        }
      });

      Promise.all(promises)
        .then(() => {
          if (succesfulRuns === promises.length) {
            this.$emit("rerun-success");
            return showToastedMessage(
              i18n.feedback.tests_started.all,
              "success"
            );
          } else {
            return showToastedMessage(
              i18n.feedback.tests_started.other
                .replace("${count}", String(succesfulRuns))
                .replace("${total}", String(promises.length)),
              "success"
            );
          }
        })
        .catch(() => {
          if (succesfulRuns > 0) {
            showToastedMessage(
              i18n.feedback.tests_started.other
                .replace("${count}", String(succesfulRuns))
                .replace("${total}", String(promises.length)),
              "success"
            );
          }

          return showToastedMessage(i18n.feedback.tests_started.fail, "error");
        })
        .finally(() => {
          this.$emit("testsRerun");
        });
    },

    jiraDialogConfirm() {
      const promises: Promise<void>[] = [];

      this.jiraDialog = false;
      this.selectedFailingTests.forEach((item) => {
        const type = item.test_type_id;
        const url = `${BASE_FE_URL}/project/${item.project_uuid}/test/${type}/${item.uuid}`;
        promises.push(
          this.createJiraTicket({ testId: item.test_type_id, link: url })
        );
      });

      Promise.all(promises).then(() =>
        this.getIssuesForProject(this.selectedFailingTests[0].uuid)
      );
    },
    handleSort({ id, order }: { id: string; order: SortOrder }) {
      const columnIndex = this.tableOptions.columns.findIndex(
        ({ id: columnId }) => id === columnId
      );
      if (columnIndex > -1) {
        const columns = [...this.tableOptions.columns];
        columns.forEach((column, i) => {
          columns[i] = { ...column, order: SortOrder.None };
        });
        columns[columnIndex] = { ...columns[columnIndex], order };
        this.$set(this.tableOptions, "columns", columns);
      }
    },
    sortFunction(): (a: RawTest, b: RawTest) => number {
      const orderedColumn = this.tableOptions.columns.find(
        ({ order }) => order !== SortOrder.None
      );
      return (a, b) => {
        if (!orderedColumn) return 0;
        const isAscending = orderedColumn.order === "asc";
        if (orderedColumn.id === "name") {
          if (isAscending) {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
          } else {
            return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
          }
        }

        if (orderedColumn.id === "score") {
          if (isAscending) {
            return testScoreInPercent(a) > testScoreInPercent(b) ? 1 : -1;
          } else {
            return testScoreInPercent(a) < testScoreInPercent(b) ? 1 : -1;
          }
        }

        if (orderedColumn.id === "latest_run") {
          if (isAscending) {
            return new Date(a.created) > new Date(b.created) ? 1 : -1;
          } else {
            return new Date(a.created) < new Date(b.created) ? 1 : -1;
          }
        }
        return 0;
      };
    },
    route(test: TestTypeOverview): Location {
      return {
        name: RouteNames.TestDetails,
        params: {
          projectId: test.project_uuid,
          testsuite: test.category,
          type: test.test_type_id,
          testId: test.uuid,
        },
      };
    },
    handleRowClick(test: TestTypeOverview): void {
      this.$router.push(this.route(test));
    },
    testMeta(test) {
      const type = test.test_type_id;
      return this.testsMeta?.[type];
    },
    showDisabledWarning() {
      this.$root.$emit("show-full-width-alert", {
        message: i18n.actions.select_to_rerun_or_generate,
        type: "info",
        forIds: "pdf-report-button rerun-tests-button",
      });
    },
    showJiraWarning() {
      this.$root.$emit("show-full-width-alert", {
        message: i18n.test.has_no_jira_project,
        type: "primary",
        forIds: "create-ticket-button",
      });
    },
    handleGenerateReportClick() {
      if (this.disabled) {
        this.showDisabledWarning();
        return;
      }
      this.$router.push(this.generateReportRoute);
    },
  },
  mounted() {
    EventBus.$on(GlobalEvents.FilterTestTable, (filter) => {
      this.filter = filter;
      const testTable = this.$refs.testTable as HTMLElement;
      if (testTable) {
        this.$nextTick(() => {
          testTable.scrollIntoView({
            behavior: "smooth",
          });
        });
      }
    });
  },
});
