























import Vue from "vue";
export default Vue.extend({
  props: {
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Boolean],
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputId: this.id || crypto.randomUUID(),
    };
  },
  methods: {
    handleChange(event) {
      this.$emit("change", event.target.checked ? event.target.value : null);
    },
  },
});
