var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","position":"relative"}},[(_vm.project)?_c('project-header',{attrs:{"id":_vm.projectId,"url":_vm.url,"title":_vm.project.name,"logo":_vm.logo,"show-edit-button":_vm.showEditButton,"back-route":_vm.backRoute,"has-link":"","data-v-step":"test-overview"}}):_vm._e(),(!_vm.test)?_c('loading-indicator'):[(_vm.testType)?_c('test-summary',{staticClass:"TestDetail-summary",attrs:{"test":_vm.test,"test-type":_vm.testType,"test-data":_vm.tests,"tickets":_vm.jiraTickets,"test-meta":_vm.testMeta,"data-v-step":"test-summary"}}):_vm._e(),(_vm.showMetatagsReportDownloadButton)?_c('ui-card',{staticClass:"TestDetail-reports",attrs:{"title":"Reports"}},[_c('export-metatags-report',{attrs:{"project-id":_vm.projectId}})],1):_vm._e(),_c('ui-card',{staticClass:"TestDetail-results",attrs:{"title":_vm.$i18n.test.results_title,"tag":"h3"},scopedSlots:_vm._u([{key:"before",fn:function(){return [(
            (_vm.project && _vm.type && _vm.project.meta_parsed[_vm.type].critical_error) ||
            _vm.project.alerts.length
          )?_c('notification-bar',{attrs:{"notifications":_vm.project.meta_parsed[_vm.type].critical_error || _vm.project.alerts,"show":"full"}}):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"TestDetail-resultsActions u-hiddenPrint"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipCopy),expression:"tooltipCopy"}],staticClass:"TestDetail-actionsButton"},[_c('btn',{staticClass:"u-fullHeight",attrs:{"color":"perfect","disabled":_vm.isJiraButtonDisabled,"data-v-step":"test-jira"},on:{"click":function($event){_vm.jiraDialog = true}}},[_vm._v(" "+_vm._s(_vm.$i18n.actions.create_tickets)+" ")])],1),_c('btn',{staticClass:"TestDetail-actionsButton u-text-White",attrs:{"outlined":"","to":_vm.generateReportRoute,"disabled":_vm.isPending || _vm.isRunning,"data-v-step":"test-report_function"}},[_vm._v(" "+_vm._s(_vm.$i18n.actions.generate_report)+" ")]),(_vm.isPublished)?_c('btn',{staticClass:"TestDetail-actionsButton u-text-White",attrs:{"outlined":"","disabled":_vm.isPending || _vm.isRunning,"icon":_vm.isPending || _vm.isRunning ? 'spinner-icon' : 'flip-icon',"spin":_vm.isPending || _vm.isRunning,"data-v-step":"test-automated_frequency"},on:{"click":function($event){_vm.rerunDialog = true}}},[_vm._v(" "+_vm._s(_vm.isPending || _vm.isRunning ? _vm.$i18n.status.pending : _vm.$i18n.actions.rerun_test)+" ")]):_vm._e()],1)]),(_vm.type.startsWith('lighthouse'))?_c('lighthouse-results',{attrs:{"results":_vm.lighthouseResults}}):_c('tab-group',{attrs:{"groups":_vm.groups,"data-v-step":"test-test_results"},scopedSlots:_vm._u([{key:"tab",fn:function(ref){
          var group = ref.group;
return [(group.value === 'Errors')?_c('span',[_vm._v(" Error ("+_vm._s(_vm.eventCounts.error + (_vm.hasTruncatedResults && "+"))+") ")]):(group.value === 'Warnings')?_c('span',[_vm._v(" Warning ("+_vm._s(_vm.eventCounts.warning + (_vm.hasTruncatedResults && "+"))+") ")]):_c('span',[_vm._v(" Success ("+_vm._s(_vm.eventCounts.success + (_vm.hasTruncatedResults && "+"))+") ")])]}},{key:"content",fn:function(ref){
          var group = ref.group;
return [(_vm.resultsLoading)?_c('div',{staticStyle:{"width":"100%"}},[_c('loading-indicator')],1):_vm._e(),(_vm.tests && !_vm.isPending && _vm.reportItems && _vm.reportItems.items)?_c('results-accordion',{staticClass:"TestDetail-results",attrs:{"items":_vm.reportItems.items,"type":group.value,"test":_vm.test,"test-type":_vm.type,"results-loading":_vm.resultsLoading}}):_c('div',{staticClass:"TestDetail-emptyResultsWrapper"},[(group.value === 'Errors')?_c('p',{staticClass:"TestDetail-emptyResults"},[_vm._v(" "+_vm._s(_vm.$i18n.test.results_errors_empty)+" ")]):(group.value === 'Warnings')?_c('p',{staticClass:"TestDetail-emptyResults"},[_vm._v(" "+_vm._s(_vm.$i18n.test.results_warning_empty)+" ")]):(
              group.value === 'Info' &&
              _vm.eventCounts.error === 0 &&
              _vm.eventCounts.warning === 0
            )?_c('p',{staticClass:"TestDetail-emptyResults"},[_vm._v(" "+_vm._s(_vm.$i18n.test.congratulations)+" ")]):(group.value === 'Info')?_c('p',{staticClass:"TestDetail-emptyResults"},[_vm._v(" "+_vm._s(_vm.$i18n.test.results_success_empty)+" ")]):_vm._e()])]}}]),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),_c('modal',{attrs:{"max-width":"300px","title":_vm.$i18n.confirmation.rerun.title.one,"hide-title":""},model:{value:(_vm.rerunDialog),callback:function ($$v) {_vm.rerunDialog=$$v},expression:"rerunDialog"}},[_c('simple-dialog',{attrs:{"title":_vm.$i18n.confirmation.rerun.title.one,"copy":_vm.$i18n.confirmation.rerun.copy.one.replace('${name}', _vm.test.name)},on:{"cancel":function($event){_vm.rerunDialog = false},"confirm":_vm.rerunDialogConfirm}})],1),_c('modal',{attrs:{"max-width":"400px","title":_vm.$i18n.confirmation.jira_ticket.title.one,"hide-title":""},model:{value:(_vm.jiraDialog),callback:function ($$v) {_vm.jiraDialog=$$v},expression:"jiraDialog"}},[_c('simple-dialog',{attrs:{"title":_vm.$i18n.confirmation.jira_ticket.title.one,"copy":_vm.jiraCopy},on:{"cancel":function($event){_vm.jiraDialog = false},"confirm":_vm.jiraDialogConfirm}})],1)],_c('tutorial',{attrs:{"name":_vm.tutorialName,"steps":_vm.tutorialSteps,"callbacks":_vm.tutorialCallbacks,"button-labels":_vm.tutorialButtonLabels}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }