import { render, staticRenderFns } from "./TestsOverview.vue?vue&type=template&id=98a38e4c&scoped=true&"
import script from "./TestsOverview.vue?vue&type=script&lang=ts&"
export * from "./TestsOverview.vue?vue&type=script&lang=ts&"
import style0 from "./TestsOverview.vue?vue&type=style&index=0&id=98a38e4c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a38e4c",
  null
  
)

export default component.exports