











































































import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import EmptyCategory from "@/components/EmptyCategory.vue";
import CustomTable, { SortOrder } from "@/components/ui/CustomTable.vue";
import ProjectTeaserRow from "@/components/project-teaser/ProjectTeaserRow.vue";
import Tutorial from "@/components/Tutorial.vue";
import { ProjectTeaserRow as IProjectTeaserRow } from "@/interfaces";
import Btn from "@/components/ui/Btn.vue";
import { i18n } from "@/i18n";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";
import HomeTutorialMixin from "@/mixins/tutorial/home";

const LAST_ITEM_SELECTOR = ".CustomTableRow:last-child";

export default Vue.extend({
  mixins: [HomeTutorialMixin],

  components: {
    LoadingIndicator,
    EmptyCategory,
    CustomTable,
    ProjectTeaserRow,
    Btn,
    Tutorial,
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      // Resets project list so it shows a new batch
      this.$store.commit("projects/setProjectsList", []);
      this.$store.commit("projects/setProjectsApiUrl", "");
    }
    next();
  },

  beforeRouteLeave(to, from, next) {
    // Resets project list so it does not show mixed categories
    this.$store.commit("projects/setProjectsList", []);
    this.$store.commit("projects/setProjectsApiUrl", "");
    next();
  },

  data() {
    return {
      isLoadingMoreEntries: false,
      observer: null as IntersectionObserver | null,
      lastItem: null as HTMLElement | null,
      tableOptions: {
        columns: [
          {
            label: "",
            id: "alert",
            orderable: false,
          },
          {
            label: "Project",
            id: "name",
            orderable: true,
            order: "asc",
          },
          {
            label: "Project score",
            id: "score",
            orderable: true,
            order: "none",
          },
          {
            label: "Trend",
            id: "trend",
            orderable: true,
            order: "none",
          },
          {
            label: "Latest test run",
            id: "latest_run",
            orderable: true,
            order: "none",
          },
          {
            label: "Project team",
            id: "team",
            orderable: true,
            order: "none",
          },
        ],
      },
    };
  },

  watch: {
    lastItem() {
      if (this.lastItem) {
        this.observer?.observe(this.lastItem);
      }
    },

    entries() {
      this.fetchList();
    },
  },

  computed: {
    ...mapState("projects", [
      "projectsMessages",
      "entries",
      "search",
      "projectsDetails",
      "projectsList",
    ]),
    ...mapGetters("projects", ["findProjectDetails"]),
    ...mapState("tests", ["testSnapshots"]),
    ...mapGetters("tests", ["getTestSnapshotsForProject"]),

    isLoading(): boolean {
      return this.entries === null;
    },

    categoryId(): string {
      return this.$route.params.id;
    },

    isArchive(): boolean {
      return this.$route.name === "archivedProjects";
    },

    pageTitle(): string {
      return this.isArchive ? i18n.pages.archive.title : i18n.pages.home.title;
    },

    sidebarProjects(): { name: string; id: string }[] {
      if (this.entries === null) return [];

      let entries = this.entries;
      // entries don't consider archived projects
      if (this.isArchive) {
        entries = this.projectsList;
      }

      return entries
        .filter(({ type, parentIds }) => {
          if (this.categoryId) {
            return parentIds
              ? parentIds.includes(this.categoryId) && type == "project"
              : false;
          }

          return type == "project";
        })
        .map(({ label, id }) => {
          return { name: label, id };
        });
    },

    renderedProjects(): IProjectTeaserRow[] {
      return this.projectsList
        .filter((project) => {
          if (!this.search) return true;

          return project.name.toLowerCase().includes(this.search.toLowerCase());
        })
        .map((project) => ({
          id: project.uuid,
          name: project.name,
          url: project.url,
          alerts: project.alerts,
          favicon: project.favicon,
          users: project.project_team,
          score: project.score,
          trend: project.project_score_trend,
          lastTestRunDate: project.latest_test_run,
          isCritical: project.alerts?.messages.length > 0,
        }));
    },

    categoryEditRoute(): Location {
      return {
        name: RouteNames.CategoryEdit,
        params: {
          id: this.categoryId,
        },
      };
    },

    newProjectRoute(): Location {
      return {
        name: RouteNames.ProjectNew,
        query: { categoryId: this.$store.state.categories.currentCategoryId },
      };
    },
  },

  methods: {
    ...mapActions("projects", [
      "getProjectsList",
      "subscribeProjectsWithPendingTestsToPolling",
    ]),

    getLastItem() {
      this.$nextTick(() => {
        this.lastItem = this.$el.querySelector(LAST_ITEM_SELECTOR);
        if (!this.lastItem) {
          setTimeout(() => {
            this.getLastItem();
          }, 100);
        }
      });
    },

    fetchList(entry?: IntersectionObserverEntry): void {
      if (entry) {
        this.observer?.unobserve(entry.target);
      }
      this.isLoadingMoreEntries = true;
      this.getProjectsList({
        categoryId: this.categoryId || "",
        workflow: this.isArchive ? 2 : 1,
      })
        .then(({ total }) => {
          this.getLastItem();

          if (
            this.renderedProjects.length !== 0 &&
            this.renderedProjects.length === total
          ) {
            this.observer?.disconnect();
          }

          this.subscribeProjectsWithPendingTestsToPolling();
        })
        .finally(() => {
          this.isLoadingMoreEntries = false;
        });
    },

    navigate({ id }): void {
      this.$router.push({
        name: RouteNames.ProjectOverview,
        params: {
          id,
        },
      });
    },

    handleSort({ id, order }) {
      const columnIndex = this.tableOptions.columns.findIndex(
        ({ id: columnId }) => id === columnId
      );
      if (columnIndex > -1) {
        const columns = [...this.tableOptions.columns];
        columns.forEach((column, i) => {
          columns[i] = { ...column, order: SortOrder.None };
        });
        columns[columnIndex] = { ...columns[columnIndex], order };
        this.$set(this.tableOptions, "columns", columns);
      }
    },

    sortFunction(): (a: IProjectTeaserRow, b: IProjectTeaserRow) => number {
      const orderedColumn = this.tableOptions.columns.find(
        ({ order }) => order !== SortOrder.None
      );
      return (a, b) => {
        if (!orderedColumn || !a.project || !b.project) return 0;
        const isAscending = orderedColumn.order === SortOrder.Ascending;
        if (orderedColumn.id === "name") {
          if (isAscending) {
            return a.project.name.toLowerCase() > b.project.name.toLowerCase()
              ? 1
              : -1;
          } else {
            return a.project.name.toLowerCase() < b.project.name.toLowerCase()
              ? 1
              : -1;
          }
        }

        if (orderedColumn.id === "score") {
          if (isAscending) {
            return a.score > b.score ? 1 : -1;
          } else {
            return a.score < b.score ? 1 : -1;
          }
        }

        if (orderedColumn.id === "trend") {
          if (isAscending) {
            return a.trend > b.trend ? 1 : -1;
          } else {
            return a.trend < b.trend ? 1 : -1;
          }
        }

        if (orderedColumn.id === "latest_run") {
          if (isAscending) {
            return new Date(a.lastTestRunDate || "") >
              new Date(b.lastTestRunDate || "")
              ? 1
              : -1;
          } else {
            return new Date(a.lastTestRunDate || "") <
              new Date(b.lastTestRunDate || "")
              ? 1
              : -1;
          }
        }
        return 0;
      };
    },
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.fetchList(entry);
        }
      });
    });

    this.fetchList();
  },
});
