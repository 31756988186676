


































import Vue from "vue";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import { i18n } from "@/i18n";

export default Vue.extend({
  components: {
    ArrowDown,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    contentID(): string {
      return `${this.id}-content`;
    },
    buttonLabel(): string {
      return i18n.actions.more_about.replace(":title", this.title);
    },
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
