<template>
  <div class="ProjectFormJiraInformation">
    <project-form-title
      tag="h3"
      :description="$i18n.project.form.jira.explanation"
    >
      {{ $i18n.project.form.jira.title }}
    </project-form-title>
    <template v-if="accounts.length > 0">
      <div class="u-form-columns">
        <div class="ProjectFormJiraInformation-field">
          <custom-select
            :disabled="!accounts.length || isDisabled"
            :items="availableAccounts"
            :label="$i18n.project.attributes.jira.account"
            id="jira_account_select"
            option-label="label"
            v-model="account"
            @input="unlockOverrideStatus('jira_account')"
          ></custom-select>
          <field-info
            id="jira_account"
            :options="jiraAccountOptions"
            :value="account"
            @inherit-value="account = $event"
          ></field-info>
        </div>

        <div class="ProjectFormJiraInformation-field">
          <custom-select
            :disabled="!account || isDisabled"
            :items="filteredJiraProjects"
            :label="$i18n.project.attributes.jira.project_id"
            id="jira_project_select"
            option-label="name"
            v-model="jiraProject"
            @input="unlockOverrideStatus('jira_project')"
          ></custom-select>
          <field-info
            id="jira_project"
            :options="jiraProjectOptions"
            :value="jiraProject"
            @inherit-value="jiraProject = $event"
          ></field-info>
        </div>

        <div class="ProjectFormJiraInformation-field">
          <custom-select
            :disabled="!account || isDisabled"
            :items="issueTypes"
            :label="$i18n.project.attributes.jira.default_issue_type"
            id="jira_issue_select"
            option-label="name"
            v-model="defaultIssueType"
            @input="unlockOverrideStatus('jira_default_issue_type')"
          ></custom-select>
          <field-info
            id="jira_default_issue_type"
            :options="issueTypeOptions"
            :value="defaultIssueType"
            @inherit-value="defaultIssueType = $event"
          ></field-info>
        </div>

        <div class="ProjectFormJiraInformation-field">
          <custom-select
            :disabled="!account || isDisabled"
            :items="issuePriorities"
            :label="$i18n.project.attributes.jira.default_issue_priority"
            id="jira_priority_select"
            option-label="name"
            v-model="defaultIssuePriority"
            @input="unlockOverrideStatus('jira_default_issue_type_priority')"
          ></custom-select>
          <field-info
            id="jira_default_issue_type_priority"
            :options="issuePriorityOptions"
            :value="defaultIssuePriority"
            @inherit-value="defaultIssuePriority = $event"
          ></field-info>
        </div>
      </div>
    </template>
    <p v-else>
      <i>{{ $i18n.project.attributes.jira.no_jira }}</i>
    </p>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import FieldInfo from "@/components/FieldInfo";
import CustomSelect from "@/components/form/Select.vue";
import unlockOverrideStatusMixin from "../../mixins/override-inherited-value";
import { i18n } from "@/i18n";
import ProjectFormTitle from "./ProjectFormTitle.vue";

export default {
  mixins: [unlockOverrideStatusMixin],
  components: {
    FieldInfo,
    CustomSelect,
    ProjectFormTitle,
  },
  props: {
    jiraAccountIdInput: {
      type: String,
      default: "",
    },
    jiraAccountOptions: {
      type: Object,
      default: () => ({}),
    },
    jiraProjectIdInput: {
      type: String,
      default: "",
    },
    jiraProjectOptions: {
      type: Object,
      default: () => ({}),
    },
    issuePriorityInput: {
      type: String,
      default: "",
    },
    issuePriorityOptions: {
      type: Object,
      default: () => ({}),
    },
    issueTypeInput: {
      type: String,
      default: "",
    },
    issueTypeOptions: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      account: {},
      accountOptions: {},
      defaultIssuePriority: "",
      defaultIssueType: "",
      jiraProject: "",
      jiraAccountIdIndex: this.jiraAccountIdInput || "",
    };
  },

  computed: {
    ...mapState("jira", ["accounts", "jiraProjects", "jiraPriorities"]),
    ...mapGetters("categories", ["getOverriddenFieldLock"]),

    filteredJiraProjects() {
      return this.jiraAccountIdIndex
        ? this.jiraProjects[this.jiraAccountIdIndex]
        : [];
    },

    issueTypes() {
      if (this.jiraProject) {
        return this.jiraProject.issueTypes;
      }

      return [];
    },

    issuePriorities() {
      if (
        this.jiraPriorities &&
        this.account &&
        this.account.id &&
        this.jiraPriorities[this.account.id]
      ) {
        const priorities = this.jiraPriorities[this.account.id];
        const prioritiesArray = [];
        Object.keys(priorities).forEach((item) => {
          prioritiesArray.push(priorities[item].name);
        });
        return prioritiesArray;
      }

      return [];
    },

    availableAccounts() {
      return [
        {
          id: {},
          label: i18n.project.form.jira.no_account,
        },
        ...this.accounts,
      ];
    },
  },

  watch: {
    account(newValue) {
      if (newValue) {
        this.getJiraProjects(newValue.id);
        this.jiraAccountIdIndex = newValue.id;
        this.$emit("jiraAccount", newValue);
      } else {
        this.$emit("jiraAccount", {});
      }
      this.jiraProject = "";
    },

    defaultIssuePriority: {
      immediate: true,
      handler(newValue) {
        this.$emit("defaultIssuePriority", newValue);
      },
    },

    defaultIssueType: {
      immediate: true,
      handler(newValue) {
        this.$emit("defaultIssueType", newValue);
      },
    },

    jiraProject(jiraProject) {
      if (
        this.account &&
        jiraProject &&
        this.issueTypeInput === "" &&
        this.issuePriorityInput === ""
      ) {
        this.defaultIssueType = "Task";
        Object.keys(this.jiraPriorities[this.account.id]).forEach((item) => {
          if (this.jiraPriorities[this.account.id][item].name === "Major") {
            this.defaultIssuePriority = "Major";
          }
        });
        this.defaultIssuePriority =
          this.defaultIssuePriority === "" &&
          this.jiraPriorities[this.account.id][0]
            ? this.jiraPriorities[this.account.id][0].name
            : this.defaultIssuePriority;
      }
      this.$emit("jiraProject", jiraProject);
    },

    accounts(newValue) {
      if (newValue.length) {
        const jiraAccountSelected = newValue.find(
          (account) => account.id === this.jiraAccountIdInput
        );
        this.account = jiraAccountSelected;
      }
    },

    jiraAccountIdInput: {
      immediate: true,
      handler(newValue) {
        if (this.accounts.length) {
          const jiraAccountSelected = this.accounts.find(
            (account) => account.id === newValue
          );
          this.account = jiraAccountSelected;
        }
      },
    },

    jiraProjectIdInput(newValue) {
      const valid =
        this.jiraAccountIdInput && this.jiraProjects[this.jiraAccountIdInput];
      const filteredJiraProjects = valid
        ? this.jiraProjects[this.jiraAccountIdInput]
        : [];
      const jiraProjectSelected = filteredJiraProjects.find(
        (jiraProject) => jiraProject.key === newValue
      );
      this.jiraProject = jiraProjectSelected;
    },

    filteredJiraProjects(newValue) {
      if (this.jiraAccountIdInput && newValue) {
        const jiraProjectSelected = newValue.find(
          (jiraProject) => jiraProject.key === this.jiraProjectIdInput
        );
        this.jiraProject = jiraProjectSelected;
      }
      if (this.account) {
        this.getJiraPriorities(this.account.id);
      }
    },

    issuePriorityInput(newValue) {
      this.defaultIssuePriority = newValue;
    },

    issueTypeInput(newValue) {
      this.defaultIssueType = newValue;
    },
  },

  methods: {
    ...mapActions("jira", [
      "getAccounts",
      "getJiraProjects",
      "getJiraPriorities",
    ]),

    handleRemoveSelectedJiraProject() {
      this.jiraProject = "";
    },
  },
  created() {
    this.getAccounts();
    this.getJiraProjects(this.jiraAccountIdInput || "");
  },
};
</script>
