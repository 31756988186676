import { FullWidthAlert } from "@/interfaces";
import Vue, { CreateElement, VNode } from "vue";
import FullWidthAlerts from "./FullWidthAlerts.vue";

function getBgColor(type = "info"): string {
  if (type === "primary") {
    return "Primary";
  }
  return "";
}

function getTextColor(type = "info"): string {
  if (type === "primary") {
    return "White";
  }
  return "";
}

export default Vue.extend({
  data() {
    return {
      alertMessages: [] as FullWidthAlert[],
    };
  },

  computed: {
    reverseAnimationDelay() {
      return 5000;
    },
  },

  mounted() {
    this.$root.$on("show-full-width-alert", (alert: FullWidthAlert) => {
      const id = crypto.randomUUID();
      this.alertMessages.push({
        id,
        ...alert,
        bgColor: getBgColor(alert.type),
        textColor: getTextColor(alert.type),
      });

      setTimeout(() => {
        this.alertMessages = this.alertMessages.filter(
          (message) => message.id !== id
        );
      }, this.reverseAnimationDelay + 300);
    });
  },

  render(createElement: CreateElement): VNode {
    return createElement(FullWidthAlerts, {
      props: {
        alerts: this.alertMessages,
        reverseAnimationDelay: this.reverseAnimationDelay,
      },
    });
  },
});
