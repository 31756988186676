






























































































































































































import Vue, { PropType } from "vue";
import FieldInfo from "@/components/FieldInfo.vue";
import unlockOverrideStatusMixin from "@/mixins/override-inherited-value";
import OutlinedTextField from "@/components/form/OutlinedTextField.vue";
import OutlinedTextArea from "@/components/form/OutlinedTextArea.vue";
import Checkbox from "../form/Checkbox.vue";
import ProjectFormExpansionPanel from "./ProjectFormExpansionPanel.vue";
import { AdvancedConfiguration } from "@/interfaces";
import ProjectFormTitle from "./ProjectFormTitle.vue";
import CustomSelect from "@/components/form/Select.vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  mixins: [unlockOverrideStatusMixin],
  components: {
    FieldInfo,
    OutlinedTextField,
    CustomSelect,
    OutlinedTextArea,
    Checkbox,
    ProjectFormExpansionPanel,
    ProjectFormTitle,
  },

  props: {
    options: {
      type: Object,
      required: true,
    },
    value: {
      type: Object as PropType<AdvancedConfiguration>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("categories", ["getterOptions"]),

    crawlerOptions() {
      let options: Array<any> = [];
      for (var key in this.getterOptions) {
        const element = { label: this.getterOptions[key], value: key };
        options.push(element);
      }

      return options;
    },
    isCrawlerDisabled() {
      return this.value?.urlGetter?.label !== "Url List";
    },
    urlGetterUrlsValue() {
      if (this.value.urlGetterOptions?.urls instanceof Array) {
        return this.value.urlGetterOptions?.urls.join("\n");
      }

      return "";
    },
    urlGetterValue() {
      if (
        typeof this.value?.urlGetter === "object" &&
        this.value?.urlGetter !== null
      ) {
        return this.value?.urlGetter;
      } else {
        if (this.getterOptions?.[this.value?.urlGetter] !== undefined) {
          return this.getterOptions?.[this.value?.urlGetter];
        } else {
          return { label: "Undefinded", value: this.value?.urlGetter };
        }
      }
    },
  },
  methods: {
    ...mapActions("categories", ["getGetterOptionsForProject"]),
    update(): void {
      (this as any).getGetterOptionsForProject();
    },
    handleInput(key, value) {
      //temp solution only one options currently
      if (key === "urlGetterOptions") {
        value = { urls: value.split(/\n/) };
      }
      this.$emit("input", {
        ...this.value,
        [key]: value,
      });

      const mapKey = {
        disableProxy: "disable_proxy",
        urlGetter: "url_getter",
        urlGetterOptions: "url_getter_options",
        enableNoindexNofollowPages: "enable_noindex_nofollow_pages",
        disablePageLoading: "disable_page_loading",
      };

      // eslint-disable-next-line
      // @ts-ignore this comes from mixin
      this.unlockOverrideStatus(mapKey[key]);
    },
    getOption(key) {
      return this.options[key]?.option;
    },
  },
  created() {
    (this as any).update();
  },
});
