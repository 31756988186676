var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"Btn",class:[
    ("Btn--" + _vm.color),
    {
      'Btn--outlined': _vm.outlined,
      'Btn--disabled': _vm.disabled,
      'Btn--small': _vm.small,
      'Btn--icon': _vm.iconOnly,
    } ],attrs:{"to":!_vm.external ? _vm.to : null,"href":_vm.external ? _vm.to : null,"target":_vm.external || _vm.openNewTab ? '_blank' : null,"rel":_vm.external || _vm.openNewTab ? 'noreferrer noopener' : null,"disabled":_vm.disabled,"title":_vm.title},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('transition',{attrs:{"name":_vm.iconTransitionName}},[(_vm.icon)?_c(_vm.icon,{tag:"component",staticClass:"Btn-icon",class:[_vm.iconClass, { 'Btn--spin-icon': _vm.spin }],attrs:{"aria-hidden":"true"}}):_vm._e()],1),_c('span',{staticClass:"Btn-content"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }