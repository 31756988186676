export const CLIENT_ID = "9d83eaa7-2db1-4cf9-865d-0973b610b452";
export const CLIENT_SECRET =
  "oKxF3mKTh3VoBCsSfKVDrG6WkFnK3ivBtkVbeZrgRnZrwTg542W5tux7J5se73vm";

export const BASE_FE_URL = document.location.origin;

export const BASE_URL =
  document.getElementById("app")?.getAttribute("data-api-url") ||
  "http://thewsd.test";

export const API_VERSION = "1.0";
export const API_URL = `${BASE_URL}/api/${API_VERSION}/`;
export const API_OAUTH_URL = `${BASE_URL}/oauth/token`;
export const API_URL_V2 = `${BASE_URL}/api/2.0/`;
