import { showToastedMessage } from "@/lib/utils";
import JsonApiParse from "jsonapi-parse";
import { uniqBy, orderBy } from "lodash";
import Vue from "vue";

import {
  fetchTestsOfTypeForProject,
  fetchSnapshotsForProjects,
  fetchTestsForProject,
  fetchTestSuites,
  fetchTestTypes,
  fetchTestData,
  fetchProjectTestsList,
} from "../../../lib/api";

export default {
  namespaced: true,
  state: {
    testSnapshots: [],
    testSuites: [],
    testTypes: [],
    loaders: [],
    tests: [],
    testTypeDocumentation: {},
    projectTestsList: [],
  },

  actions: {
    /**
     * Get a collection of snapshot results for given projects.
     *
     * @param {Array} ids - An array of project IDs.
     * @returns {Promise}
     */
    loadSnapshotsForProjects({ commit }, ids) {
      let totalSnapshots = [];

      return new Promise((resolve, reject) => {
        fetchSnapshotsForProjects(ids)
          .then((response) => {
            let newSnapshots = [];
            Object.keys(response).forEach((key) => {
              const resultParsed = JsonApiParse.parse(response[key].body);
              // Seems like the mock subrequests is missing the "attributes" key
              if (resultParsed) {
                const snapshotsArray = resultParsed.data;

                if (snapshotsArray && snapshotsArray.length > 0) {
                  newSnapshots = [...newSnapshots, ...snapshotsArray];
                }
              }
            });
            totalSnapshots = [...totalSnapshots, ...newSnapshots];
            commit("addTestSnapshots", totalSnapshots);
            resolve();
          })
          .catch((error) => {
            console.log("loadSnapshotsForProjects", error);
            reject();
          });
      });
    },

    /**
     * Get the list of testsuites in the system.
     *
     * @returns {Promise}
     */
    getTestSuites({ commit }) {
      commit("addLoader", "testSuites");
      fetchTestSuites()
        .then((result) => {
          commit("setTestSuites", result);
          commit("removeLoader", "testSuites");
        })
        .catch((error) => {
          console.log("getTestSuites", error);
          commit("removeLoader", "testSuites");
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    /**
     * Get the list of testtypes in the system.
     *
     * @returns {Promise}
     */
    async getTestTypes({ commit }, { id, entity = "project" }) {
      commit("setTestTypesFetching", true);
      await fetchTestTypes(id, entity)
        .then((result) => {
          commit("setTestTypes", result);
          commit("setTestTypesFetching", false);
        })
        .catch((error) => {
          console.log("getTestTypes", error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
          commit("setTestTypesFetching", false);
        });
    },

    /**
     * Retrieve all tests for a given testsuite (e.g. "SEO") for a project.
     *
     * @param {string} id - Project id
     * @param {string} testsuite - The testsuite machinename that the test should
     * belongs to (e.g. "SEO")
     * @returns {Promise}
     */
    getTestResults({ commit }, { id, testTypes, keepResultsUntilFetched }) {
      let totalTests = [];

      if (!keepResultsUntilFetched) {
        commit("setTests", totalTests);
      }

      return new Promise((resolve, reject) => {
        fetchTestsForProject({ id, testTypes })
          .then((result) => {
            let newTests = [];
            Object.keys(result).forEach((key) => {
              const resultParsed = JsonApiParse.parse(result[key].body);
              if (resultParsed) {
                const testsArray = resultParsed.data;

                if (testsArray && testsArray.length > 0) {
                  newTests = [...newTests, ...testsArray];
                }
              }
            });
            totalTests = [...totalTests, ...newTests];
            commit("setTests", totalTests);
            resolve(totalTests);
          })
          .catch((error) => {
            console.log("getTestResults", error);
            // showToastedMessage('Something went wrong, please try again later.', 'error');
            reject();
          });
      });
    },

    /**
     * Get all test results for a single test type and project.
     */
    getTestsByTypeAndProject({ commit }, { projectId, testType, testId }) {
      return fetchTestsOfTypeForProject({ projectId, testType })
        .then((response) => {
          const responseParsed = JsonApiParse.parse(response);
          const currentTestInResponse = responseParsed.data.find(
            (test) => test.id == testId
          );

          if (currentTestInResponse) {
            const indexCurrentTest = responseParsed.data.indexOf(
              currentTestInResponse
            );
            responseParsed.data.splice(indexCurrentTest, 1);
          }

          commit("addTests", responseParsed.data);
        })
        .catch((error) => {
          console.log("getTestsByTypeAndProject", error);
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    /**
     * Retrieve the data for a single test by ID and test-type.
     *
     * @param {string} id - The tests id.
     * @param {string} testType - The tests type.
     * @returns {Promise}
     */
    getTestData({ commit }, { id, testType }) {
      return fetchTestData({ id, testType })
        .then((response) => {
          const jsonApiParsed = JsonApiParse.parse(response);
          const included = response.included;
          if (included) {
            const documentation = included.find(
              (include) => include.type == "test_type--test_type"
            );
            if (documentation) {
              commit("setTestTypeDocumentation", {
                testType,
                docs: documentation.attributes,
              });
            }
          }
          commit("addTest", jsonApiParsed.data);
        })
        .catch((error) => {
          console.log("getTestData", error);
          showToastedMessage(
            "Something went wrong, please try again later.",
            "error"
          );
        });
    },

    /**
     * Updates the test status
     *
     * @param {object} updatedTest - The updated test response from the api.
     */
    updateTestStatus({ state, commit }, updatedTest) {
      const test = state.tests.find((test) => test.type === updatedTest.type);
      if (test) {
        // Test changes status once rerun, so we remove by type and add with the new id
        commit("removeTestByType", test.type);
        commit("addTest", {
          ...test,
          id: updatedTest.id,
          status: updatedTest.status,
        });
      }
    },

    getProjectTestsList({ commit }, projectId) {
      if (!projectId) return Promise.resolve();
      return fetchProjectTestsList(projectId).then((tests) => {
        commit("setProjectTestsList", { projectId, tests });
        return tests;
      });
    },
  },

  mutations: {
    setTestTypesFetching(state, fetching) {
      state.testTypesFetching = fetching;
    },

    addLoader(state, id) {
      state.loaders = [...state.loaders, id];
    },

    removeLoader(state, id) {
      state.loaders = state.loaders.filter((item) => item !== id);
    },

    addTestSnapshots(state, newTestSnapshots) {
      const testSnapshots = Array.isArray(newTestSnapshots)
        ? newTestSnapshots
        : [newTestSnapshots];
      const currentSnapshots = state.testSnapshots || [];

      state.testSnapshots = orderBy(
        uniqBy([...testSnapshots, ...currentSnapshots], "id"),
        "created",
        "desc"
      );
    },

    setTestSnapshots(state, newTestSnapshots) {
      state.testSnapshots = orderBy(newTestSnapshots, "created", "desc");
    },

    clearTests(state) {
      state.tests = [];
    },

    setTests(state, newTests) {
      state.tests = newTests;
    },

    addTests(state, newTests) {
      state.tests = orderBy(
        uniqBy([...newTests, ...state.tests], "id"),
        "created",
        "desc"
      );
    },

    setTestSuites(state, testSuites) {
      state.testSuites = testSuites;
    },

    setTestTypes(state, testTypes) {
      testTypes.forEach((testType) => {
        try {
          testType.category = JSON.parse(testType.category);
          testType.category.label = testType.category.label.toLowerCase();
        } catch (e) {} // eslint-disable-line
      });

      state.testTypes = orderBy(
        testTypes,
        [(testType) => testType.label.toLowerCase()],
        "asc"
      );
    },

    removeTestByType(state, testType) {
      state.tests = state.tests.filter((test) => test.type !== testType);
    },

    addTest(state, newTest) {
      state.tests = orderBy(
        uniqBy([newTest, ...state.tests], "id"),
        "created",
        "desc"
      );
    },

    setTestTypeDocumentation(state, { testType, docs }) {
      Vue.set(state.testTypeDocumentation, testType, docs);
    },

    setProjectTestsList(state, { tests }) {
      state.projectTestsList = [...tests];
    },
  },

  getters: {
    getTestSnapshotsForProject: (state) => (id) => {
      return state.testSnapshots.filter(
        (testSnapshot) => testSnapshot.project.id === id
      );
    },

    getTestSuite: (state) => (machinename) =>
      state.testSuites.find((s) => s.machinename === machinename),

    getTestById: (state) => (id) => state.tests.find((t) => t.id === id),

    getTestsByProject: (state) => (id) =>
      uniqBy(
        state.tests.filter((t) => t.project.id === id),
        "type"
      ),

    getTestsByProjectAndType: (state) => (id, type) =>
      state.tests.filter(
        (t) => t.project.id === id && t.type === `test--${type}`
      ),

    getTestTypeByType: (state) => (typeId) =>
      state.testTypes.find((t) => t.id === typeId),

    getEnabledTestsGroupedBySuite: (state) => (projectId, enabledTestTypes) => {
      const suites = [];
      const testTypesForProject = state.testTypes.filter((testType) =>
        enabledTestTypes.includes(testType.id)
      );

      state.testSuites.forEach((testSuite) => {
        const suite = { ...testSuite };
        suite.tests = testTypesForProject.filter((testType) => {
          return testType.testsuite_values.includes(suite.machinename);
        });
        suite.tests.forEach((test) => {
          test.result = state.tests.find(
            (t) => t.project.id === projectId && t.type === `test--${test.id}`
          );
        });
        suites.push(suite);
      });

      return suites.filter(({ tests }) => tests.length > 0);
    },

    projectTestsList: (state) => (projectId) => {
      return (
        state.projectTestsList.filter(
          (test) => test.project_uuid === projectId
        ) || []
      );
    },
  },
};
