














import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { postForgotPasswordEndpoint } from "@/lib/auth";
import AuthForm from "./AuthForm.vue";
import { i18n } from "@/i18n";

export default Vue.extend({
  components: {
    AuthForm,
  },

  data() {
    return {
      fields: [
        { label: i18n.user.email, id: "email", value: "", type: "email" },
      ],
      postForgotPasswordEndpoint,
      sent: false,
    };
  },

  methods: {
    ...mapActions("users", ["postForgotPassword"]),
    ...mapMutations("users", ["resetLoginError"]),

    submit(fields: Record<string, string>): void {
      this.resetLoginError();
      this.postForgotPassword(fields).then(() => {
        this.sent = true;
      });
    },
  },
});
