var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"Alert",class:[
    _vm.data.staticClass,
    _vm.data.class,
    {
      'Alert--info': _vm.props.info,
      'Alert--warning': _vm.props.warning,
      'Alert--critical': _vm.props.critical,
    } ]},[_c('p',{staticClass:"Alert-copy",attrs:{"data-cy":"alert-message"}},[_vm._v(_vm._s(_vm.props.copy))])])}
var staticRenderFns = []

export { render, staticRenderFns }