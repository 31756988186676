































































































import Vue, { PropType } from "vue";
import {
  RawTest,
  RawTestType,
  SuiteScore,
  TestMeta as ITestMeta,
} from "@/interfaces";
import UiCard from "../ui/Card.vue";
import Btn from "../ui/Btn.vue";
import Capsule from "../ui/Capsule.vue";
import CategoryScores from "../scores/CategoryScores.vue";
import NextRunDate from "../NextRunDate.vue";

import {
  formatTimestamp,
  testScoreInPercent,
  testScoreTendency,
} from "@/lib/utils";
import { Location } from "vue-router";
import { RouteNames } from "@/router/router";

export default Vue.extend({
  components: {
    Btn,
    UiCard,
    Capsule,
    CategoryScores,
    NextRunDate,
  },
  props: {
    test: {
      type: Object as PropType<RawTest>,
      required: true,
    },
    testType: {
      type: Object as PropType<RawTestType>,
      required: true,
    },
    testData: {
      type: Array as PropType<RawTest[]>,
      default: () => [],
    },
    testMeta: {
      type: Object as PropType<ITestMeta | null>,
      default: null,
    },
  },
  computed: {
    testSuites(): string[] {
      return this.test.field_testsuites;
    },

    testScore(): SuiteScore {
      return {
        progress: testScoreInPercent(this.test),
        tendency: testScoreTendency(this.test, this.previousTest),
      };
    },

    previousTest(): RawTest {
      const indexTest = this.testData.indexOf(this.test);
      return indexTest >= 0 ? this.testData[indexTest + 1] : ({} as RawTest);
    },

    testSuite(): string {
      return this.testSuites.length ? this.testSuites[0] : "";
    },

    lastRunDate(): string {
      if (this.test.created) {
        return formatTimestamp(this.test.created);
      }
      return "N/A";
    },

    totalPages(): string {
      return this.test.max_score ? String(this.test.max_score) : "";
    },

    testRoute(): Location {
      return {
        name: RouteNames.TestDetails,
        params: {
          projectId: this.test.project.id,
          type: this.test.type.replace("test--", ""),
          testId: this.test.id,
        },
      };
    },
  },
});
