<template>
  <div>
    <iframe v-if="hasHtmlReport" :src="htmlReportUrl"></iframe>
    <template v-else>
      <template v-if="isPending">
        <p>{{ $i18n.test.pending }}</p>
      </template>
      <template v-else>
        <template v-if="this.report.items && this.report.items.length > 0">
          <Result
            :results="this.report.items"
            :index="3"
            class="Results-result"
          />
        </template>
        <p v-else class="Results-noData">
          {{ $i18n.test.no_data_logged }}
        </p>
      </template>
    </template>
  </div>
</template>

<script>
import Result from "./Result";
import { BASE_URL } from "../../constants";

export default {
  components: {
    Result,
  },
  props: {
    test: Object,
    report: Object,
    isPending: Boolean,
    type: String,
  },
  computed: {
    hasHtmlReport() {
      return this.type.startsWith("lighthouse");
    },
    htmlReportUrl() {
      return `${BASE_URL}/test/${this.test.id}/report/html`;
    },
  },
};
</script>

<style scoped>
iframe {
  border: none;
  width: 100%;
  height: 600px;
}

.Results-result {
  margin-left: var(--spacing-24);
}

.Results-noData {
  font: var(--typo-headline-12);
  line-height: var(--typo-headline-18-line-height);
  max-width: 65ch;
  margin-bottom: 0;
  color: var(--color-decoration-Grey80);
}
</style>
