














































import Vue from "vue";
import { RouteNames } from "@/router/router";
import LogoutIcon from "@/assets/icons/log-out.svg";
import PersonIcon from "@/assets/icons/person.svg";
import QuestionMarkIcon from "@/assets/icons/question-mark.svg";

export default Vue.extend({
  components: {
    LogoutIcon,
    PersonIcon,
    QuestionMarkIcon,
  },
  computed: {
    userProfileRoute() {
      return { name: RouteNames.UserProfile };
    },
  },
  methods: {
    startTutorial() {
      this.$store.commit("users/setDoingTutorial", true);
      if (this.$route.name !== RouteNames.CategoriesHome) {
        this.$router.push({
          name: RouteNames.CategoriesHome,
        });
      }
    },
  },
});
