import { Relationship, User, FieldConfig } from "@/interfaces";
import { workerConfigToAdvancedConfig } from "@/lib/utils";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      defaultIssuePriority: "",
      defaultIssuePriorityOptions: {},
      defaultIssueType: "",
      defaultIssueTypeOptions: {},
      enabledTests: {},
      enabledTestsOptions: {},
      jiraAccount: null as Relationship | null,
      jiraAccountId: "",
      jiraAccountOptions: {},
      jiraProjectId: "",
      jiraProjectOptions: {},
      projectAuthPassword: "",
      projectAuthPasswordOptions: {},
      projectAuthUsername: "",
      projectAuthUsernameOptions: {},
      usersId: [] as string[],
      usersIdOptions: {},
      stakeholderUserId: "",
      stakeholderUserOptions: {},
      advancedConfig: {
        disableProxy: false,
        sitemapCrawlLimit: 0,
        enableNoindexNofollowPages: false,
        disablePageLoading: false,
      },
      advancedConfigOptions: {},
    };
  },
  methods: {
    ...mapActions("categories", ["setOverriddenFields"]),

    setFormMembers(element_id: string, members: User[]): void {
      const id = members?.[element_id]?.id;
      Vue.set(this.usersIdOptions, id, members?.[element_id]?.option);

      if (id) {
        this.usersId.push(id);
      }
    },

    setUserFromMembers(element_id: string, members: User[]): User {
      if (members?.[element_id]?.option?.uuid == this.$route.params.id) {
        return members?.[element_id]?.option;
      }
      return {} as User;
    },

    setEditForm(
      inherited_project_config,
      project_data,
      worker_config = null as FieldConfig["worker_config"] | null
    ) {
      const project_config =
        inherited_project_config.entity_config?.project || {};
      if (
        Object.keys(project_config || {}).length !== 0 ||
        Object.keys(project_data || {}).length !== 0
      ) {
        const projectAuthUsername = project_data?.field_auth_username?.value;
        this.projectAuthUsername =
          projectAuthUsername ||
          project_config.field_auth_username?.value ||
          "";
        this.projectAuthUsernameOptions =
          project_config.field_auth_username?.option || "";
        this.setOverriddenFields({
          id: "auth-username",
          data: {
            lock: Boolean(!projectAuthUsername || projectAuthUsername === ""),
          },
        });

        const projectAuthPassword = project_data?.field_auth_password?.value;
        this.projectAuthPassword =
          projectAuthPassword ||
          project_config.field_auth_password?.value ||
          "";
        this.projectAuthPasswordOptions =
          project_config.field_auth_password?.option || {};
        this.setOverriddenFields({
          id: "auth-password",
          data: {
            lock: Boolean(!projectAuthPassword || projectAuthPassword === ""),
          },
        });

        const jiraProjectId = project_data?.field_jira_project_key?.value;
        this.jiraProjectId =
          jiraProjectId || project_config.field_jira_project_key?.value || "";
        this.jiraProjectOptions = project_config.field_jira_project_key?.option;
        this.setOverriddenFields({
          id: "jira_project",
          data: {
            lock: Boolean(!jiraProjectId || jiraProjectId === ""),
          },
        });

        this.jiraAccount = {
          data: {
            id:
              project_data?.jira_account?.id || project_config.jira_account?.id,
            type: "jira_account--jira_account",
          },
        };

        const jiraAccountId = project_data.jira_account?.id;
        this.jiraAccountId =
          jiraAccountId || project_config.jira_account?.id || "";
        this.jiraAccountOptions = project_config.jira_account?.option;
        this.setOverriddenFields({
          id: "jira_account",
          data: {
            lock: Boolean(!jiraAccountId || jiraAccountId === ""),
          },
        });

        const defaultIssuePriority =
          project_data.field_jira_tickets_priority?.value;
        this.defaultIssuePriority =
          defaultIssuePriority ||
          project_config.field_jira_tickets_priority?.value ||
          "";
        this.defaultIssuePriorityOptions =
          project_config.field_jira_tickets_priority?.option;
        this.setOverriddenFields({
          id: "jira_default_issue_type_priority",
          data: {
            lock: Boolean(!defaultIssuePriority || defaultIssuePriority === ""),
          },
        });

        const defaultIssueType = project_data.field_jira_tickets_type?.value;
        this.defaultIssueType =
          defaultIssueType ||
          project_config.field_jira_tickets_type?.value ||
          "";
        this.defaultIssueTypeOptions =
          project_config.field_jira_tickets_type?.option;
        this.setOverriddenFields({
          id: "jira_default_issue_type",
          data: {
            lock: Boolean(!defaultIssueType || defaultIssueType === ""),
          },
        });

        const inheritedTests = project_config?.enabled_test_types?.tests;
        Object.keys(inheritedTests || {}).forEach((id) => {
          if (id === "option") return;
          this.$set(this.enabledTests, id, inheritedTests[id].value);
          this.enabledTests[id] = inheritedTests[id].value;
          this.enabledTestsOptions[id] = inheritedTests[id].option;
        });
        // Override with project values
        Object.keys(project_data?.enabled_test_types?.value || {}).forEach(
          (id) => {
            const projectEnabledTest =
              project_data?.enabled_test_types.value[id];
            this.$set(this.enabledTests, id, projectEnabledTest);
            this.enabledTests[id] = projectEnabledTest;
            this.enabledTestsOptions[id] = inheritedTests?.[id]?.option || {};
            this.setOverriddenFields({
              id: id,
              data: {
                lock: false,
              },
            });
          }
        );

        const stakeholderUserId = project_data.field_project_stakeholder?.id;
        this.stakeholderUserId =
          stakeholderUserId || project_config.field_project_stakeholder?.id;
        this.stakeholderUserOptions =
          project_config.field_project_stakeholder?.option;
        this.setOverriddenFields({
          id: "stakeholder",
          data: {
            lock: Boolean(!stakeholderUserId || stakeholderUserId === ""),
          },
        });

        if (
          "field_project_users" in project_config ||
          "field_project_users" in project_data
        ) {
          let user = {};

          const inheritedMembers = project_config.field_project_users;
          Object.keys(inheritedMembers || {}).forEach((element_id) => {
            if (element_id === "option") return;
            this.setFormMembers(element_id, inheritedMembers);
            user = this.setUserFromMembers(element_id, inheritedMembers);
          });

          const projectMembers = project_data.field_project_users || {};
          Object.keys(projectMembers).forEach((element_id) => {
            this.setFormMembers(element_id, projectMembers);
            user = this.setUserFromMembers(element_id, projectMembers);
          });

          if (Object.keys(user).length === 0) {
            user = Object.keys(inheritedMembers || {})[
              Object.keys(inheritedMembers || {}).length - 1
            ];
          }

          Vue.set(this.usersIdOptions, "user_select", user);
        }

        const inherited_worker_config =
          inherited_project_config?.worker_config?.workers?.defaults
            ?.worker_config;
        Object.keys(inherited_worker_config || {}).forEach((key) => {
          if (inherited_worker_config[key]?.value) {
            this.$set(
              this.advancedConfigOptions,
              key,
              inherited_worker_config[key]
            );
          }
        });
      }

      const advancedConfig = worker_config?.workers?.defaults?.worker_config;
      if (advancedConfig) {
        const translatedConfig = workerConfigToAdvancedConfig(advancedConfig);
        Object.keys(translatedConfig).forEach((key) => {
          this.$set(this.advancedConfig, key, translatedConfig[key]);

          this.setOverriddenFields({
            id: key,
            data: {
              lock: Boolean(
                !translatedConfig[key] || translatedConfig[key] === ""
              ),
            },
          });
        });
      }
    },
  },
});
