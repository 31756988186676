import { i18n } from "@/i18n";
import { RouteNames } from "@/router/router";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      tutorialButtonLabels: {
        buttonStop: i18n.tutorial.actions.finish,
      },
      tutorialSteps: [
        {
          target: `[data-v-step="test-overview"]`,
          content: i18n.tutorial.test.overview,
          offset: -200,
          params: {
            placement: "bottom",
          },
        },
        {
          target: `[data-v-step="test-summary"]`,
          content: i18n.tutorial.test.summary,
          offset: -200,
        },
        {
          target: `[data-v-step="test-summary"]`,
          content: i18n.tutorial.test.test_score,
          offset: -200,
        },
        {
          target: `[data-v-step="test-test_results"]`,
          content: i18n.tutorial.test.test_results,
          offset: -200,
        },
        {
          target: `[data-v-step="test-test_results"]`,
          content: i18n.tutorial.test.test_results_details,
          offset: -200,
        },
        {
          target: `[data-v-step="test-report_function"]`,
          content: i18n.tutorial.test.report_function,
          offset: -200,
        },
        {
          target: `[data-v-step="test-jira"]`,
          content: i18n.tutorial.test.jira,
          offset: -200,
        },
        {
          target: `[data-v-step="test-automated_frequency"]`,
          content: i18n.tutorial.test.automated_frequency,
          offset: -200,
        },
        {
          target: `[data-v-step="test-overview"]`,
          content: i18n.tutorial.test.congrats,
          offset: -200,
        },
      ],
    };
  },

  computed: {
    ...mapState("users", ["doingTutorial"]),

    tutorialName: () => "tour",

    tutorialCallbacks() {
      return {
        onStart: () => this.$store.commit("users/setDoingTutorial", true),
        onStop: () => {
          if (!this.$tours.tour.isLast) {
            this.resetTutorialState();
          }
        },
        onSkip: () => {
          this.resetTutorialState();
        },
        onFinish: () => {
          this.resetTutorialState();
        },
      };
    },
  },

  methods: {
    ...mapActions("projects", ["getProjects", "getProjectsList"]),

    startTutorial() {
      if (this.doingTutorial) {
        setTimeout(() => {
          this.$tours[this.tutorialName].start();
        }, 100);
      }
    },

    resetTutorialState() {
      this.$store.commit("users/setDoingTutorial", false);
      this.getProjects();
      this.getProjectsList({
        categoryId: "",
        workflow: 1,
      });

      if (this.$route.name !== RouteNames.ProjectsHome) {
        this.$router.push({
          name: RouteNames.ProjectsHome,
        });
      }
    },
  },

  watch: {
    doingTutorial() {
      this.startTutorial();
    },
  },

  created() {
    if (this.$route.params.projectId === "tutorial") {
      this.$store.commit("users/setDoingTutorial", true);
    }
  },

  mounted() {
    this.startTutorial();
  },
});
