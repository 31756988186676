











import Vue from "vue";
import UiCard from "@/components/ui/Card.vue";
import TestOverviewRow from "./TestOverviewRow.vue";

export default Vue.extend({
  components: {
    UiCard,
    TestOverviewRow,
  },
  props: {
    tests: {
      type: Array,
      required: true,
    },
  },
});
