export interface SuiteScore {
  name?: string;
  color?: string;
  progress: number;
  tendency: number;
}

export interface Test {
  max_score: number;
  score: number;
}

export interface TestSuiteResult extends Test {
  value: string;
}

export interface TestSnapshot extends Test {
  id: string;
  field_testsuite_results: TestSuiteResult[];
  project: {
    id: string;
  };
  result_type: {
    id: string;
  };
  created: string;
}

export enum TestCategory {
  Optional = "optional",
  Recommended = "recommended",
  Experimental = "experimental",
  Mandatory = "mandatory",
}

export interface TestType {
  category: TestCategory;
  description: string;
  documentation_url: string;
  id: string;
  label: string;
  result: {
    id: string;
    name: string;
    status: string; // completed, ... (?)
    result: string; // CRITICAL, ... (?)
    max_score: number;
    score: number;
    project: {
      id: string;
    };
  };
  short_description: string;
  testsuite_values: string[];
}

export interface TestTypeOverview {
  alerts: {
    messages: string[];
  };
  calculated_finish_time: number;
  category: TestSuite;
  latest_test_run: number;
  next_test_run: number;
  name: string;
  score: number;
  score_trend: number;
  status: TestStatus;
  uuid: string;
  project_uuid: string;
  test_type_id: string;
}

export interface SuiteRequest {
  machinename: string;
  name: string;
}

export interface Suite {
  machinename: TestSuite;
  name: string;
  tests: TestType[];
}

export enum TestSuite {
  Seo = "seo",
  Performance = "performance",
  Compliance = "compliance",
}

export interface UserClient {
  id: string;
  name: string;
}

export interface BasicUser {
  uuid: string;
  name: string;
  avatar: string;
}

export interface User {
  id: string;
  avatar?: string;
  field_user_avatar?: {
    uri?: {
      url?: string;
    };
  };
  display_name?: string;
  name?: string;
  mail?: string;
  field_user_firstname?: string;
  field_user_surname?: string;
  field_user_title?: string;
  field_user_jira_reference: string;
  field_client: UserClient[];
  field_user_agency: { id: string }[];
  links: {
    self: {
      href: string;
    };
  };
}

export interface CategorizedTests {
  seo: number[];
  performance: number[];
  compliance: number[];
  total: number[];
  date: string[];
}

export interface JsonSchemaObject {
  $schema: string;
  type: string;
  title: string;
  properties: Record<string, object>;
  additionalProperties: boolean;
}

export interface RawTestType {
  category: TestCategory;
  description: string;
  documentation_url: string;
  id: string;
  label: string;
  responsibility_values: string;
  short_description: string;
  testsuite_values: string[];
  frequency: string;
  is_disabled: boolean;
  is_disabled_message: string;
  config_options?: JsonSchemaObject;
}

export enum TestStatus {
  Completed = "completed",
  Pending = "pending",
  Error = "error",
}

export interface RawTest extends Test {
  id: string;
  created: string;
  documentation_url: string;
  name: string;
  field_responsibility: string;
  field_testsuites: string[];
  processed?: string;
  langcode: string;
  result: string; // possible enum "CRITICAL", "SUCCESS"
  project: {
    id: string;
    type: string;
  };
  status: TestStatus;
  type: string;
  website_tested_count: number;
  log_grouped_by_status_code: string; // JSON
  html_report_url: Record<string, string> | null;
}

export interface TestMetaError {
  full_message: string;
  message: string;
  small_message: string;
  status_code: string;
}

export interface TestMeta extends Test {
  critical_error: null | TestMetaError[];
  latest_status: TestStatus;
  latest_status_message: string;
  date: {
    changed: number;
    created: number;
    expected: null | number;
    last_enqueued: null | number;
    next_enqueue: null | number;
  };
}

export interface JiraTicket {
  jira_issue_id: number;
  name: string;
  jira_issue_key: string;
  jira_issue_status: string;
  jira_issue_url: string;
  created: string; // ISO 8601
  test_type: string;
}

export interface JiraAccountRequest {
  id: string;
  type: string;
  attributes: {
    id: string;
    langcode: string;
    status: string;
    label: string;
    key: string;
  };
  links: {
    self: {
      href: string;
    };
  };
}

export interface JiraAccount {
  id: string;
  type: string;
  langcode: string;
  status: string;
  label: string;
  key: string;
  links: {
    self: {
      href: string;
    };
  };
}

export interface JiraProject {
  id: string;
  issueTypes: string[];
  key: string;
  name: string;
}

export interface ProjectMeta {
  [test_type_key: string]: TestMeta & { notifications: null | string };
}

export interface FieldConfig {
  entity_config: {
    type: string;
    project: {
      type: string;
      enabled_test_types: {
        type: string;
        value: {
          [test_type: string]: boolean;
        };
      };
      field_jira_tickets_priority: {
        type: string;
        value: string;
      };
      field_jira_tickets_type: {
        type: string;
        value: string;
      };
      field_project_stakeholder: {
        content_type: string;
        id: string;
        type: string;
      };
      field_auth_password: {
        type: string;
        id: string;
      };
      field_jira_project_key: {
        type: string;
        id: string;
      };
    };
  };
  worker_config?: {
    type: string;
    test_types?: {
      type: string;
      [testType: string]:
        | string
        | {
            type: string;
            worker_config?: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [testType: string]: any;
            };
          };
    };
    workers?: {
      type: string;
      defaults: {
        type: string;
        worker_config?: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [testType: string]: any;
        };
      };
    };
  };
}

export interface FieldImage {
  changed: string;
  created: string;
  filemime: string;
  filename: string;
  filesize: number;
  id: string;
  status: boolean;
  type: string;
  uri: {
    url: string;
    value: string;
  };
}

export interface ProjectDetails {
  access: string[];
  alerts: Notification[];
  changed: string;
  created: string;
  enabled_test_types: string; // json string
  field_auth_password: string | null;
  field_auth_username: string | null;
  field_environment: string | null;
  field_jira_project_key: string | null;
  field_jira_tickets_priority: string | null;
  field_jira_tickets_type: string | null;
  field_project_description: string | null;
  field_project_rerun_frequency: string | null;
  field_project_sitemap_url: string | null;
  field_project_stakeholder: User | null;
  field_project_tested_url: string | null;
  field_project_users: User[];
  id: string;
  meta: string; // json string
  meta_parsed: ProjectMeta;
  name: string;
  type: string;
  status: boolean;
  field_image: FieldImage;
  field_config: FieldConfig;
  field_menu: {
    id: string;
    type: string;
  };
  field_project_client: {
    id: string;
    type: string;
  };
  field_project_jira_account: {
    id: string;
    type: string;
  };
  user_id: {
    id: string;
    type: string;
  };
  project_type: {
    id: string;
    type: string;
  };
  workflow: string;
}

export interface ProjectTeaserRow {
  project: ProjectDetails;
  score: number;
  trend: number;
  lastTestRunDate: string | null;
  isCritical: boolean;
}

export interface Documentation {
  id: string;
  label: string;
  short_description: string;
  description: string;
  category: TestCategory;
  documentation_benefits: string; // html format
  documentation_developers: string; // html format
  documentation_editors: string; // html format
  documentation_error_cores: string; // html format
  documentation_example_code: string; // html format
  documentation_references: string; // html format
  documentation_url: string;
  testsuite_values: string[];
}

export interface ParsedReport {
  label?: string;
  items: ParsedReport[];
  count?: number;
}

export interface Notification {
  full_message: string;
  message: string;
  small_message: string;
  status_code: string;
  test_type?: string;
  test_name?: string;
}

export interface TooltipConfiguration {
  content: string;
  trigger?: string;
  classes?: string;
  delay?:
    | number
    | {
        show?: number;
        hide?: number;
      };
}

export interface Relationship {
  data: {
    id: string;
    type: string;
  } | null;
}

export interface EditedProject {
  id: string;
  type: string;
  attributes: {
    changed: string;
    created: string;
    enabled_test_types: string; // json string
    field_auth_password: string | null;
    field_auth_username: string | null;
    field_config: FieldConfig;
    field_environment: string | null;
    field_jira_project_key: string | null;
    field_jira_tickets_priority: string | null;
    field_jira_tickets_type: string | null;
    field_menu: Relationship;
    field_project_description: string | null;
    field_project_rerun_frequency: string | null;
    field_project_sitemap_url: string | null;
    field_project_stakeholder: User | null;
    field_project_tested_url: string | null;
    field_project_users: User[];
    last_retested: string;
    meta: string; // json string
    project_type: Relationship;
    status: boolean;
  };
  relationships: {
    field_menu: Relationship;
  };
}

export interface EditedCategory {
  id: string;
  type: string;
  attributes: {
    changed: string;
    description: string;
    field_config: FieldConfig | null;
    name: string;
    tree_config: FieldConfig | null;
    parent: Relationship[];
  };
}

export enum ResultLevels {
  Success = 100,
  Pass = 95,
  Warning = 85,
  Fail = 60,
  Critical = 40,
}

export enum RelationshipType {
  User = "user--user",
}
export interface RelationshipRequest<T extends RelationshipType> {
  id: string;
  type: T;
}

export interface ProjectRequest {
  data: {
    id: string | null;
    type: "project--default_project";
    attributes: {
      name: string;
      status: boolean;
      enabled_test_types: string;
      field_project_description: string;
      field_project_tested_url: string;
      field_jira_tickets_type: string;
      field_jira_tickets_priority: string;
      field_jira_project_key: string;
      field_project_sitemap_url: string;
      field_project_rerun_frequency: string;
      field_auth_username: string;
      field_auth_password: string;
      field_config: object;
    };
    relationships: {
      field_project_stakeholder: {
        data: {
          type: string;
          id: string;
        };
        links: {
          self: {
            href: string;
          };
        };
      };
      field_project_users: {
        data: RelationshipRequest<RelationshipType.User>[];
      };
      field_menu?: {
        data: {
          id: string;
          type: "taxonomy_term--menu";
        };
      };
      jira_account?: {
        data: JiraAccountRequest;
        links: {
          self: {
            href: string;
          };
        };
      };
    };
  };
}

export interface SubRequest {
  requestId: string;
  uri: string;
  action: string;
  headers: {
    Accept: string;
    ContentType: string;
    Authorization?: string;
  };
}

export interface ProjectEntry {
  id: string;
  label: string;
  type: string;
  children: ProjectEntry[];
}

export enum EntityType {
  Project = "project",
  Category = "taxonomy_term",
}

export interface AdvancedConfiguration {
  disableProxy: boolean;
  sitemapCrawlLimit: string | number;
  enableNoindexNofollowPages: boolean;
  disablePageLoading: boolean;
  urlGetter: any;
  urlGetterOptions: UrlGetterOptions;
}

export interface UrlGetterOptions {
  urls: Array<string>;
}

export interface FullWidthAlert {
  message: string;
  type: string;
  forIds: string;
  id?: string;
  bgColor?: string;
  textColor?: string;
}
