import { render, staticRenderFns } from "./ProjectFormFooter.vue?vue&type=template&id=6d844944&scoped=true&"
import script from "./ProjectFormFooter.vue?vue&type=script&lang=ts&"
export * from "./ProjectFormFooter.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectFormFooter.vue?vue&type=style&index=0&id=6d844944&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d844944",
  null
  
)

export default component.exports