























import Vue from "vue";
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: "h2",
    },
    header: {
      type: Boolean,
      default: true,
    },
  },
});
