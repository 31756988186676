
























import Vue from "vue";
import Btn from "@/components/ui/Btn.vue";

export default Vue.extend({
  components: {
    Btn,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: true,
    },
    confirmString: {
      type: String,
      default: "",
    },
  },
});
