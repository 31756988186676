

































import Vue from "vue";
import Btn from "../ui/Btn.vue";
import CloseIcon from "@/assets/icons/close.svg";
import TextField from "./OutlinedTextField.vue";

export default Vue.extend({
  components: {
    Btn,
    TextField,
    CloseIcon,
  },
  props: ["field"],
});
