














































































































































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import {
  CategorizedTests,
  Documentation,
  JiraTicket as IJiraTicket,
  TestMeta as ITestMeta,
  RawTest,
  RawTestType,
  SuiteScore,
  TestStatus,
} from "@/interfaces";
import UiCard from "./ui/Card.vue";
import Btn from "./ui/Btn.vue";
import Capsule from "./ui/Capsule.vue";
import CategoryScores from "./scores/CategoryScores.vue";
import QualityScoresChart from "./charts/QualityScoresChart.vue";
import JiraTicket from "./ui/JiraTicket.vue";
import TestImportance from "./TestImportance.vue";
import TestHowToFix from "./TestHowToFix.vue";
import NextRunDate from "./NextRunDate.vue";
import {
  formatTimestamp,
  sortByCreatedDate,
  testScoreInPercent,
  testScoreTendency,
} from "@/lib/utils";
import Alert from "./ui/Alert.vue";

export default Vue.extend({
  components: {
    Btn,
    UiCard,
    Capsule,
    CategoryScores,
    QualityScoresChart,
    JiraTicket,
    TestImportance,
    TestHowToFix,
    NextRunDate,
    Alert,
  },
  props: {
    test: {
      type: Object as PropType<RawTest>,
      required: true,
    },
    testType: {
      type: Object as PropType<RawTestType>,
      required: true,
    },
    testData: {
      type: Array as PropType<RawTest[]>,
      default: () => [],
    },
    tickets: {
      type: Array as PropType<IJiraTicket[]>,
      default: () => [],
    },
    testMeta: {
      type: Object as PropType<ITestMeta | null>,
      default: null,
    },
  },
  computed: {
    ...mapState("tests", ["testTypeDocumentation"]),

    testSuites(): string[] {
      return this.test.field_testsuites;
    },

    testScore(): SuiteScore {
      return {
        progress: testScoreInPercent(this.test),
        tendency: testScoreTendency(this.test, this.previousTest),
      };
    },

    previousTest(): RawTest {
      const indexTest = this.testData.indexOf(this.test);
      return indexTest >= 0 ? this.testData[indexTest + 1] : ({} as RawTest);
    },

    testSuite(): string {
      return this.testSuites.length ? this.testSuites[0] : "";
    },

    lastRunDate(): string {
      if (this.test.created) {
        return formatTimestamp(this.test.created);
      }
      return "N/A";
    },

    totalPages(): string {
      return this.test.website_tested_count
        ? String(this.test.website_tested_count)
        : "";
    },

    categorizedTests(): CategorizedTests {
      if (!this.testType) return {} as CategorizedTests;

      return this.testData.reduce(
        (datasets, test: RawTest): CategorizedTests => {
          datasets.date.push(test.created);
          datasets[this.testSuite].push(
            Math.round((test.score / test.max_score) * 100)
          );
          return datasets;
        },
        {
          seo: [] as number[],
          performance: [] as number[],
          compliance: [] as number[],
          total: [] as number[],
          date: [] as string[],
        }
      );
    },

    documentation(): Documentation | undefined {
      return this.testTypeDocumentation[this.testType.id];
    },

    testTypeImportance(): string {
      return this.documentation
        ? this.documentation.documentation_benefits
        : "";
    },

    developerDocumentation(): string {
      return this.documentation
        ? this.documentation.documentation_developers
        : "";
    },

    editorDocumentation(): string {
      return this.documentation ? this.documentation.documentation_editors : "";
    },

    sortedTickets(): IJiraTicket[] {
      return sortByCreatedDate(this.tickets);
    },

    isPending(): boolean {
      return this.testMeta?.latest_status === TestStatus.Pending;
    },
  },
});
