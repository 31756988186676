




















import { FullWidthAlert } from "@/interfaces";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    alerts: {
      type: Array as PropType<FullWidthAlert[]>,
      required: true,
    },
    reverseAnimationDelay: {
      type: Number,
      default: 3000,
    },
  },

  methods: {
    customStyle(alert) {
      return `
      --bg-color: var(--color-decoration-${alert.bgColor || "Grey80"});
      --text-color: var(--color-decoration-${alert.textColor || "Grey15"});
      `;
    },
  },
});
