






















































































































import Vue, { PropType } from "vue";
import Checkbox from "../form/Checkbox.vue";
import TestName from "./TestName.vue";
import TestProgress from "./TestProgress.vue";
import FlipIcon from "../../assets/icons/flip.svg";
import SpinnerIcon from "../../assets/icons/spinner.svg";
import AlertTriangle from "../../assets/icons/alert-triangle.svg";
import Spinner from "../../assets/icons/spinner.svg";
import Btn from "../ui/Btn.vue";
import {
  JiraTicket as IJiraTicket,
  TestMeta as ITestMeta,
  TestStatus,
  TestTypeOverview,
  TooltipConfiguration,
} from "../../interfaces";
import {
  colorNameForScore,
  formatTimestamp,
  sortByCreatedDate,
} from "../../lib/utils";
import { THEME } from "@/theme";
import { Location } from "vue-router";
import JiraTicket from "../ui/JiraTicket.vue";
import NextRunDate from "../NextRunDate.vue";
import { Fragment } from "vue-fragment";
import { RouteNames } from "@/router/router";

export default Vue.extend({
  components: {
    Fragment,
    Checkbox,
    TestName,
    TestProgress,
    Spinner,
    Btn,
    FlipIcon,
    SpinnerIcon,
    AlertTriangle,
    JiraTicket,
    NextRunDate,
  },
  props: {
    test: {
      type: Object as PropType<TestTypeOverview>,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    testTypeStatus: {
      type: String as PropType<TestStatus>,
      default: TestStatus.Completed,
    },
    testMeta: {
      type: Object as PropType<ITestMeta | null>,
      default: null,
    },
    hasJiraProject: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: null,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastRunDate(): string {
      if (this.test.latest_test_run) {
        return formatTimestamp(this.test.latest_test_run);
      }
      return "N/A";
    },

    runFrequency(): string {
      return "Daily";
    },

    progress(): number {
      return this.test.score;
    },

    classColorRules(): string {
      return colorNameForScore(this.progress);
    },

    route(): Location {
      const testsuite = this.test.category;

      return {
        name: RouteNames.TestDetails,
        params: {
          projectId: this.test.project_uuid,
          testsuite,
          type: this.test.test_type_id,
          testId: this.test.uuid,
        },
      };
    },
    jiraIssueForTest(): IJiraTicket | null {
      const issues = sortByCreatedDate<IJiraTicket>(
        this.$store.state.jira.issues[this.test.project_uuid] || []
      );

      return issues.length
        ? issues.find(
            ({ test_type }) => test_type === this.test.test_type_id
          ) || null
        : null;
    },

    isRunning(): boolean {
      return (
        this.test.status === TestStatus.Pending ||
        this.testTypeStatus === TestStatus.Pending
      );
    },

    jiraButtonIsDisabled(): boolean {
      return this.isRunning || this.test.status !== TestStatus.Error;
    },

    status(): string | null {
      // This is meant to render a spinner if the test is running
      // so if the test is pending, return it, otherwise use the test
      // meta to render the latest status
      if (this.test.status === TestStatus.Pending) {
        return this.test.status;
      }
      return this.testMeta ? this.testMeta.latest_status : null;
    },

    statusMessage(): string {
      return this.testMeta ? this.testMeta.latest_status_message : "";
    },

    tooltipConfig(): TooltipConfiguration {
      return {
        content: this.status !== TestStatus.Completed ? this.statusMessage : "",
      };
    },
  },
  methods: {
    colorRules(progress): string {
      if (progress < 60) {
        return THEME.critical;
      } else if (progress < 90) {
        return THEME.warning;
      }
      return THEME.perfect;
    },
    handleActionClick(type: string) {
      this.$emit("clear-selection");
      this.$emit("test-selected", {
        test: this.test,
        enabled: true,
      });
      this.$emit("open-dialog", type);
    },
    handleRowClick() {
      this.$router.push(this.route);
    },
  },
});
