













import Vue from "vue";
import ProgressBar from "../charts/ChartProgressLine.vue";

export default Vue.extend({
  components: {
    ProgressBar,
  },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    colorRules: {
      type: Function,
      default: undefined,
    },
    backgroundColor: {
      type: String,
      default: "Grey15",
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
});
