import Vue from "vue";
import {
  fetchGetterOptions,
  fetchJiraIssuesForProject,
  fetchLayeredConfig,
} from "@/lib/api";

export default {
  namespaced: true,
  state: {
    overriddenFields: {},
    layeredConfig: {},
    currentCategoryId: "",
    getterOptions: {},
  },
  getters: {
    getOverriddenFields: (state) => {
      return state.overriddenFields;
    },
    getOverriddenField: (state) => (id) => {
      return state.overriddenFields[id];
    },
    getOverriddenFieldLock: (state) => (id) => {
      if (id in state.overriddenFields) {
        return state.overriddenFields[id].lock;
      }
      return false;
    },
    getInheritedValue: (state, _, rootState) => (id) => {
      const projectConfig = state.layeredConfig?.entity_config?.project;
      const workerConfig =
        state.layeredConfig?.worker_config?.workers?.defaults?.worker_config;
      const mappedIds = {
        "auth-username": () => projectConfig?.field_auth_username?.value,
        "auth-password": () => projectConfig?.field_auth_password?.value,
        jira_account: () => {
          const jiraAccountId = projectConfig?.jira_account?.id;
          return rootState.jira?.accounts?.find(
            ({ id }) => id === jiraAccountId
          );
        },
        jira_project: () => {
          const jiraAccountId = projectConfig?.jira_account?.id;
          const jiraProjectKey = projectConfig?.field_jira_project_key?.value;
          return rootState.jira?.jiraProjects?.[jiraAccountId]?.find(
            ({ key }) => key === jiraProjectKey
          );
        },
        jira_default_issue_type: () =>
          projectConfig?.field_jira_tickets_type?.value,
        jira_default_issue_type_priority: () =>
          projectConfig?.field_jira_tickets_priority?.value,
        stakeholder: () => {
          const stakeholderId = projectConfig?.field_project_stakeholder?.id;
          return rootState.users?.users?.find(({ id }) => id === stakeholderId);
        },
        user_select: () => {
          const usersIds = Object.values(
            projectConfig?.field_project_users
          ).map(({ id }) => id);
          return rootState.users?.users?.filter(({ id }) =>
            usersIds.includes(id)
          );
        },
        disable_proxy: () => workerConfig?.disable_proxy?.value,
        url_getter: () => workerConfig?.url_getter?.value,
        url_getter_options: () => workerConfig?.url_getter_options?.value,
        sitemap_crawl_limit: () => workerConfig?.sitemap_crawl_limit?.value,
        enable_noindex_nofollow_pages: () =>
          workerConfig?.enable_noindex_nofollow_pages?.value,
      };

      if (!(id in mappedIds)) {
        return state.layeredConfig?.entity_config?.project?.enabled_test_types
          ?.tests?.[id]?.value;
      }

      return mappedIds[id]();
    },
  },
  actions: {
    getGetterOptionsForProject({ commit }) {
      return fetchGetterOptions()
        .then((result) => {
          commit("setGetterOptions", result.getter_configuration?.options);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setOverriddenFields({ commit }, payload) {
      commit("updateOverrideFields", payload);
    },
    async getLayeredConfig({ commit }, payload) {
      await fetchLayeredConfig(payload.type_base, payload.id_base)
        .then((response) => {
          commit("setLayeredConfig", response);
        })
        .catch((error) => {
          console.log("getLayeredConfig", error);
        });
    },
    setCurrentCategory({ commit }, categoryId) {
      commit("setCurrentCategory", categoryId);
    },
  },

  mutations: {
    updateOverrideFields(state, payload) {
      Vue.set(state.overriddenFields, payload.id, payload.data);
    },
    setLayeredConfig(state, data) {
      state.layeredConfig = data;
    },
    setCurrentCategory(state, id) {
      state.currentCategoryId = id;
    },
    setGetterOptions(state, data) {
      state.getterOptions = data;
    },
  },
};
