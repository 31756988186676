






























import Vue from "vue";
import Btn from "@/components/ui/Btn.vue";

export default Vue.extend({
  components: {
    Btn,
  },

  data() {
    return {
      subscribe_url: "https://stripe.com",
      contact_url: "https://website-standards.io",
    };
  },
});
