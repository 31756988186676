








































import Vue from "vue";
import ArrowDown from "../../../assets/icons/arrow-down.svg";

export default Vue.extend({
  components: {
    ArrowDown,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      required: true,
    },
    accent: {
      type: String,
      default: "perfect",
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.expandable,
      targetId: crypto.randomUUID(),
    };
  },
  computed: {
    classes(): string {
      return `AccordionItem--${this.accent}`;
    },
  },
  methods: {
    handleClick() {
      if (!this.expandable) {
        this.isExpanded = !this.isExpanded;
      }
    },
  },
});
