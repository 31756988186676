


























import Vue from "vue";
import ChevronRightIcon from "@/assets/icons/chevron-right.svg";

export default Vue.extend({
  components: {
    ChevronRightIcon,
  },
  props: {
    tag: {
      type: String,
      default: "h3",
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      targetId: crypto.randomUUID(),
    };
  },
});
