import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("categories", ["setOverriddenFields"]),
    unlockOverrideStatus(field) {
      if (field) {
        this.setOverriddenFields({
          id: field,
          data: { lock: false },
        });
      }
    },
  },
};
