


























import { User } from "@/interfaces";
import Vue, { PropType } from "vue";
import ProjectMember from "./ProjectMember.vue";

export default Vue.extend({
  components: {
    ProjectMember,
  },
  props: {
    stakeholder: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    showClient: {
      type: Boolean,
      default: true,
    },
  },
});
