import JsonApiParse from "jsonapi-parse";

import { uniqBy, camelCase, filter, sortBy } from "lodash";

import {
  getPasswordGrant,
  setAuthInfo,
  postForgotPassword,
  postPasswordReset,
} from "../../../lib/auth";

import {
  fetchUser,
  fetchUsers,
  fetchUsersWithPermission,
  fetchUserInformation,
  fetchUsersForEntity,
} from "../../../lib/api";

import router from "../../../router/router";
import { i18n } from "@/i18n";

export default {
  namespaced: true,
  state: {
    userIsAuthenticated: false,
    loginErrorMsg: false,
    users: [],
    usersWithPermission: {},
    loaders: [],
    error: false,
    doingTutorial: false,
  },

  actions: {
    authenticateUser({ commit }, { name, pass, redirect = null }) {
      commit("addLoader", "auth");
      getPasswordGrant(name, pass)
        .then((data) => {
          setAuthInfo(data);
          commit("setAuthStatus", true);
          if (redirect) {
            router.push(redirect);
          }
        })
        .catch((error) => {
          commit("setAuthStatus", false);
          commit("setLoginError", error);
        })
        .finally(() => {
          commit("removeLoader", "auth");
        });
    },

    postForgotPassword({ commit }, { email }) {
      commit("addLoader", "auth");
      return postForgotPassword(email)
        .catch((error) => {
          commit("setAuthStatus", false);
          commit("setLoginError", error);
        })
        .finally(() => {
          commit("removeLoader", "auth");
        });
    },

    postResetPassword({ commit }, { name, temp_pass, new_pass }) {
      commit("addLoader", "auth");
      return postPasswordReset({ name, temp_pass, new_pass })
        .catch((error) => {
          commit("setAuthStatus", false);
          commit("setLoginError", error);
        })
        .finally(() => {
          commit("removeLoader", "auth");
        });
    },

    getUserInformation({ commit }) {
      return fetchUserInformation()
        .then((response) => {
          commit("setAuthStatus", response.logged_in);
        })
        .catch((response) => {
          if (response.status >= 500) {
            return Promise.reject(response);
          }
          commit("setAuthStatus", false);
        });
    },

    getUser({ commit }, name) {
      fetchUser(name)
        .then((response) => {
          commit("setUsers", JsonApiParse.parse(response).data);
          commit("removeLoader", "user");
        })
        .catch((error) => {
          commit("setError", error);
          commit("removeLoader", "user");
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    getUsersForEntity({ commit }, { entityType, entityId }) {
      commit("addLoader", "users");
      return fetchUsersForEntity(entityType, entityId)
        .then((response) => {
          commit("setUsers", JsonApiParse.parse(response).data);
          commit("removeLoader", "users");
        })
        .catch((error) => {
          commit("setError", error);
          commit("removeLoader", "users");
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },

    getUsers({ commit }) {
      commit("addLoader", "users");
      return fetchUsers()
        .then((response) => {
          commit("setUsers", JsonApiParse.parse(response).data);
          commit("removeLoader", "users");
        })
        .catch((error) => {
          commit("setError", error);
          commit("removeLoader", "users");
        });
    },

    getUsersWithPermission({ commit }, permission) {
      commit("addLoader", "usersWithPermission");
      return fetchUsersWithPermission(permission)
        .then((response) => {
          commit("setUsersWithPermission", { newUsers: response, permission });
          commit("removeLoader", "usersWithPermission");
        })
        .catch((error) => {
          commit("setError", error);
          commit("removeLoader", "usersWithPermission");
          // showToastedMessage('Something went wrong, please try again later.', 'error');
        });
    },
  },

  mutations: {
    setLoginError: (state, { message }) => {
      state.loginErrorMsg = i18n.feedback.loginError;
      if (message) {
        state.loginErrorMsg = message;
      }
    },

    resetLoginError: (state) => {
      state.loginErrorMsg = null;
    },

    addLoader(state, id) {
      state.loaders = [...state.loaders, id];
    },

    setAuthStatus(state, authStatus) {
      state.userIsAuthenticated = authStatus;
    },

    removeLoader(state, id) {
      state.loaders = state.loaders.filter((item) => item !== id);
    },

    setUsers(state, newUsers) {
      state.users = sortBy(uniqBy([...state.users, ...newUsers], "id"), [
        (user) => user.display_name.toLowerCase(),
      ]);
    },

    setUsersWithPermission(state, { newUsers, permission }) {
      state.usersWithPermission = Object.assign({}, state.usersWithPermission, {
        [camelCase(permission)]: newUsers,
      });
    },

    setError(state, error) {
      state.error = error;
    },

    setDoingTutorial(state, doingTutorial) {
      state.doingTutorial = doingTutorial;
    },
  },

  getters: {
    getIsLoadingById:
      ({ loaders }) =>
      (id) =>
        loaders.some((item) => item === id),

    getUserByName:
      ({ users }) =>
      (name) =>
        users.find((u) => u.name === name),

    getBusinessAnalystUsers: ({ users, usersWithPermission }) => {
      const IDs = [];

      if (usersWithPermission.beBusinessAnalyst) {
        JsonApiParse.parse(usersWithPermission.beBusinessAnalyst).data.forEach(
          (user) => IDs.push(user.id)
        );
      }

      return filter(users, (u) => IDs.includes(u.id));
    },
  },
};
