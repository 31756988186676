



























import Vue, { PropType } from "vue";
import { SuiteScore, TooltipConfiguration } from "../../interfaces";
import CategoryScores from "./CategoryScores.vue";
import QuestionMarkIcon from "@/assets/icons/question-mark.svg";

export default Vue.extend({
  components: {
    CategoryScores,
    QuestionMarkIcon,
  },
  props: {
    projectScore: {
      type: Object as PropType<SuiteScore>,
      default: () => ({}),
    },
    categoryScores: {
      type: Array as PropType<SuiteScore[]>,
      default: () => [],
    },
  },
  computed: {
    tooltipContent(): TooltipConfiguration {
      return {
        content: `
        The score system measures the quality of the web application in a range from 0 to 100
        with 0 meaning inadequate and 100 meaning excellent.<br/>
        The colored bars indicate in what range the current score is set:<br/>
        <dl class="u-color-dl u-mt-4">
          <dt class="u-bg-Critical u-mr-8 u-mt-4 u-pill"><span class="u-sr-only">red</span></dt>
          <dd>0 to 60</dd>
          <dt class="u-bg-Warning u-mr-8 u-mt-4 u-pill"><span class="u-sr-only">yellow</span></dt>
          <dd>61 to 79</dd>
          <dd />
          <dt class="u-bg-Perfect u-mr-8 u-mt-4 u-pill"><span class="u-sr-only">green</span></dt>
          <dd>80 to 100</dd>
        </dl>`,
      };
    },
  },
});
