<template>
  <div style="width: 100%" class="u-container">
    <h1 class="NewProjectForm-title">
      {{ formTitle }}
    </h1>
    <p class="NewProjectForm-info">
      {{ $i18n.validation.required_explanation }}
    </p>
    <form v-if="!isLoading" ref="form" class="NewProjectForm">
      <project-form-actions
        :form-type="formType"
        :disabled="!valid || isDisabled"
        :submitting="isDisabled"
        :go-back-url="goBackUrl"
        class="NewProjectForm-actions"
        @submit="projectSubmit"
      />

      <project-form-wrapper>
        <project-form-information
          :is-disabled="isDisabled"
          :project-title-input="projectTitle"
          :project-description-input="projectDescription"
          :project-base-url-input="projectBaseUrl"
          :project-base-sitemap-url-input="projectSitemapUrl"
          :project-re-run-frequency-input="reRunFrequency"
          :project-category-input="projectCategory"
          @project-title-event="projectTitle = $event"
          @project-description-event="projectDescription = $event"
          @project-url-event="projectBaseUrl = $event"
          @project-sitemap-url-event="projectSitemapUrl = $event"
          @re-run-frequency="reRunFrequency = $event"
          @category-event="setCategory($event)"
        ></project-form-information>
      </project-form-wrapper>

      <accordion
        class="NewProjectForm-advanced"
        :label="$i18n.project.form.advanced_configuration"
        tag="h2"
      >
        <project-form-wrapper>
          <project-form-auth-information
            :is-disabled="isDisabled"
            :project-auth-username-input="projectAuthUsername"
            :project-auth-username-options="projectAuthUsernameOptions"
            :project-auth-password-input="projectAuthPassword"
            :project-auth-password-options="projectAuthPasswordOptions"
            @project-auth-username-event="projectAuthUsername = $event"
            @project-auth-password-event="projectAuthPassword = $event"
          ></project-form-auth-information>
        </project-form-wrapper>

        <project-form-wrapper>
          <project-form-jira-information
            :is-disabled="isDisabled"
            :jira-account-id-input="jiraAccountId"
            :jira-account-options="jiraAccountOptions"
            :jira-project-id-input="jiraProjectId"
            :jira-project-options="jiraProjectOptions"
            :issue-priority-input="defaultIssuePriority"
            :issue-priority-options="defaultIssuePriorityOptions"
            :issue-type-input="defaultIssueType"
            :issue-type-options="defaultIssueTypeOptions"
            @defaultIssuePriority="defaultIssuePriority = $event"
            @defaultIssueType="defaultIssueType = $event"
            @jiraProject="jiraProject = $event"
            @jiraAccount="jiraAccount = $event"
          ></project-form-jira-information>
        </project-form-wrapper>

        <project-form-wrapper>
          <project-form-advanced-config
            :is-disabled="isDisabled"
            v-model="advancedConfig"
            :options="advancedConfigOptions"
          ></project-form-advanced-config>
        </project-form-wrapper>
      </accordion>

      <project-form-wrapper>
        <project-form-test-information
          :enabled-tests="enabledTests"
          :enabled-tests-options="enabledTestsOptions"
          :worker-config="workerConfigData"
          :current-user-is-stakeholder="currentUserIsStakeholder"
          @test-selection-changed="enabledTests = $event"
          @test-config-input="handleConfigInput"
        ></project-form-test-information>
      </project-form-wrapper>

      <project-form-wrapper>
        <project-form-user-information
          :is-disabled="isDisabled"
          :baseUrl="projectBaseUrl"
          :users-id-input="usersId"
          :users-id-input-options="usersIdOptions"
          :stakeholder-user-id-input="stakeholderUserId"
          :stakeholder-user-id-input-options="stakeholderUserOptions"
          :stakeholder-update-disabled="stakeholderUpdateDisabled"
          @stakeholder-user="stakeholderUser = $event"
          @users-access="usersAccess = $event"
        ></project-form-user-information>
      </project-form-wrapper>

      <project-form-actions
        :form-type="formType"
        :disabled="!valid || isDisabled"
        :submitting="isDisabled"
        :go-back-url="goBackUrl"
        @submit="projectSubmit"
      />
    </form>
    <div v-else>
      <loading-indicator />
    </div>
    <modal
      v-model="overwriteWarningOpen"
      max-width="450px"
      :title="$i18n.category_warning.title"
      hide-title
    >
      <simple-dialog
        :title="$i18n.category_warning.title"
        :copy="$i18n.category_warning.copy"
        confirm-string="Ok"
        v-on:cancel="overwriteWarningOpen = false"
        v-on:confirm="handleConfirmCategoryWarning"
      />
    </modal>
  </div>
</template>

<script>
import JsonApiParse from "jsonapi-parse";
import { mapActions, mapGetters, mapState } from "vuex";
import router from "../router/router";
import { addProject, editProject } from "../lib/api";
import {
  advancedConfigToWorkerConfig,
  convertNewProjectToApi,
  showToastedMessage,
} from "../lib/utils";
import setEditFormMixin from "../mixins/set-edit-form";
import entityForm from "../mixins/entity-form";
import LoadingIndicator from "../components/LoadingIndicator";
import ProjectFormWrapper from "../components/category-form/ProjectFormWrapper";
import ProjectFormInformation from "../components/category-form/ProjectFormInformation";
import ProjectFormJiraInformation from "../components/category-form/ProjectFormJiraInformation";
import ProjectFormTestInformation from "../components/category-form/ProjectFormTestInformation";
import ProjectFormUserInformation from "../components/category-form/ProjectFormUserInformation";
import SimpleDialog from "../components/SimpleDialog";
import ProjectFormFooter from "@/components/category-form/ProjectFormFooter.vue";
import { RouteNames } from "@/router/router";
import Modal from "../components/ui/Modal.vue";
import ProjectFormAuthInformation from "@/components/category-form/ProjectFormAuthInformation.vue";
import ProjectFormAdvancedConfig from "@/components/category-form/ProjectFormAdvancedConfig.vue";
import Accordion from "@/components/ui/Accordion.vue";

export default {
  mixins: [setEditFormMixin, entityForm],

  components: {
    LoadingIndicator,
    ProjectFormWrapper,
    ProjectFormInformation,
    ProjectFormJiraInformation,
    ProjectFormTestInformation,
    ProjectFormUserInformation,
    SimpleDialog,
    "project-form-actions": ProjectFormFooter,
    Modal,
    ProjectFormAuthInformation,
    ProjectFormAdvancedConfig,
    Accordion,
  },

  props: {
    entityId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      projectTitle: "",
      projectDescription: "",
      projectBaseUrl: "",
      projectSitemapUrl: "",
      projectCategory: "",
      advancedConfig: {
        disableProxy: false,
        sitemapCrawlLimit: 0,
        enableNoindexNofollowPages: false,
        disablePageLoading: false,
        urlGetter: 1,
        urlGetterOptions: {},
      },
      formType: "add",
      reRunFrequency: "automatic",
    };
  },

  computed: {
    ...mapState("categories", ["layeredConfig"]),
    ...mapGetters("categories", ["getOverriddenFields"]),
    ...mapState("projects", ["editedProject"]),
    ...mapGetters("projects", ["findProjectDetails", "projectsIsLoading"]),
    ...mapGetters("users", ["getUserByName"]),

    entityType() {
      return "project";
    },

    projectDetails() {
      return this.findProjectDetails(this.entityId);
    },

    categoryId() {
      return (
        this.editedProject?.relationships?.field_menu?.data?.id ||
        this.$route.query.categoryId
      );
    },

    valid() {
      return this.usersAccess.length !== 0 && this.projectCategory;
    },

    formTitle() {
      return this.formType === "add"
        ? this.$i18n.pages.project.new.title
        : this.$i18n.pages.project.edit.title;
    },
  },

  methods: {
    ...mapActions("projects", ["getProjects", "getProjectDetails"]),
    ...mapActions("tests", ["getTestResults"]),

    setEditedProject() {
      const tree_config = this.layeredConfig || {};
      const project_data =
        this.editedProject.attributes?.field_config?.entity_config?.project;
      // Pushing all project data to local variables.
      this.projectTitle = this.editedProject.attributes.name;
      this.projectDescription =
        this.editedProject.attributes.description?.value || "";

      this.projectDescription =
        this.editedProject.attributes.field_project_description;
      this.projectBaseUrl =
        this.editedProject.attributes.field_project_tested_url;

      this.projectSitemapUrl =
        this.editedProject.attributes.field_project_sitemap_url || "";
      this.reRunFrequency =
        this.editedProject.attributes.field_project_rerun_frequency;
      this.projectCategory = this.editedProject.relationships.field_menu.data
        ? this.editedProject.relationships.field_menu.data.id
        : "";

      const worker_config =
        this.editedProject.attributes?.field_config?.worker_config;
      this.setEditForm(tree_config, project_data, worker_config);
    },

    validate() {
      this.$store.dispatch("forms/resetValidFields");
      let valid = true;
      let firstInvalidFieldId = "";
      const requiredFields = [
        "projectTitle",
        "projectBaseUrl",
        "projectSitemapUrl",
        "stakeholderUser",
      ];
      requiredFields.forEach((field) => {
        if (!Boolean(this[field])) {
          this.$store.dispatch("forms/setInvalidField", field);
          valid = false;

          if (!firstInvalidFieldId) {
            firstInvalidFieldId = field;

            this.$nextTick(() => {
              document
                .getElementById(`${firstInvalidFieldId}-wrapper`)
                .scrollIntoView({
                  behavior: "smooth",
                });
            });
          }
        }
      });

      this.$store.dispatch("forms/setFormSubmitted", true);

      return valid;
    },

    projectSubmit() {
      if (!this.validate()) {
        showToastedMessage(this.$i18n.validation.required_explanation, "error");
        return;
      }

      this.isDisabled = true;

      // Remove last slash from urls.
      if (this.projectBaseUrl.slice(-1) === "/") {
        this.projectBaseUrl = this.projectBaseUrl.slice(0, -1);
      }

      if (
        this.projectSitemapUrl &&
        this.projectSitemapUrl.length > 0 &&
        this.projectSitemapUrl.slice(-1) === "/"
      ) {
        this.projectSitemapUrl = this.projectSitemapUrl.slice(0, -1);
      }
      // Override field.
      const tree_config = this.layeredConfig || {};
      const project_config = tree_config.entity_config?.project || {};

      const field_config = {
        entity_config: {
          type: "list",
          project: {
            type: "list",
          },
        },
        worker_config: {
          type: "list",
          test_types: {
            type: "list",
            ...this.workerConfig,
          },
          workers: {
            type: "list",
            ...advancedConfigToWorkerConfig(this.advancedConfig),
          },
        },
      };

      const id_stakeholder = "stakeholder";
      if (
        id_stakeholder in this.getOverriddenFields &&
        this.getOverriddenFields[id_stakeholder].lock === false
      ) {
        field_config.entity_config.project.field_project_stakeholder = {
          type: "entity_reference",
          id: this.stakeholderUser.id,
          content_type: this.stakeholderUser.type,
        };
      }

      const id_user = "user_select";
      if (
        id_user in this.getOverriddenFields &&
        this.getOverriddenFields[id_user].lock === false
      ) {
        const user_to_add = {};

        const listUsersOld = [];
        if ("field_project_users" in project_config) {
          Object.keys(project_config.field_project_users).forEach(
            (element_id) => {
              if (element_id === "option") return;
              if (
                project_config.field_project_users[element_id]?.option?.uuid !==
                this.entityId
              ) {
                listUsersOld.push(
                  project_config.field_project_users[element_id]
                );
              }
            }
          );
        }

        const list_new_entries = this.usersAccess.filter((e) => {
          return !listUsersOld.find((d) => d.id === e.id);
        });

        const list_removes = listUsersOld.filter((e) => {
          return !this.usersAccess.find((d) => d.id === e.id);
        });

        list_new_entries.forEach((x) => {
          user_to_add[x.id] = {
            type: "entity_reference",
            id: x.id,
            content_type: x.type,
          };
        });

        list_removes.forEach((x) => {
          if (x.id) {
            user_to_add[x.id] = {
              type: "empty",
            };
          }
        });

        if (Object.keys(user_to_add).length !== 0) {
          field_config.entity_config.project.field_project_users = {
            type: "field_entity_references",
            ...user_to_add,
          };
        }
      }

      //jira
      const jira_account = "jira_account";
      const jira_id = this.jiraAccount?.id;
      if (
        jira_account in this.getOverriddenFields &&
        this.getOverriddenFields[jira_account].lock === false &&
        jira_id !== undefined
      ) {
        field_config.entity_config.project.jira_account = {
          type: "entity_reference",
          id: jira_id,
          content_type: "jira_account--jira_account",
        };
      }

      const field_jira_tickets_type = "jira_default_issue_type";
      if (
        field_jira_tickets_type in this.getOverriddenFields &&
        this.getOverriddenFields[field_jira_tickets_type].lock === false &&
        this.defaultIssueType
      ) {
        field_config.entity_config.project.field_jira_tickets_type = {
          type: "field",
          value: this.defaultIssueType,
        };
      }

      const field_jira_tickets_priority = "jira_default_issue_type_priority";
      if (
        field_jira_tickets_priority in this.getOverriddenFields &&
        this.getOverriddenFields[field_jira_tickets_priority].lock === false &&
        this.defaultIssuePriority
      ) {
        field_config.entity_config.project.field_jira_tickets_priority = {
          type: "field",
          value: this.defaultIssuePriority,
        };
      }

      const field_jira_project_key = "jira_project";
      if (
        field_jira_project_key in this.getOverriddenFields &&
        this.getOverriddenFields[field_jira_project_key].lock === false &&
        this.jiraProject?.key
      ) {
        field_config.entity_config.project.field_jira_project_key = {
          type: "field",
          value: this.jiraProject.key,
        };
      }

      // basic auth
      const id_username = "auth-username";
      if (
        id_username in this.getOverriddenFields &&
        this.getOverriddenFields[id_username].lock === false
      ) {
        field_config.entity_config.project.field_auth_username = {
          type: "field",
          value: this.projectAuthUsername,
        };
      }

      const id_auth = "auth-password";
      if (
        id_auth in this.getOverriddenFields &&
        this.getOverriddenFields[id_auth].lock === false
      ) {
        field_config.entity_config.project.field_auth_password = {
          type: "field",
          value: this.projectAuthPassword,
        };
      }

      // filter by overrides.
      let testa = {};
      const tests = Object.keys(this.enabledTests)
        .filter((e) => {
          if (e in this.getOverriddenFields) {
            if (this.getOverriddenFields[e].lock === false) {
              return true;
            }
          }
          return false;
        })
        .map((e) => {
          return { [e]: this.enabledTests[e] || false };
        });

      tests.forEach((e) => {
        testa = { ...testa, ...e };
      });

      if (Object.keys(testa).length !== 0) {
        field_config.entity_config.project.enabled_test_types = {
          type: "field_enabled_tests",
          value: testa,
        };
      }

      const convertedProject = convertNewProjectToApi(
        this.projectTitle,
        this.projectDescription,
        this.projectBaseUrl,
        this.projectSitemapUrl,
        this.reRunFrequency,
        this.projectCategory,
        this.enabledTests,
        this.defaultIssueType,
        this.defaultIssuePriority,
        this.jiraAccount,
        this.jiraProject,
        this.entityId ? this.entityId : null,
        this.stakeholderUser,
        this.usersAccess,
        this.projectAuthUsername,
        this.projectAuthPassword,
        field_config
      );

      if (this.formType === "add") {
        addProject(convertedProject)
          .then((project) => {
            showToastedMessage(
              this.$i18n.feedback.project.new.success,
              "success"
            );
            this.getProjects();
            router.push({
              name: RouteNames.ProjectOverview,
              params: { id: project.data.id },
            });

            this.$store.dispatch("forms/setFormSubmitted", false);
          })
          .catch((response) => {
            const parsedResponse = JsonApiParse.parse(
              response?.request?.response ? response.request.response : response
            );

            if (parsedResponse.errors && parsedResponse.errors.length > 0) {
              parsedResponse.errors.forEach((error) => {
                if (error.source.pointer === "/data") {
                  error.detail = error.detail
                    .replace("Entity is not valid:", "")
                    .trim();
                  showToastedMessage(error.detail, "error");
                }
              });
            } else {
              showToastedMessage(
                this.$i18n.feedback.project.new.error,
                "error"
              );
            }
          })
          .finally(() => {
            this.isDisabled = false;
          });
      } else {
        editProject(convertedProject, this.entityId)
          .then(() => {
            this.getProjects();
            this.getProjectDetails(this.entityId).then(() => {
              this.getTestResults({
                id: this.entityId,
                testTypes: Object.keys(
                  JSON.parse(this.projectDetails.enabled_test_types)
                ),
              });
            });
            router.push({
              name: RouteNames.ProjectOverview,
              params: { id: this.entityId },
            });
            showToastedMessage(
              this.$i18n.feedback.project.edit.success,
              "success"
            );
          })
          .catch((response) => {
            const parsedResponse = JsonApiParse.parse(response);

            if (parsedResponse.errors && parsedResponse.errors.length > 0) {
              parsedResponse.errors.forEach((error) => {
                showToastedMessage(error.detail, "error");
              });
            } else {
              showToastedMessage(
                this.$i18n.feedback.project.edit.error,
                "error"
              );
            }
          })
          .finally(() => {
            this.isDisabled = false;
          });
      }
    },
    handleConfirmCategoryWarning() {
      this.fetchInheritanceData(this.projectCategory);
      this.overwriteWarningOpen = false;
    },
  },
  mounted() {
    if (this.$route.query.categoryId) {
      this.projectCategory = this.$route.query.categoryId;
    } else {
      // If no category was selected to create the project
      // selects the first category in the list
      if (!this.projectsIsLoading) {
        const projects = this.$store.state.projects.projects;
        this.projectCategory = projects[0].id;
      }
    }
    this.fetchInheritanceData(this.projectCategory);
  },
};
</script>

<style scoped>
.NewProjectForm {
  margin-top: var(--spacing-24);
}

.NewProjectForm-info {
  font-size: var(--typo-copy-14-font-size);
  color: var(color-decoration-Grey80);
  margin-top: var(--spacing-12);
}

.NewProjectForm-title {
  font: var(--typo-headline-28);
}

.NewProjectForm-actions {
  margin-bottom: var(--spacing-24);
}

.NewProjectForm-advanced {
  margin-bottom: var(--spacing-24);
}

.NewProjectForm-advanced::v-deep .Accordion-content {
  padding: var(--spacing-12) 0;
}
</style>
