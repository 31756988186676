import Vue from "vue";
import Vuex from "vuex";

import jira from "./modules/jira/jira-store";
import users from "./modules/users/users-store";
import projects from "./modules/projects/projects-store";
import tests from "./modules/tests/tests-store";
import categories from "./modules/categories/categories-store";
import forms from "./modules/forms/forms-store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    jira,
    users,
    projects,
    tests,
    categories,
    forms,
  },
});
